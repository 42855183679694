import { useParams } from "react-router-dom";
import { useRole, useToken } from "src/app/AppContext";

export function useIsGroupeNav() {
  const params = useParams<any>();
  const token = useToken();
  const role = useRole();
  const isGroupe = role === "groupe";

  return isGroupe && token?.claims?.establishment === params?.establishmentId;
}
