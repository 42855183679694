import React from "react";
import YouTube from "react-youtube";
import { Box } from "reflexbox";
import { SubTitle, Title } from "../commons/Text";

const noop = () => {};

const playerVars = {
  enablejsapi: 0,
  origin: "",
  showinfo: 0,
  controls: 0,
};

const opts = {
  height: "100%",
  width: "100%",
  playerVars,
};

export function VideoYoutube({ title, description, video, onPlay = noop, theme }) {
  function getUrl() {
    try {
      const url = new URL(video);
      const params = new URLSearchParams(url.search);
      const videoId = params.get("v");
      return videoId;
    } catch (e) {
      return "ldBMOcXRQsk";
    }
  }

  return (
    <Box>
      {(title || description) && (
        <Box display="flex" flexDirection="column" paddingLeft={2} paddingTop={2} paddingBottom={1}>
          <Box>
            <Title theme={theme} title={title} />
          </Box>
          <Box>
            <SubTitle theme={theme} title={description} />
          </Box>
        </Box>
      )}
      <Box
        width="auto"
        pt="56.25%"
        sx={{
          position: "relative",
          iframe: {
            position: "absolute",
            top: 0,
            left: 0,
          },
        }}
      >
        <YouTube onPlay={onPlay} videoId={getUrl()} opts={opts} />
      </Box>
    </Box>
  );
}
