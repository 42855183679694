import { Groupe, Image as ImageType, KardImage, Video, FolderMedia } from "@kards/types";
import useSWR from "swr";
import { toArray } from "src/helpers/toArray";
import { db, storage, firebase, auth } from "src/firebase";
import { useState, useEffect } from "react";
import { useEstablishment } from "src/helpers/useEstablishment";
import { noop } from "lodash";
import { Image } from "@kards/types";
import { convertFileToBase64 } from "src/helpers/convertFileToBase64";
import { useFirebaseOnSnapshot } from "src/helpers/useFirebaseOnSnapshot";
import * as UpChunk from "@mux/upchunk";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { useIsGroupeNav } from "src/helpers/useIsGroupeNav";
import { useApp } from "src/app/AppContext";

type Query = {
  type?: FolderMedia["type"];
  folders?: FolderMedia["parentFolder"][];
};

export function useKardImages() {
  return useSWR<KardImage[]>(`/images`, () =>
    db
      .collection("images")
      .get()
      .then((docs) => toArray<KardImage>(docs)),
  );
}

type uploadFileOnCloudStorageProps = {
  imageId: string;
  source: string;
  fileName?: string;
  establishment: string;
  external?: boolean;
  onProgress: (p: number | { [name: string]: number }) => void;
  onError: (err: any) => void;
  onComplete: (url: string) => void;
};

export async function uploadFileOnCloudStorage({
  imageId,
  source,
  establishment,
  external,
  fileName,
  onProgress,
  onError,
  onComplete,
}: uploadFileOnCloudStorageProps) {
  return new Promise(async (resolve) => {
    if (!source.includes("base64") && !external) {
      const url = await storage
        .ref()
        .child(`images/${establishment}/${imageId}/original`)
        .getDownloadURL();

      onComplete(url);
    }
    if (external && !source.includes("base64")) {
      source = (await convertFileToBase64(source)) as string;
    }

    const uploadTask = storage
      .ref()
      .child(`images/${establishment}/${imageId}/original`)
      .putString(source, "data_url", {
        contentType: "image/jpeg",
        cacheControl: "public, max-age=300, s-maxage=600",
      });

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        fileName ? onProgress({ [fileName]: Math.round(progress) }) : onProgress(Math.round(progress));
      },
      (error) => {
        onError(error);
        resolve(error);
      },
      async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        await onComplete(url);
        resolve(url);
      },
    );
  });
}

export async function uploadFileEditedOnCloudStorage({
  imageId,
  source,
  establishment,
  fileName,
  onProgress,
  onError,
  onComplete,
}: uploadFileOnCloudStorageProps) {
  return new Promise(async (resolve) => {
    const uploadTask = storage
      .ref()
      .child(`images/${establishment}/${imageId}/transform`)
      .putString(source, "data_url", {
        contentType: "image/jpeg",
        cacheControl: "public, max-age=300, s-maxage=600",
      });

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        fileName ? onProgress({ [fileName]: Math.round(progress) }) : onProgress(Math.round(progress));
      },
      (error) => {
        onError(error);
        resolve(error);
      },
      async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        await onComplete(url);
        resolve(url);
      },
    );
  });
}

export function useQueryParsed() {
  const location = useLocation();
  const queryParsed: Query = qs.parse(location.search, { arrayFormat: "index" });
  return queryParsed;
}

export function useGetFolderQuery() {
  const queryParsed = useQueryParsed();
  const parentFolderArray = queryParsed.folders || [];
  return parentFolderArray;
}

// Images CRUD

export function useGetImages() {
  const establishmentId = useEstablishment();
  return useSWR<ImageType[]>(`/establishments/${establishmentId}/images`, () =>
    db
      .collection(`/establishments/${establishmentId}/images`)
      .orderBy("createdAt", "desc")
      .get()
      .then((docs) => toArray<ImageType>(docs)),
  );
}

export function useGetImagesByFolder() {
  const parentFolderArray = useGetFolderQuery();
  const parentFolder = parentFolderArray.join("/");
  const establishmentId = useEstablishment();

  return useSWR<ImageType[]>(`/establishments/${establishmentId}/images?parentFolder=${parentFolder}`, () =>
    !parentFolder
      ? db
          .collection(`/establishments/${establishmentId}/images`)
          .orderBy("createdAt", "desc")
          .get()
          .then((docs) => toArray<ImageType>(docs)?.filter((i) => !i.parentFolder))
      : db
          .collection(`/establishments/${establishmentId}/images`)
          .where("parentFolder", "==", parentFolder)
          .get()
          .then((docs) => toArray<ImageType>(docs)),
  );
}

export function useCreateImage({ onSuccess = noop, onError = noop } = {}) {
  const [progress, setProgress] = useState(0);
  const folders = useGetFolderQuery();
  const establishment = useEstablishment();
  const { groupe } = useApp();

  useEffect(() => {
    if (progress === 100) {
      const id = setTimeout(() => {
        setProgress(0);
        clearTimeout(id);
      }, 1500);
    }
  }, [progress]);

  async function submit(
    values: { name: string; photoEditorSDKOperations?: Image["photoEditorSDKOperations"]; data: any },
    external = false,
  ) {
    const imageToAdd: Omit<Image, "id" | "original"> = {
      deleted: false,
      type: "image",
      name: values.name,
      photoEditorSDKOperations: values?.photoEditorSDKOperations,
      parentFolder: folders?.length ? folders.join("/") : undefined,
      createdAt: firebase.firestore.Timestamp.now(),
      createdBy: auth.currentUser?.email,
    };
    const img = await db
      .collection("establishments")
      .doc(establishment)
      .collection("images")
      .add(imageToAdd);
    const imageId = img.id;

    return uploadFileOnCloudStorage({
      source: values?.data,
      imageId,
      establishment,
      external,
      onProgress: (p) => setProgress(p as number),
      onError: async () => {
        await db
          .collection("establishments")
          .doc(establishment)
          .collection("images")
          .doc(imageId)
          .delete();
        onError();
      },
      onComplete: async (url: string) => {
        await db
          .collection("establishments")
          .doc(establishment)
          .collection("images")
          .doc(imageId)
          .update({ original: url, imageURL: `/images/${establishment}/${imageId}/original` });
        await syncImageGroupe({ establishmentId: establishment, imageId, groupe });
        onSuccess({ ...values, original: url });
      },
    });
  }

  return [progress, submit] as const;
}

export function useCreateImageMultiple({ onSuccess = noop, onError = noop } = {}) {
  const establishment = useEstablishment();
  const folders = useGetFolderQuery();
  const isGroup = useIsGroupeNav();
  const { groupe } = useApp();

  async function submit(values: { name: string; data: any }, external = false, onProgress) {
    const imageToAdd: Omit<Image, "id" | "original"> = {
      deleted: false,
      type: "image",
      name: values.name,
      parentFolder: folders?.length ? folders.join("/") : undefined,
      createdAt: firebase.firestore.Timestamp.now(),
      createdBy: auth.currentUser?.email,
    };

    const img = await db
      .collection("establishments")
      .doc(establishment)
      .collection("images")
      .add(imageToAdd);
    const imageId = img.id;

    return uploadFileOnCloudStorage({
      fileName: values.name,
      source: values?.data,
      imageId,
      establishment,
      external,
      onProgress,
      onError: async () => {
        await db
          .collection("establishments")
          .doc(establishment)
          .collection("images")
          .doc(imageId)
          .delete();
        onError();
      },
      onComplete: async (url: string) => {
        await db
          .collection("establishments")
          .doc(establishment)
          .collection("images")
          .doc(imageId)
          .update({ original: url, imageURL: `/images/${establishment}/${imageId}/original` });
        if (isGroup) await syncImageGroupe({ establishmentId: establishment, imageId, groupe });
        onSuccess({ ...values, original: url });
      },
    });
  }

  return submit;
}

export function useCreateImagefromImagephotoEditorSDK({ onProgress = noop, onSuccess = noop, onError = noop } = {}) {
  const establishmentId = useEstablishment();
  const isGroupe = useIsGroupeNav();
  const { groupe } = useApp();
  const [progress, setProgress] = useState(0);
  const folders = useGetFolderQuery();

  useEffect(() => {
    if (progress === 100) {
      const id = setTimeout(() => {
        setProgress(0);
        clearTimeout(id);
      }, 1500);
    }
  }, [progress]);

  return async (imageId: string, imageToUpdate: Partial<ImageType>, edited) => {
    const img = await db
      .collection("establishments")
      .doc(establishmentId)
      .collection("images")
      .doc(imageId);
    const test = (await img.get()).exists;
    console.log("EXISTE", test, imageToUpdate);
    if (!test) {
      const imageToAdd: Omit<Image, "id" | "original"> = {
        deleted: false,
        type: "image",
        name: imageToUpdate.name,
        photoEditorSDKOperations: imageToUpdate.photoEditorSDKOperations,
        parentFolder: folders?.length ? folders.join("/") : undefined,
        createdAt: firebase.firestore.Timestamp.now(),
        createdBy: auth.currentUser?.email,
      };

      const img = await db
        .collection("establishments")
        .doc(establishmentId)
        .collection("images")
        .add(imageToAdd);

      imageId = img.id;

      return uploadFileEditedOnCloudStorage({
        fileName: imageToAdd.name,
        source: edited,
        imageId,
        establishment: establishmentId,
        onProgress,
        onError: async () => {
          await db
            .collection("establishments")
            .doc(establishmentId)
            .collection("images")
            .doc(imageId)
            .delete();
          onError();
        },
        onComplete: async (url: string) => {
          await db
            .collection("establishments")
            .doc(establishmentId)
            .collection("images")
            .doc(imageId)
            .update({ original: url, transform: url, imageURL: `/images/${establishmentId}/${imageId}/transform` });
          if (isGroupe) await syncImageGroupe({ establishmentId: establishmentId, imageId, groupe });
          onSuccess({ ...imageToAdd, original: url });
        },
      });
    }

    await db
      .collection(`/establishments/${establishmentId}/images`)
      .doc(imageId)
      .update({ ...imageToUpdate, updatedAt: firebase.firestore.Timestamp.now(), updatedBy: auth.currentUser?.email });

    return uploadFileEditedOnCloudStorage({
      source: edited,
      imageId,
      establishment: establishmentId,
      onProgress: (p) => setProgress(p as number),
      onError: async () => {
        await db
          .collection("establishments")
          .doc(establishmentId)
          .collection("images")
          .doc(imageId)
          .delete();
        onError();
      },
      onComplete: async (url: string) => {
        await db
          .collection("establishments")
          .doc(establishmentId)
          .collection("images")
          .doc(imageId)
          .update({ transform: url, imageURL: `/images/${establishmentId}/${imageId}/transform` });
        if (isGroupe) await syncImageGroupe({ establishmentId, imageId, groupe });
        onSuccess({ ...edited, transform: url });
      },
    });
  };
}

export function useUpdateImage() {
  const establishmentId = useEstablishment();
  const isGroupe = useIsGroupeNav();
  const { groupe } = useApp();

  return async (imageId: string, imageToUpdate: Partial<ImageType>) => {
    await db
      .collection(`/establishments/${establishmentId}/images`)
      .doc(imageId)
      .update({ ...imageToUpdate, updatedAt: firebase.firestore.Timestamp.now(), updatedBy: auth.currentUser?.email });
    if (isGroupe) await syncImageGroupe({ establishmentId, imageId, groupe });
  };
}

export function useRemoveImage({ onSuccess = noop, onError = noop } = {}) {
  const [loading, setLoading] = useState(false);
  const establishmentId = useEstablishment();
  const isGroupe = useIsGroupeNav();
  const { groupe } = useApp();

  async function remove(imageId: string) {
    try {
      setLoading(true);
      await storage
        .ref()
        .child(`images/${establishmentId}/${imageId}/original`)
        .delete();
      await db
        .collection("establishments")
        .doc(establishmentId)
        .collection("images")
        .doc(imageId)
        .delete();
      if (isGroupe) await removeImageGroupe({ imageId, groupe });
      onSuccess();
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  }

  return [loading, remove] as const;
}

async function removeImageGroupe({ imageId, groupe }: { imageId: string; groupe: Groupe }) {
  return Promise.all(
    groupe.establishmentIds.map((id) =>
      db
        .collection(`/establishments/${id}/images`)
        .doc(imageId)
        .delete(),
    ),
  );
}

async function syncImageGroupe({
  establishmentId,
  imageId,
  groupe,
}: {
  establishmentId: string;
  imageId: string;
  groupe: Groupe;
}) {
  console.log("-- establishmentId --", establishmentId, groupe?.establishmentIds);
  const image = await db
    .collection(`establishments/${establishmentId}/images`)
    .doc(imageId)
    .get()
    .then((doc) => doc.data() as ImageType);

  return (
    groupe?.establishmentIds &&
    Promise.all(
      groupe?.establishmentIds?.map((id) =>
        db
          .collection(`/establishments/${id}/images`)
          .doc(imageId)
          .set({ ...image, isGroupe: true }),
      ),
    )
  );
}

// Video CRUD

export function useGetMediasByFolder<T>({ type = "video" } = {}) {
  const establishment = useEstablishment();
  const parentFolderArray = useGetFolderQuery();
  const parentFolder = parentFolderArray.join("/");

  return useFirebaseOnSnapshot<T[]>(
    (cb) => {
      if (!establishment) return;
      return !parentFolder
        ? db
            .collection("establishments")
            .doc(establishment)
            .collection("videos")
            .where("type", "==", type)
            .orderBy("createdAt", "desc")
            .onSnapshot((docs) => {
              const videos = toArray<T>(docs);
              console.log({ videos });
              const videoFiltered = videos?.filter((v: any) => !v.parentFolder);
              cb(videoFiltered);
            })
        : db
            .collection("establishments")
            .doc(establishment)
            .collection("videos")
            .where("type", "==", type)
            .where("parentFolder", "==", parentFolder)
            .onSnapshot((docs) => {
              cb(toArray<T>(docs));
            });
    },
    [establishment, parentFolder],
  );
}

export function useUploadMedia({ onSuccess = noop, onError = noop } = {}) {
  const [progress, setProgress] = useState<number>(0);
  const establishment = useEstablishment();

  useEffect(() => {
    if (progress === 100) {
      const id = setTimeout(() => {
        setProgress(0);
        clearTimeout(id);
      }, 1500);
    }
  }, [progress]);

  async function mutate(file: File, type = "video") {
    try {
      setProgress(1);
      const createMediaVideo = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("createMediaVideo");
      const mediaCreate = await createMediaVideo({ establishment, name: file.name, type }).then((res) => res.data);
      const upload = UpChunk.createUpload({
        // getUploadUrl is a function that resolves with the upload URL generated
        // on the server-side
        endpoint: mediaCreate?.url,
        // picker here is a file picker HTML element
        file: file,
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });
      upload.on("progress", (progress) => {
        setProgress(Math.round(progress.detail));
      });
      upload.on("success", async () => {
        onSuccess();
      });
      upload.on("error", (err) => {
        console.error("error. 👋", err);
      });
    } catch (e) {
      onError(e);
    }
  }

  return [progress, mutate] as const;
}

export function useUpdateVideo() {
  const establishmentId = useEstablishment();
  const isGroupe = useIsGroupeNav();
  const { groupe } = useApp();

  return async (videoId: string, folderToUpdate: Partial<Video>) => {
    await db
      .collection(`/establishments/${establishmentId}/videos`)
      .doc(videoId)
      .update({ ...folderToUpdate, updatedAt: firebase.firestore.Timestamp.now(), updatedBy: auth.currentUser?.email });

    if (isGroupe) await syncVideoGroupe({ establishmentId, videoId, groupe });
  };
}

export async function syncVideoGroupe({
  establishmentId,
  videoId,
  groupe,
}: {
  establishmentId: string;
  videoId: string;
  groupe: Groupe;
}) {
  const video = await db
    .collection(`establishments/${establishmentId}/videos`)
    .doc(videoId)
    .get()
    .then((doc) => doc.data() as FolderMedia);

  return Promise.all(
    groupe?.establishmentIds.map((id) =>
      db
        .collection(`/establishments/${id}/videos`)
        .doc(videoId)
        .set({ ...video, isGroupe: true }),
    ),
  );
}

export function useRemoveVideo() {
  const establishmentId = useEstablishment();
  const [loading, setLoading] = useState<boolean>(false);
  const { groupe } = useApp();

  const removeVideo = async (videoId: string) => {
    try {
      setLoading(true);
      await db
        .collection(`/establishments/${establishmentId}/videos`)
        .doc(videoId)
        .delete();
      await syncVideoDeleteGroupe({ groupe, videoId });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return [loading, removeVideo] as const;
}

async function syncVideoDeleteGroupe({ videoId, groupe }: { groupe: Groupe; videoId: string }) {
  return Promise.all(
    groupe?.establishmentIds.map((id) =>
      db
        .collection(`/establishments/${id}/videos`)
        .doc(videoId)
        .delete(),
    ),
  );
}

// Folder Medias CRUD

export function useGetFolders({ type }: Pick<FolderMedia, "type">) {
  const parentFolderArray = useGetFolderQuery();
  const parentFolder = parentFolderArray.join("/");
  const establishmentId = useEstablishment();

  return useSWR<FolderMedia[]>(
    `/establishments/${establishmentId}/folders?type=${type}&parentFolder=${parentFolder}`,
    () =>
      db
        .collection(`/establishments/${establishmentId}/folders-medias`)
        .where("type", "==", type)
        .where("parentFolder", "==", parentFolder)
        .get()
        .then((docs) => toArray<FolderMedia>(docs)),
  );
}

export function useGetCurrentFolder({ type }: Pick<FolderMedia, "type">) {
  const parentFolderArray = useGetFolderQuery();
  const name = parentFolderArray.pop() || "";
  const parentFolder = parentFolderArray.join("/");
  const establishmentId = useEstablishment();

  const { data } = useSWR<FolderMedia[]>(
    `/establishments/${establishmentId}/folders?type=${type}&parentFolder=${parentFolder}&name=${name}`,
    () =>
      parentFolder
        ? db
            .collection(`/establishments/${establishmentId}/folders-medias`)
            .where("type", "==", type)
            .where("parentFolder", "==", parentFolder)
            .where("name", "==", name)
            .get()
            .then((docs) => toArray<FolderMedia>(docs))
        : db
            .collection(`/establishments/${establishmentId}/folders-medias`)
            .where("type", "==", type)
            .where("name", "==", name)
            .get()
            .then((docs) => toArray<FolderMedia>(docs)),
  );

  const folder = data?.[0];
  return folder;
}

export function useAddFolder() {
  const establishmentId = useEstablishment();
  const isGroupe = useIsGroupeNav();
  const { groupe } = useApp();

  return async (folderToCreate: Omit<FolderMedia, "id" | "createdAt" | "createdBy">) => {
    const doc = await db
      .collection(`/establishments/${establishmentId}/folders-medias`)
      .add({ ...folderToCreate, createdAt: firebase.firestore.Timestamp.now(), createdBy: auth.currentUser?.email });
    if (isGroupe) await syncFolderGroupe({ establishmentId, folderId: doc.id, groupe });
  };
}

export function useUpdateFolderMedia() {
  const establishmentId = useEstablishment();
  const isGroupe = useIsGroupeNav();
  const { groupe } = useApp();

  return async (folderId: string, folderToUpdate: Partial<FolderMedia>) => {
    await db
      .collection(`/establishments/${establishmentId}/folders-medias`)
      .doc(folderId)
      .update({ ...folderToUpdate, updatedAt: firebase.firestore.Timestamp.now(), updatedBy: auth.currentUser?.email });
    if (isGroupe) await syncFolderGroupe({ establishmentId, folderId, groupe });
  };
}

export function useRemoveFolder() {
  const establishmentId = useEstablishment();
  const isGroupe = useIsGroupeNav();
  const { groupe } = useApp();

  return async (folderId: string) => {
    await db
      .collection(`/establishments/${establishmentId}/folders-medias`)
      .doc(folderId)
      .delete();
    if (isGroupe && groupe) await removeFolderGroupe({ groupe, folderId });
  };
}

export async function removeFolderGroupe({ groupe, folderId }: { groupe: Groupe; folderId: string }) {
  console.log("Delete children folderId", groupe, folderId);

  return Promise.all(
    groupe?.establishmentIds.map((id) =>
      db
        .collection(`/establishments/${id}/folders-medias`)
        .doc(folderId)
        .delete(),
    ),
  );
}

export async function syncFolderGroupe({
  establishmentId,
  folderId,
  groupe,
}: {
  establishmentId: string;
  folderId: string;
  groupe: Groupe;
}) {
  const folder = await db
    .collection(`establishments/${establishmentId}/folders-medias`)
    .doc(folderId)
    .get()
    .then((doc) => doc.data() as FolderMedia);

  return Promise.all(
    groupe?.establishmentIds.map((id) =>
      db
        .collection(`/establishments/${id}/folders-medias`)
        .doc(folderId)
        .set({ ...folder, isGroupe: true }),
    ),
  );
}
