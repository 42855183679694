import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormFinal } from "src/common-ui/Form";
import { Payment } from "./Payment";
import { useGetEstablishmentWithToken } from "src/data/useGetEstablishment";
import { auth, functions } from "src/firebase";
import { useRole } from "src/app/AppContext";
import { SelectStripeProduct } from "./SelectStripeProduct";
import { StripeProductType } from "@kards/types";
import { AppLoader } from "src/app/AppLoader";

type Steps = "choiceSpace" | "choiceTemplate" | "selectProduct" | "payment";

type DataOnBoarding = {
  spaces: {
    name: string;
    type: string;
  }[];
};

export function OnBoarding() {
  const { establishmentId }: { establishmentId: string } = useParams();
  const { data: establishment } = useGetEstablishmentWithToken();
  const [loading, setLoading] = useState<boolean>(false);
  const [stripeProduct, setStripeProduct] = useState<StripeProductType | null>(null);
  const role = useRole();
  const history = useHistory();
  const group = role === "groupe";

  useEffect(() => {
    if (establishment?.onBoarding) history.push("admin");
  }, [establishment, history]);

  async function submit(values: DataOnBoarding) {
    try {
      setLoading(true);
      // functions.useFunctionsEmulator("http://localhost:5000");
      const response = await functions.httpsCallable("onBoarding")({ stripeProduct, ...values });
      if (response?.data?.code || response?.data?.code === "internal") {
        setLoading(false);
      } else {
        if (group) {
          return history.push(`/establishments/${establishmentId}/dashboard`);
        }
        history.push(`/establishments/${establishmentId}/welcome`);
      }
    } catch (e) {
      console.error("error", e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <FormFinal onSubmit={submit}>
      {() => (
        <OnboardingStepper
          group={group}
          setStripeProduct={setStripeProduct}
          stripeProduct={stripeProduct}
          loading={loading}
          establishmentId={establishmentId}
        />
      )}
    </FormFinal>
  );
}

function OnboardingStepper({ loading, group, setStripeProduct, stripeProduct, establishmentId }) {
  const [step, setStep] = useState<Steps>("selectProduct");

  if (loading) return <AppLoader />;

  switch (step) {
    case "selectProduct":
      return (
        <SelectStripeProduct
          isComplete={group ? true : false}
          setStep={() => setStep("payment")}
          setStripeProduct={setStripeProduct}
          onBack={() => auth.signOut()}
          establishmentId={establishmentId}
        />
      );
    case "payment":
      return <Payment isComplete stripeProduct={stripeProduct} onBack={() => setStep("selectProduct")} />;
    default:
      return null;
  }
}
