import React from "react";
import { Media, ThemeCard } from "@kards/types";
import { Flex, Box } from "reflexbox";
import { MediaComponent } from "../commons/Media/Media";

type MediaGalleryProps = {
  medias: Media[];
  hostImage: string;
  width?: string;
  theme: ThemeCard;
  dev?: boolean;
};

export const MediaGallery = ({ dev, medias, hostImage, width, theme }: MediaGalleryProps) => {
  return (
    <Flex justifyContent="space-around" alignItems="flex-start" flexWrap="wrap">
      {medias?.map((media) => (
        <Box key={media?.uuid || media?.id} sx={{ borderRadius: "5px", position: "relative" }} padding="5px">
          <MediaComponent
            dev={dev}
            hostImage={hostImage}
            value={media}
            style={{ borderRadius: "5px", width, height: width }}
          />
          {(media?.title || media?.description) && (
            <Box display="flex" flexDirection="column" mt="5px" paddingBottom={1} paddingX="6px" width={width}>
              {media?.title && (
                <Box
                  as="h2"
                  fontWeight="bold"
                  fontSize="min(3.7vw, .8em)"
                  fontFamily="inherit"
                  color={theme?.color?.title}
                >
                  {media?.title}
                </Box>
              )}
              {media?.description && (
                <Box as="p" fontSize="min(3.5vw, .7em)" fontFamily="inherit" color={theme?.color?.title}>
                  {media?.description}
                </Box>
              )}
            </Box>
          )}
        </Box>
      ))}
    </Flex>
  );
};
