import React, { ReactNode } from "react";
import { Box, Flex } from "reflexbox";
import { Store as StoreType, ThemeCard } from "@kards/types";
import { Text, TitleProduct } from "../commons";
import { MediaComponent } from "../commons/Media/Media";
import { GoogleReviewStore } from "../GoogleReview";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import { ImPhone } from "react-icons/im";
import { FaGlobeEurope, FaLocationArrow } from "react-icons/fa";
import { StoreLabel } from "../commons/Label";

export type StoreProps = {
  store: StoreType;
  theme?: ThemeCard;
  width?: string;
  hostImage: string;
  hostApi: string;
  children?: ReactNode;
};

export function Store({ store, theme, hostImage, hostApi, children }: StoreProps) {
  if (!store) return null;

  function onClickAdress(adress) {
    if (adress) {
      window.location.href = `http://maps.google.com/?q=${address?.adress}`;
    }
  }

  const {
    reference,
    address,
    picture,
    showGoogleNote = false,
    description,
    website,
    instagram,
    // timeSlots,
    facebook,
    phone,
    labels,
  } = store;

  return (
    <Box margin={1} className="littleBounced">
      {picture && (
        <Box style={{ position: "relative" }}>
          <MediaComponent
            hostImage={hostImage}
            value={picture}
            style={{
              width: "100%",
              height: "auto",
              borderBottomLeftRadius: "inherit",
              borderBottomRightRadius: "inherit",
            }}
          />
        </Box>
      )}
      <Box flexDirection="column" display="flex" marginTop={1}>
        <Box display="flex" flexDirection="column">
          <Flex justifyContent="space-between" width="100%">
            <TitleProduct theme={theme} title={reference} />
            {children}
          </Flex>
          {showGoogleNote && <GoogleReviewStore place_id={address?.place_id} hostApi={hostApi} />}
          <Text fontSize=".8em" color={theme?.color?.productDescription}>
            {" "}
            {description}
          </Text>
          <StoreLabel labels={labels} />

          <Box display="flex" sx={{ overflowX: "scroll", width: "100%" }} my={1}>
            {phone && (
              <a href={`tel:${phone}`} target="_blank">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  color={theme?.color?.productDescription}
                  sx={{
                    width: "max-content",
                    border: "solid 1px",
                    borderColor: theme?.color?.productDescription,
                    borderRadius: "100px",
                  }}
                  p="4px"
                  mr="5px"
                >
                  <Flex fontSize="1.1em" py={"1px"} px="4px">
                    <ImPhone />
                  </Flex>
                  {/* <Box>
                    <Text fontSize=".9em" color={theme?.color?.productDescription}>{phone}</Text>
                  </Box> */}
                </Flex>
              </a>
            )}
            {website && (
              <a href={website} target="_blank">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  color={theme?.color?.productDescription}
                  sx={{
                    width: "max-content",
                    border: "solid 1px",
                    borderColor: theme?.color?.productDescription,
                    borderRadius: "100px",
                  }}
                  p="4px"
                  mr="5px"
                >
                  <Flex fontSize="1em" px="4px">
                    <FaGlobeEurope />
                  </Flex>
                </Flex>
              </a>
            )}
            {facebook && (
              <a href={facebook} target="_blank">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  color={theme?.color?.productDescription}
                  sx={{
                    width: "max-content",
                    border: "solid 1px",
                    borderColor: theme?.color?.productDescription,
                    borderRadius: "100px",
                  }}
                  p="4px"
                  mr="5px"
                >
                  <Flex fontSize="1em" px="4px">
                    <AiFillFacebook />
                  </Flex>
                  <Box>
                    <Text fontSize=".9em" color={theme?.color?.productDescription}>
                      Facebook
                    </Text>
                  </Box>
                </Flex>
              </a>
            )}
            {instagram && (
              <a
                href={instagram?.includes("https://") ? instagram : `https://www.instagram.com/${instagram}`}
                target="_blank"
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  color={theme?.color?.productDescription}
                  sx={{
                    width: "max-content",
                    border: "solid 1px",
                    borderColor: theme?.color?.productDescription,
                    borderRadius: "100px",
                  }}
                  p="4px"
                  mr="5px"
                >
                  <Flex fontSize="1em" px="4px">
                    <AiFillInstagram />
                  </Flex>
                  <Box>
                    <Text fontSize=".9em" color={theme?.color?.productDescription}>
                      Instagram
                    </Text>
                  </Box>
                </Flex>
              </a>
            )}
            {address?.adress && (
              <div>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  color={theme?.color?.productDescription}
                  onClick={() => onClickAdress(address?.adress)}
                  sx={{
                    width: "max-content",
                    border: "solid 1px",
                    borderColor: theme?.color?.productDescription,
                    borderRadius: "100px",
                  }}
                  p="4px"
                  mr="5px"
                >
                  <Flex fontSize=".9em" px="4px">
                    <FaLocationArrow />
                  </Flex>
                  <Box>
                    <Text color={theme?.color?.productDescription} fontSize=".9em">
                      Adresse
                    </Text>
                  </Box>
                </Flex>
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
