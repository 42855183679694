import React from "react";
import { Event } from "@kards/types";
import { Box, Flex } from "reflexbox";
import { uniq } from "lodash";

type FilterCategoriesProps = {
  eventsFiltered: Event[];
  categorySelected: string;
  onSelected: (c: string) => void;
};

export function FilterCategories({ eventsFiltered, categorySelected, onSelected }: FilterCategoriesProps) {
  return (
    <Box width="100%" backgroundColor="transparent" alignItems="center" style={{ overflowX: "auto", zIndex: 1 }}>
      <Box
        alignItems="center"
        marginLeft={1}
        minWidth="auto"
        display="flex"
        width="100%"
        height="100%"
        overflowX="scroll"
      >
        {uniq(eventsFiltered?.flatMap((c) => c.categories))
          ?.filter(Boolean)
          ?.map((p) => (
            <Flex
              as="button"
              key={p}
              padding="5px"
              margin="5px"
              minWidth="auto"
              justifyContent="center"
              alignItems="center"
              className="normalBounced"
              sx={{
                whiteSpace: "nowrap",
                cursor: "pointer",
                fontSize: "0.8em",
                borderRadius: "25px",
                border: "1px solid  #1e2023",
                backgroundColor: categorySelected === p ? "#1e2023" : "#fff",
                color: categorySelected === p ? "#fff" : "#1e2023",
              }}
              onClick={() => onSelected(p === categorySelected ? null : p)}
            >
              {p}
            </Flex>
          ))}
        <Box p="2px" />
      </Box>
    </Box>
  );
}
