export const themeUi = {
  breakpoints: ["1000px", "2000px"],
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104],
  fonts: {
    body: "Mulish, sans-serif",
    navbar: "Mulish, sans-serif",
    title: "Poppins, sans-serif",
  },
  shadow: {
    normal: "0px 9px 20px -12px #1f008563",
    button: "0 0 13px -6px #1f008563",
    small: "0 4px 5px #1f008563",
  },
  fontSizes: [".1em", ".8em", "1em", "1.2em", "1.5em", "2em"],
  colors: {
    primary: {
      main: "#21018a",
      light: "#e7e8f4",
      dark_to_white: "#242424",
      dark_to_gold: "#242424",
      white_to_dark: "#ffffff",
      white_to_grey: "#ffffff",
      white_to_gold: "#ffffff",
      blue_to_white: "#21018a",
      blue_to_gold: "#21018a",
      blue_to_cyan: "#21018a",
      lightgrey_to_dark: "#f2f3f5",
      lightgrey_to_black: "#f2f3f5",
      lightblue_to_grey: "#EDEEF4",
    },
    secondary: {
      main: "#E8E9EF",
      light: "#F7F7F7",
      dark: "#3d4244",
    },
    tertiary: {
      main: "#2ED5C3",
    },
    background: "#f7f7f7",
    label: "#242424",
    input: "#EDEEF4",
    inputPlaceholder: "#aeacac",
    shadow: "rgba(125,155,250,0.55)",
    overlay: "#ffffff",
    // menuColor: "#21018a",
    menuColor: "#ffffff",
    menuColorActiveLink: "#21018a",
    groupe: "#b7094c",
    subgroupe: "#b7094c7a",
    agency: "#f19d2e",
    contributor: "#1ca74a",

    suggestion: {
      main: "#c8ccf0",
      light: "#e0e2f4",
    },
    spaces: {
      bar: "#E6C630",
      terrace: "#2ED5C3",
      restaurant: "#F0942E",
      toilet: "#EB5F5A",
      lounge: "#FF7F6A",
      pool: "#00B3E1",

      spa: "#E6C630",
      corridor: "#2ED5C3",
      room: "#F0942E",
      hall: "#EB5F5A",
      elevator: "#FF7F6A",
      library: "#00B3E1",

      outside: "#E6C630",
      sport: "#2ED5C3",
    },
    blue: {
      main: "#21018a",
      second: "#605ECF",
      pale: "#EDEEF4",
      light: "#7ad8f0",
      extralight: "#e7e8f4",
    },
    dark: {
      main: "#242424",
      light: "#8c8989",
    },
    light: {
      main: "#f2f3f5",
    },
    green: {
      regular: "#27C6B5",
      main: "#56d6c3",
      dark: "#70bd68",
      light: "#56d6c3",
    },
    grey: {
      main: "#777777",
      light: "#dadada",
    },
    orange: {
      light: "#f8c892",
      main: "#FFC15C",
    },
    pink: {
      main: "#FEA9D1",
      dark: "#f2566b",
      light: "linear-gradient(120deg,#FEA9D1,#FF4B4B)",
    },
    red: {
      main: "#EB5F59",
      light: "#f5adac",
    },
    white: {
      main: "#ffffff",
      light: "#f7f7f7",
    },
    yellow: {
      main: "#fac90f",
      light: "#f3e296",
    },

    modes: {
      dark: {
        primary: {
          main: "#242424",
          light: "#e7e8f4",
          dark_to_white: "#ffffff",
          dark_to_gold: "#fac90f",
          white_to_dark: "#242424",
          white_to_grey: "#3b3b3b",
          white_to_gold: "#fac90f",
          blue_to_white: "#ffffff",
          blue_to_gold: "#fac90f",
          blue_to_cyan: "#44C1F0",
          lightgrey_to_dark: "#242424",
          lightgrey_to_black: "#181818",
          lightblue_to_grey: "#3b3b3b",
        },
        secondary: {
          main: "#4b4b4b",
          light: "#F7F7F7",
          dark: "#ffffff",
        },

        background: "#3b3b3b",
        label: "#ffffff",
        input: "#EDEEF4",
        shadow: "rgb(99,99,103)",
        overlay: "#313131",
        menuColor: "#44C1F0",
        menuColorActiveLink: "#44C1F0",
      },

      groupe: {
        primary: {
          main: "#b7094c",
          light: "#e7e8f4",
          dark_to_white: "#242424",
          dark_to_gold: "#242424",
          white_to_dark: "#ffffff",
          white_to_grey: "#ffffff",
          white_to_gold: "#ffffff",
          blue_to_white: "#b7094c",
          blue_to_gold: "#b7094c",
          lightgrey_to_dark: "#f2f3f5",
          lightblue_to_grey: "#EDEEF4",
        },
        tertiary: {
          main: "#df0a5c",
        },
        blue: {
          main: "#b7094c",
        },
        secondary: {
          main: "#E8E9EF",
          light: "#F7F7F7",
          dark: "#3d4244",
        },
        background: "#f7f7f7",
        label: "#242424",
        input: "#EDEEF4",
        inputPlaceholder: "#aeacac",
        shadow: "rgba(125,155,250,0.55)",
        overlay: "#ffffff",
        menuColor: "#b7094c",
        menuColorActiveLink: "#df0a5c",
      },

      groupeChildren: {
        primary: {
          main: "#70a9a1",
          light: "#e7e8f4",
          dark_to_white: "#242424",
          dark_to_gold: "#242424",
          white_to_dark: "#ffffff",
          white_to_grey: "#ffffff",
          white_to_gold: "#ffffff",
          blue_to_white: "#70a9a1",
          blue_to_gold: "#70a9a1",
          lightgrey_to_dark: "#f2f3f5",
          lightblue_to_grey: "#EDEEF4",
        },
        secondary: {
          main: "#E8E9EF",
          light: "#F7F7F7",
          dark: "#3d4244",
        },
        tertiary: {
          main: "#99e4da",
        },
        blue: {
          main: "#70a9a1",
        },
        background: "#f7f7f7",
        label: "#242424",
        input: "#EDEEF4",
        inputPlaceholder: "#aeacac",
        shadow: "rgba(125,155,250,0.55)",
        overlay: "#ffffff",
        menuColor: "#70a9a1",
        menuColorActiveLink: "#99e4da",
      },
    },
  },
};
