import React from "react";
// import React, { useState } from "react";
import { Box } from "reflexbox";
import { ThemeCard, Audio } from "@kards/types";
import { VideoPlayer } from "./VideoPlayer";

type AudioProps = {
  theme?: ThemeCard;
  value: Audio;
  style?: any;
};

export function Audio({ value }: AudioProps) {
  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <VideoPlayer src={value.videoURL} width="100%" height="60px" controls />
    </Box>
  );
}
