import { createPortal } from "react-dom";
import { IoCloseSharp } from "react-icons/io5";
import { Box, Flex } from "reflexbox";
import { useColorMode } from "theme-ui";
import { Text } from "./Text";

type MoadalType = {
  backdrop?: boolean;
  onClose?: any;
  open?: boolean;
  position?: "right" | "left" | "top" | "bottom";
} & React.ComponentProps<typeof Flex>;

export function ModalEditorPhotoshop({ open, backdrop, onClose, position, ...props }: MoadalType) {
  const [colorMode] = useColorMode();

  if (!open) return null;

  if (backdrop) {
    return createPortal(
      <Flex
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        minWidth="100vw"
        sx={{ position: "fixed", zIndex: 10000, left: 0, top: 0 }}
      >
        <Flex bg="primary.white_to_dark" p={3} width="80vw" height="70vh" flexDirection="column" {...props} />
      </Flex>,
      document.body,
    );
  }

  return (
    <Flex
      bg="primary.white_to_dark"
      minWidth={props.width || "400px"}
      width={props.width}
      p={props.p || 3}
      sx={{
        zIndex: 9999,
        position: "absolute",
        boxShadow:
          colorMode === "dark" ? "0px 0px 9px -5px rgb(125 125 125 / 18%)" : "0px 0px 9px -5px rgba(9,9,142,0.62)",
        borderRadius: "10px",
        ...(position && { [position]: 0 }),
      }}
      {...props}
    />
  );
}

export function Modal({ open, backdrop, onClose, position, ...props }: MoadalType) {
  const [colorMode] = useColorMode();
  const handleClose = onClose ? onClose : () => null;

  if (!open) return null;

  if (backdrop) {
    return createPortal(
      <Flex
        className="hiddenScrollBarAndChildren"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        minWidth="100vw"
        sx={{ position: "fixed", zIndex: 10000, left: 0, top: 0 }}
      >
        <Flex
          bg="primary.white_to_dark"
          p={3}
          maxWidth="750px"
          minWidth="750px"
          minHeight="100px"
          maxHeight="70vh"
          flexDirection="column"
          sx={{
            zIndex: 10001,
            boxShadow:
              colorMode === "dark"
                ? "0px 0px 9px -5px rgb(125 125 125 / 18%)"
                : "0px 0px 9px -5px rgb(125 125 125 / 18%)",
            borderRadius: "10px",
          }}
          {...props}
        />
        <Flex
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          minWidth="100vw"
          sx={{ position: "fixed", left: 0, top: 0 }}
          bg="rgba(0,0,0,0.5)"
          onClick={handleClose}
        />
        {onClose && (
          <>
            <Box
              sx={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
              onClick={onClose}
            />
            <Box sx={{ position: "absolute", top: "10px", right: "10px" }}>
              <button onClick={onClose}>
                <Text color="white.main" style={{ cursor: "pointer" }}>
                  <IoCloseSharp fontSize="35px" />
                </Text>
              </button>
            </Box>
          </>
        )}
      </Flex>,
      document.body,
    );
  }

  return (
    <Flex
      className="hiddenScrollBarAndChildren"
      bg="primary.white_to_dark"
      minWidth={props.width || "400px"}
      width={props.width}
      p={props.p || 3}
      sx={{
        zIndex: 9999,
        position: "absolute",
        boxShadow:
          colorMode === "dark" ? "0px 0px 9px -5px rgb(125 125 125 / 18%)" : "0px 0px 9px -5px rgba(9,9,142,0.62)",
        borderRadius: "10px",
        ...(position && { [position]: 0 }),
      }}
      {...props}
    />
  );
}

export function ModalFullScreen({ children, open, onClose, zIndex = 3000, ...props }: any) {
  if (!open) return null;
  return createPortal(
    <Flex
      bg="rgba(0,0,0,0.8)"
      alignItems="center"
      justifyContent="center"
      sx={{ position: "fixed", top: 0, bottom: 0, right: 0, left: 0, zIndex }}
      className="hiddenScrollBarAndChildren"
      {...props}
    >
      {onClose && (
        <>
          <Box
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: -1,
            }}
            onClick={onClose}
          />
          <Box sx={{ position: "absolute", top: "10px", right: "10px" }}>
            <button onClick={onClose}>
              <Text color="white.main" style={{ cursor: "pointer" }}>
                <IoCloseSharp fontSize="35px" />
              </Text>
            </button>
          </Box>
        </>
      )}
      {children}
    </Flex>,
    document.body,
  );
}
