import { Card, ComponentSpotify } from "@kards/types";
import React from "react";
import { Box, Flex } from "reflexbox";
import useSWR from "swr";
import { fetcher } from "../commons/fetcher";

type CurrentTrack = {
  actions: any;
  context: any;
  currently_playing_type: "track";
  is_playing: boolean;
  item: {
    album: { images: any[] };
    artists: any[];
    available_markets: string[];
    disc_number: number;
    duration_ms: number;
    explicit: boolean;
    external_ids: { isrc: string };
    external_urls: { spotify: string };
    href: string;
    id: string;
    is_local: boolean;
    name: string;
    popularity: number;
    preview_url: string;
    track_number: number;
    type: string;
    uri: string;
  };
  progress_ms: number;
  timestamp: number;
};

export function Spotify({
  theme,
  component,
  hostApi,
  establishment,
}: {
  component: ComponentSpotify;
  theme: Card["theme"];
  hostApi: string;
  establishment: string;
}) {
  const playlist = component?.value?.playlist;
  const { data: currentTrack } = useSWR<CurrentTrack>(`${hostApi}/spotify/${establishment}/currentTrack`, fetcher);

  return (
    <Flex width="100%" backgroundColor={theme?.color?.background} flexDirection="column">
      {currentTrack ? (
        <Flex width="100%" justifyContent="space-around" alignItems="center" px={2}>
          <Flex
            justifyContent="center"
            alignItems="center"
            width="40%"
            margin={2}
            sx={{ overflow: "hidden", animation: "pulse 2s infinite" }}
          >
            <img width="100%" src={currentTrack?.item?.album?.images?.find((i) => i.height == 300).url} />
          </Flex>
          <Flex width="50%" justifyContent="center" alignItems="flex-start" flexDirection="column">
            <Box as="h2" fontSize="0.8em" color={theme.color.title}>
              {currentTrack?.item?.name}
            </Box>

            <Box as="h2" fontSize="0.7em" color={theme.color.subtitle}>
              {currentTrack?.item?.artists?.map((a, i) => {
                if (currentTrack?.item?.artists?.length > 1 && currentTrack?.item?.artists?.length != i + 1) {
                  return a?.name + ", ";
                }
                return a?.name;
              })}
            </Box>
          </Flex>
        </Flex>
      ) : (
        <Flex justifyContent="center" alignItems="center" height="10vh" p={2}>
          <p style={{ color: theme.color.subtitle }}>Pas de music actuellement</p>
        </Flex>
      )}

      <Flex width="100%" justifyContent="space-around" alignItems="center" px={2}>
        <Box
          width="60px"
          height="60px"
          sx={{
            boxShadow: "0px 0px 9px -4px #000000",
            borderRadius: "5px",
            overflow: "hidden",
          }}
          backgroundColor="#000"
        >
          <img width="100%" height="100%" src={playlist?.images[0]?.url} />
        </Box>
        <Flex justifyContent="center" alignItems="flex-start" flexDirection="column" width="50%">
          <Box as="h2" fontSize="0.8em" color={theme.color.title}>
            {playlist?.name} .{" "}
            <span style={{ color: theme.color.subtitle, fontSize: ".7em" }}>{playlist?.tracks?.total} titres</span>
          </Box>
          <Box as="h2" fontSize="0.7em" color={theme.color.subtitle}>
            {playlist?.owner?.display_name}
          </Box>
        </Flex>
        {/* <Flex>S'abbonner</Flex> */}
      </Flex>
    </Flex>
  );
}
