import React, { ReactNode } from "react";
import { Media } from "@kards/types";
import { VideoComponent } from "./Video";
import { SmartImage } from "./SmartImage";
import { Flex } from "reflexbox";
import { Audio } from "./Audio";

export const isClient = typeof window !== "undefined";

type MediaProps = {
  value: Media;
  dev?: boolean;
  radius?: number;
  style?: any;
  hostImage: string;
};

const LinkA = (props) => <Flex as="a" {...props} />;

export function Link({ dev, media, children }: { dev?: boolean; media: Media; children: ReactNode }) {
  const { link, linkType, isInternalLink } = media;
  if (link && !dev)
    return (
      <LinkA
        href={linkType && linkType !== "rebond" ? `${linkType}${link}` : link}
        target={isInternalLink ? "_self" : "_blank"}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        {children}
      </LinkA>
    );

  return <>{children}</>;
}

export function MediaComponent({ value, dev, hostImage, radius, ...props }: MediaProps) {
  if (!value?.type) return null;

  switch (value.type) {
    case "image":
      return (
        <Link dev={dev} media={value}>
          <SmartImage size="medium" radius={radius} hostImage={hostImage} image={value} alt={value?.name} {...props} />
        </Link>
      );
    case "video":
      return <VideoComponent value={value} {...props} />;
    case "audio":
      return <Audio value={value} />;
    default:
      return never(value);
  }
}

function never(x: never) {
  return x;
}
