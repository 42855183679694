import React, { ReactNode } from "react";
import { Event, ThemeCard } from "@kards/types";
import { Flex } from "reflexbox";
import { FilterLocations } from "./FilterLocations";
import { FilterCategories } from "./FilterCategories";
import { FilterDate } from "./FilterDate";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/fr";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.locale("fr");

type ProgramSlideProps = {
  events?: Event[];
  theme: ThemeCard;
  eventsSorted: Event[];
  eventsFiltered: Event[];
  categorySelected: string | undefined;
  locationSelected: string | undefined;
  setDay: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setCategorySelected: React.Dispatch<React.SetStateAction<string | undefined>>;
  setLocationSelected: React.Dispatch<React.SetStateAction<string | undefined>>;
  children: ReactNode;
  backgroundLocation?: string;
};

export { EventWithPicture, EventWithoutPicture } from "./EventItem";

export function ProgramSlide({
  eventsSorted,
  eventsFiltered,
  setDay,
  categorySelected,
  backgroundLocation,
  setCategorySelected,
  locationSelected,
  setLocationSelected,
  theme,
  children,
}: ProgramSlideProps) {
  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" my={2}>
        <FilterDate theme={theme} events={eventsSorted} onClickDay={setDay} />
        <FilterCategories
          eventsFiltered={eventsFiltered}
          categorySelected={categorySelected}
          onSelected={setCategorySelected}
        />
        <FilterLocations
          backgroundLocation={backgroundLocation}
          eventsFiltered={eventsFiltered}
          locationSelected={locationSelected}
          onSelected={setLocationSelected}
        />
      </Flex>

      {children}
    </Flex>
  );
}
