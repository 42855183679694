import React from "react";
import { Flex } from "reflexbox";
import { Text } from "../commons";

const noop = () => {};

export function WhoIsHereUi({
  viewsNumber,
  theme,
  data,
  user,
  component,
  btnConnexion,
  btnDisconnexion,
  isUserPresent = false,
  modals,
  onClickUserName = noop,
}: any) {
  const userInData = data?.find((userPresent) => userPresent?.username === user?.instagram?.userName);

  return (
    <Flex flexDirection="column" width="100%">
      <Flex padding="5px" width="100%" justifyContent="center" alignItems="center">
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="27px"
          height="27px"
          viewBox="0 0 97.713 97.713"
        >
          <g fill={theme?.color.title}>
            <path
              d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
			c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
			c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
			c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
			C67.542,46.276,59.159,54.659,48.855,54.659z"
            />
          </g>
        </svg>
        <Flex justifyContent="flex-start" alignItems="flex-start" flexDirection="column">
          <Text px="5px" color={theme?.color.title}>
            <strong style={{ wordBreak: "break-all" }}> {data?.length}</strong> personne (s) dans le lieu
          </Text>

          <Text px="5px" fontSize="0.65em" color={theme?.color.title}>
            {component?.value?.timeToRefresh > 1
              ? `Dans les ${component?.value?.timeToRefresh}
                dernières heures`
              : `Dans la dernière
              heure`}
          </Text>
        </Flex>
        {isUserPresent && btnDisconnexion && btnDisconnexion}
      </Flex>

      <Flex width="100%" sx={{ position: "relative" }}>
        {modals}
        {!isUserPresent && btnConnexion && (
          <Flex
            justifyContent="center"
            alignItems="center"
            sx={{
              zIndex: 1,
              position: "absolute",
              width: "100%",
              height: "100%",
              background: "rgba(200,200,200,0.5)",
            }}
          >
            {btnConnexion}
          </Flex>
        )}
        <Flex
          sx={{
            height: "185px",
            width: "100%",
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          {userInData && (
            <div key={userInData?.id} style={{ flex: "0 0 auto" }}>
              <Flex
                sx={{
                  flexDirection: "column",
                  background: theme?.color?.background,
                  margin: "0px 10px",
                  borderRadius: "4px",
                }}
              >
                <Flex
                  sx={{
                    position: "relative",
                  }}
                >
                  <img
                    style={{ borderRadius: "4px", maxHeight: "100px" }}
                    src={userInData?.picture || "/img/placeholderImage.png"}
                  />
                </Flex>
                <Flex width="100%" alignItems="center" flexDirection="column">
                  <Text color={theme?.color?.title} fontSize="1.8em" fontWeight="200">
                    {viewsNumber}
                  </Text>
                  <Text color={theme?.color?.title} fontSize=".9em" fontWeight="200">
                    <strong style={{ wordBreak: "break-all" }}>visite(s)</strong>
                  </Text>
                  <Text color={theme?.color?.title} fontSize=".9em" fontWeight="200">
                    <strong style={{ wordBreak: "break-all" }}>de profil</strong>
                  </Text>
                </Flex>
              </Flex>
            </div>
          )}
          {data
            ?.filter((userPresent) => userPresent?.username !== user?.instagram?.userName)
            ?.map((u) => (
              <div key={u?.id} style={{ flex: "0 0 auto" }}>
                <Flex
                  sx={{
                    flexDirection: "column",
                    background: "#fff",
                    margin: "0px 10px",
                    borderRadius: "4px",
                  }}
                >
                  <Flex
                    sx={{
                      position: "relative",
                    }}
                  >
                    <img
                      style={{ borderRadius: "4px", maxHeight: "200px" }}
                      src={u?.picture || "/img/placeholderImage.png"}
                    />
                    <Flex
                      sx={{
                        bottom: 0,
                        position: "absolute",
                        borderRadius: "4px",
                        background: "linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);",
                      }}
                      paddingY="5px"
                      width="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Flex marginBottom="25px" justifyContent="center" alignItems="center" pr={1}>
                        <svg
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            height: "25px",
                            width: "25px",
                          }}
                          viewBox="0 0 551.034 551.034"
                        >
                          <path
                            id="XMLID_17_"
                            fill="#FFF"
                            d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722 c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156 C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156 c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722 c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"
                          />

                          <path
                            id="XMLID_81_"
                            fill="#FFF"
                            d="M275.517,133C196.933,133,133,196.933,133,275.516 s63.933,142.517,142.517,142.517S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6 c-48.095,0-87.083-38.988-87.083-87.083s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083 C362.6,323.611,323.611,362.6,275.517,362.6z"
                          />

                          <circle id="XMLID_83_" fill="#FFF" cx="418.306" cy="134.072" r="34.149" />
                        </svg>
                      </Flex>

                      <Text
                        color="#FFF"
                        fontSize=".9em"
                        marginBottom="20px"
                        fontWeight="200"
                        onClick={async () => {
                          await onClickUserName(u);
                        }}
                      >
                        <strong style={{ color: "#FFF", wordBreak: "break-all" }}> {u?.username}</strong>
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </div>
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
