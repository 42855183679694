import Twitter from "react-color/lib/Twitter";
import styled from "styled-components";
import { useField } from "react-final-form";
import { Flex } from "reflexbox";
import { useState } from "react";
import { MdExpandMore } from "react-icons/md";
import { Text } from "src/common-ui";
import useSWR from "swr";
import { StyleType, ColorType } from "@kards/types";
import { db } from "src/firebase";
import { toArray } from "src/helpers/toArray";
import { colorsExa } from "@TogetherSAS/kards-components";
import { ReactComponent as ColorFill } from "./color.svg";
import { useEstablishment } from "src/helpers/useEstablishment";

export function ColorField({ name, label = "color" }) {
  const { input } = useField(name);
  const [openModal, setOpenModal] = useState(false);
  const colors = useGetEstablishmentColors();

  return (
    <Flex width="100%" alignItems="center">
      <Flex flex="1">
        <Text alignItems="center" justifyContent="flex-start" fontSize={1}>
          {label}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        sx={{ border: "1px solid", borderColor: "secondary.light", borderRadius: "2px" }}
        onClick={() => setOpenModal(true)}
        px="5px"
        py="2px"
      >
        <ColorFillSvg color={input.value} />
        <MdExpandMore color="secondary" />
      </Flex>
      {openModal && (
        <>
          <div
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onClick={() => setOpenModal(false)}
          />
          <ColorPickup
            triangle="hide"
            colors={colors}
            color={input.value}
            onChange={(c) => {
              input.onChange(c.hex);
              setOpenModal(false);
            }}
          />
        </>
      )}
    </Flex>
  );
}

const ColorFillSvg = styled<any>(ColorFill)`
  path {
    fill: ${(props) => props.color} !important;
  }
  .secondary__fill {
    fill: ${(props) => (props.color === "#ffffff" ? "grey" : props.color)} !important;
  }
`;

const ColorPickup = styled<any>(Twitter)`
  position: absolute !important;
  width: 135px !important;
  right: 16px !important;
  top: 43px !important;
  z-index: 200;

  div {
    span {
      div {
        box-shadow: 0px 0px 5px 1px #eeeeee;
        :hover {
          transform: scale(1.09);
          transition: transform linear 100ms;
        }
      }
    }
  }
`;

export function useGetEstablishmentColors() {
  const type: StyleType["type"] = "color";
  const establishmentId = useEstablishment();

  const { data: establishmentColors } = useSWR<ColorType[]>(
    establishmentId ? `/establishments/${establishmentId}/styles/${type}` : null,
    () =>
      db
        .collection(`/establishments/${establishmentId}/styles`)
        .where("type", "==", type)
        .get()
        .then((docs) => toArray<ColorType>(docs)),
  );

  const colors =
    establishmentColors?.length > 0 ? [...colorsExa, ...establishmentColors?.map((e) => e?.hex)] : colorsExa;

  return colors;
}
