import React from "react";
import { Box, Flex } from "reflexbox";
import { MediaComponent } from "../commons/Media/Media";
import { Title } from "../commons/Text";
import dayjs from "dayjs";
import { Event, ThemeCard } from "@kards/types";

type EventWithPictureProps = {
  event: Event;
  dev?: boolean;
  hostImage?: any;
  backgroundLocation?: string;
};

export function EventWithPicture({ event, hostImage, dev = false, backgroundLocation }: EventWithPictureProps) {
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            background: "white",
            color: "grey",
            zIndex: 1,
            padding: "5px",
            top: "10px",
            borderRadius: "20px",
            fontSize: "0.7em",
            position: "absolute",
            right: "20px",
          }}
        >
          {dayjs(event?.startDate?.seconds * 1000).fromNow()}
        </Box>
        <MediaComponent
          dev={dev}
          hostImage={hostImage}
          value={event?.picture}
          style={{ borderRadius: "5px", width: "100%" }}
        />
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{
            borderRadius: "5px",
            height: "20%",
            width: "100%",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            bottom: "35%",
          }}
        >
          <Box
            my={1}
            as="h2"
            bg="rgba(0,0,0,0.5)"
            p="5px"
            color="#fff"
            textAlign="center"
            fontWeight="bold"
            fontSize="min(3.7vw, 1.2em)"
            fontFamily="inherit"
            sx={{
              border: "1px solid #fff",
            }}
          >
            {event?.reference}
          </Box>
          {event?.locations?.[0] && (
            <Box
              as="h2"
              my="3px"
              padding="5px"
              bg={`${backgroundLocation}bf` || "rgba(0,0,0,0.5)"}
              p="5px"
              color="#fff"
              textAlign="center"
              fontWeight="bold"
              fontSize="min(3.7vw, 1.2em)"
              fontFamily="inherit"
              sx={{
                border: "1px solid #fff",
                transform: "rotate(-10deg)",
              }}
            >
              {event?.locations?.[0]}
            </Box>
          )}
        </Flex>
        <Flex
          alignItems="center"
          minWidth="auto"
          sx={{ position: "absolute", zIndex: "1", bottom: "2px" }}
          flexWrap="wrap"
        >
          {event?.categories?.map((p) => (
            <Flex
              key={p}
              as="button"
              fontStyle="italic"
              padding="5px"
              margin="5px"
              minWidth="auto"
              justifyContent="center"
              alignItems="center"
              backgroundColor="rgba(0,0,0,0.5)"
              color="#fff"
              fontSize="0.8em"
            >
              {p}
            </Flex>
          ))}
        </Flex>
      </Box>
    </>
  );
}

type EventWithoutPictureProps = {
  event: Event;
  theme: ThemeCard;
  backgroundLocation?: string;
};

export function EventWithoutPicture({ theme, event, backgroundLocation }: EventWithoutPictureProps) {
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          <Box
            color={theme?.color.title}
            sx={{
              padding: "5px",
              fontSize: "0.8em",
              right: "20px",
            }}
          >
            <Box color={theme?.color.title}>{dayjs(event?.startDate?.seconds * 1000).format("HH:mm")}</Box>
            <Box color={theme?.color.title}>{dayjs(event?.endDate?.seconds * 1000).format("HH:mm")}</Box>
          </Box>
          <Flex maxWidth="80%" mx={1} flexDirection="column">
            <Title color={theme?.color.title} fontWeight="bold" fontSize="min(3.7vw, 1em)">
              {event?.reference}
            </Title>
            <Box alignItems="center" minWidth="auto" display="flex" flexWrap="wrap">
              {event?.categories?.map((p) => (
                <Box
                  key={p}
                  as="button"
                  fontStyle="italic"
                  color={theme?.color.subtitle}
                  display="flex"
                  padding="5px"
                  minWidth="auto"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="0.8em"
                >
                  {p}
                </Box>
              ))}
            </Box>
            <Flex>
              {event?.locations?.[0] && (
                <Box
                  backgroundColor={backgroundLocation}
                  p="5px"
                  color="white"
                  textAlign="center"
                  fontWeight="bold"
                  fontSize="min(3.7vw, 0.8em)"
                  fontFamily="inherit"
                >
                  {event?.locations?.[0]}
                </Box>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
