import { Route, Switch } from "react-router-dom";
import { ErrorPaymentMethod } from "src/auth/ErrorPaymentMethod";
import { ErrorSubscription } from "src/auth/ErrorSubscription";
import { ErrorUnAuthorized } from "src/auth/ErrorUnAuthorized";

export function ErrorRoute({}) {
  return (
    <Switch>
      <Route path="/errorPaymentMethod" component={ErrorPaymentMethod} />;
      <Route path="/errorSubscription" component={ErrorSubscription} />;
      <Route path="/errorUnauthorize" component={ErrorUnAuthorized} />;
    </Switch>
  );
}
