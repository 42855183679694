import React, { useRef, useEffect } from "react";

const noop = () => {};

export function VideoPlayer({
  src,
  onEnd,
  fullscreen = false,
  onFullScreen = noop,
  onExitFullScreen = noop,
  ...props
}: React.VideoHTMLAttributes<HTMLVideoElement> & {
  fullscreen?: boolean;
  onEnd?: () => void;
  onFullScreen?: () => void;
  onExitFullScreen?: () => void;
}) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (window.Hls.isSupported() && videoElement) {
      const hls = new window.Hls();
      hls.loadSource(src);
      hls.attachMedia(videoElement);
    }
    if (videoElement && onEnd) {
      videoElement?.addEventListener("ended", () => onEnd());
    }
  }, [videoRef.current]);

  useEffect(() => {
    if (fullscreen) {
      const videoElement = videoRef.current as any;

      if (videoElement && videoElement?.webkitRequestFullScreen) {
        videoElement.webkitRequestFullScreen();
      }

      videoElement.addEventListener("webkitfullscreenchange", function() {
        const state =
          (document as any).fullScreen || (document as any).mozFullScreen || (document as any).webkitIsFullScreen;
        if (state) {
          onFullScreen();
        } else {
          onExitFullScreen();
        }
      });
    }
  }, [videoRef.current, fullscreen]);

  return <video ref={videoRef} src={src} {...props} />;
}
