import { auth, firebase } from "src/firebase";

export async function signIn({ email, password }) {
  await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  try {
    const signIn: any = await auth.signInWithEmailAndPassword(email, password);
    if (signIn?.code) throw signIn?.code;
  } catch (e) {
    throw e;
  }
}
