import React, { useState, useEffect } from "react";
import { Event, ThemeCard } from "@kards/types";
import { Box, Flex } from "reflexbox";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import uniqBy from "lodash/uniqBy";
import dayjs from "dayjs";

export type FilterDateProps = {
  events?: Event[];
  theme: ThemeCard;
  onClickDay: (day: Date) => void;
};

export function FilterDate({ theme, events, onClickDay }: FilterDateProps) {
  const [dayIndex, setDayIndex] = useState<number>();

  const ds: { key: string; date: Date }[] = uniqBy(
    events?.map((e) => {
      const date = new Date(e.startDate.seconds * 1000);
      const key = dayjs(date).format("dddd DD MMMM");
      return { key, date };
    }),
    "key",
  );
  const getDates = (format: string) => ds.map((d) => dayjs(d.date).format(format));

  const dateByDay = getDates("dddd DD MMMM");
  const isDayFormat = dateByDay?.length === 1;
  const dates: string[] = isDayFormat ? getDates("HH [h] mm") : dateByDay;
  const dateSelected = dayIndex === undefined ? undefined : dates[dayIndex];

  useEffect(() => {
    const index = dates.findIndex((d) => d === dayjs().format("dddd DD MMMM"));
    setDayIndex(index <= 0 ? 0 : index);
  }, []);

  useEffect(() => {
    if (dayIndex !== undefined) {
      const day = ds[dayIndex]?.date;
      onClickDay(day);
    }
  }, [dayIndex]);

  return (
    <Flex justifyContent="space-between">
      <Box p={2} fontWeight="bold" color={theme?.color?.title}>
        {dateSelected?.toUpperCase()}
      </Box>
      <Flex justifyContent="center" alignItems="center" mx={1}>
        <Circle theme={theme} onClick={() => setDayIndex(dayIndex <= 0 ? dates.length - 1 : dayIndex - 1)}>
          <BiLeftArrow color={theme?.color?.title} />
        </Circle>
        <Box mx={1} />
        <Circle theme={theme} onClick={() => setDayIndex((dayIndex + 1) % dates.length)}>
          <BiRightArrow color={theme?.color?.title} />
        </Circle>
      </Flex>
    </Flex>
  );
}

function Circle({ theme, ...props }) {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="30px"
      height="30px"
      sx={{
        border: `1px solid ${theme?.color?.title}`,
        color: theme?.color?.title,
        borderRadius: "50%",
      }}
      {...props}
    />
  );
}
