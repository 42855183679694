import { ThemeCard } from "@kards/types";
import React from "react";
import { Box } from "reflexbox";
import { Title } from "../commons";

export type PdfViewerProps = {
  title?: string;
  theme?: ThemeCard;
  src: string;
} & React.IframeHTMLAttributes<HTMLIFrameElement>;

export function PdfViewer({ title, src, theme, ...props }: PdfViewerProps) {
  return (
    <>
      {Boolean(title) && <Title theme={theme} title={title} fontSize="min(5.5vw,1.4em)" width="100%" />}
      <Box
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        <iframe
          style={{
            border: "0",
            height: "100%",
            left: "0",
            position: "absolute",
            top: "0",
            width: "100%",
          }}
          src={src}
          width="100%"
          {...props}
          frameBorder="0"
        />
      </Box>
    </>
  );
}
