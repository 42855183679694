import { OnBoardingLayout, OnBoardingLayoutProps } from "./OnBoardingLayout";
import { Box, Flex } from "reflexbox";
import { Button, Card, Text } from "src/common-ui";
import { StripeProductType } from "@kards/types";
import { Input } from "src/common-ui/Form";
import { functions } from "src/firebase";
import { useState } from "react";
import { AppLoader } from "src/app/AppLoader";
import { useHistory } from "react-router";

export function debounce(f, interval, timer, setTimer) {
  return (...args) => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      setTimer(setTimeout(() => resolve(f(...args)), interval));
    });
  };
}

type SelectStripeProductProps = Omit<OnBoardingLayoutProps, "children" | "title">;

export function SelectStripeProduct({
  onNext,
  onBack,
  setStep,
  setStripeProduct,
  establishmentId,
  ...props
}: SelectStripeProductProps) {
  const [errorRes, seErrorRes] = useState(false);
  const [loading, seLoading] = useState(false);
  const [codeValue, setCodeValue] = useState(null);
  const history = useHistory();

  async function onClick(product: StripeProductType) {
    setStep("payment");
    setStripeProduct(product);
  }

  async function handleSendCode() {
    if (codeValue) {
      seErrorRes(false);
      seLoading(true);
      const res = await functions.httpsCallable("onboardingFree")({ code: codeValue });
      seLoading(false);
      setCodeValue(null);
      if (res?.data === "wrong_code") {
        seErrorRes(true);
      } else {
        history.push(`/establishments/${establishmentId}/welcome`);
      }
    }
  }

  if (loading) return <AppLoader />;

  return (
    <OnBoardingLayout onNext={onNext} onBack={onBack} title="Choisissez votre offre" {...props}>
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="20px"
        height="100%"
        flexDirection="column"
        sx={{ position: "relative" }}
      >
        <Box p={2} fontWeight="bold" fontSize="20px">
          Choissisez votre offre
        </Box>
        <Card p={1} width="100%" maxWidth="800px">
          <Box display="flex" flexDirection="column">
            <Box padding={2} flexDirection="row" display="flex">
              <Box flex={0.45} justifyContent="center" alignItems="center">
                <Button width="90%" size="large" fontSize="20px" onClick={() => onClick("product50")}>
                  Kards 5 espaces
                </Button>
              </Box>

              <Box flex={0.55} justifyContent="center" alignItems="center">
                Abonnement de 50€ par mois. 5 espaces espaces maximum sont disponibles pour cet abonnement.
              </Box>
            </Box>
            <Box padding={2} flexDirection="row" display="flex">
              <Box flex={0.45} justifyContent="center" alignItems="center">
                <Button width="90%" size="large" fontSize="20px" onClick={() => onClick("product100")}>
                  Kards 20 espaces
                </Button>
              </Box>
              <Box flex={0.55} justifyContent="center" alignItems="center">
                Abonnement de 100€ par mois. 20 espaces espaces maximum sont disponibles pour cet abonnement.
              </Box>
            </Box>
            <Box padding={2} flexDirection="row" display="flex">
              <Box flex={0.45} justifyContent="center" alignItems="center">
                <Button width="90%" size="large" fontSize="20px" onClick={() => onClick("product175")}>
                  Kards 50 espaces
                </Button>
              </Box>
              <Box flex={0.55} justifyContent="center" alignItems="center">
                Abonnement de 175€ par mois. 50 espaces espaces maximum sont disponibles pour cet abonnement.
              </Box>
            </Box>
            <Box padding={2} flexDirection="row" display="flex">
              <Box flex={0.45} justifyContent="center" alignItems="center">
                <Button width="90%" size="large" fontSize="20px" onClick={() => onClick("product200")}>
                  Kards illimité
                </Button>
              </Box>
              <Box flex={0.55} justifyContent="center" alignItems="center">
                Abonnement de 200€ par mois. Quantité d'espaces disponibles illimité pour cet abonnement.
              </Box>
            </Box>
          </Box>
        </Card>
        <Box p={2} fontWeight="bold" fontSize="20px">
          Ou saissisez un code Kards
        </Box>
        <Card p={1} width="100%" maxWidth="800px">
          <Box display="flex" flexDirection="column" width="100%">
            <Flex padding={2} flexDirection="row" justifyContent="center" alignItems="center">
              <Flex flex={0.3} justifyContent="center" alignItems="center">
                Code Kards
              </Flex>
              <Flex flex={0.4} justifyContent="center" alignItems="center">
                <Input onChange={(e) => setCodeValue(e?.target?.value)} />
              </Flex>
              <Flex flex={0.3} justifyContent="center" alignItems="center">
                <Button onClick={handleSendCode}>Valider</Button>
              </Flex>
            </Flex>
          </Box>
          {errorRes && (
            <Text style={{ position: "absolute", bottom: "25px", left: "35%" }} color="red.main" fontSize="12px">
              Le code saisie est incorrect
            </Text>
          )}
        </Card>
      </Box>
    </OnBoardingLayout>
  );
}
