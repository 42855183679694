import React from "react";
import { Media, ThemeCard } from "@kards/types";
import Slider from "react-slick";
import { MediaComponent } from "../commons/Media/Media";
import { Box } from "reflexbox";

type MediaSlideProps = {
  medias: Media[];
  hostImage: string;
  itemsPerColumn?: number;
  dev?: boolean;
  theme: ThemeCard;
  autoPlay?: boolean;
};

function Arrow(props) {
  const { className, style, onClick, margin } = props;
  return (
    <div className={className} style={{ ...style, zIndex: "10", display: "block", ...margin }} onClick={onClick} />
  );
}

export const MediaSlide = ({ dev, medias, hostImage, itemsPerColumn, theme, autoPlay }: MediaSlideProps) => {
  console.log("=> ~ file: index.tsx ~ line 24 ~ MediaSlide ~ autoPlay", autoPlay);
  const settings = {
    arrows: true,
    infinite: autoPlay,
    autoplay: autoPlay,
    autolaySpeed: 2000,
    speed: 500,
    dots: false,
    slidesToShow: itemsPerColumn,
    slidesToScroll: 1,
    nextArrow: <Arrow margin={{ marginRight: "33px" }} />,
    prevArrow: <Arrow margin={{ marginLeft: "33px" }} />,
  };

  return (
    <Slider {...settings}>
      {medias?.map((media) => (
        <Box
          key={media?.uuid || media?.id}
          sx={{ position: "relative", borderRadius: "5px", width: "45%" }}
          padding="5px"
        >
          <MediaComponent
            dev={dev}
            hostImage={hostImage}
            value={media}
            style={{ borderRadius: "5px", width: "100%" }}
          />
          {(media?.title || media?.description) && (
            <Box display="flex" flexDirection="column" mt="5px" paddingBottom={1} paddingX="6px">
              {media?.title && (
                <Box
                  as="h2"
                  fontWeight="bold"
                  fontSize="min(3.7vw, .8em)"
                  fontFamily="inherit"
                  color={theme?.color?.title}
                >
                  {media?.title}
                </Box>
              )}
              {media?.description && (
                <Box as="p" fontSize="min(3.5vw, .7em)" fontFamily="inherit" color={theme?.color?.title}>
                  {media?.description}
                </Box>
              )}
            </Box>
          )}
        </Box>
      ))}
    </Slider>
  );
};
