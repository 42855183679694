import { Flex } from "reflexbox";
import { useThemeUI } from "theme-ui";

type BoxProps = React.ComponentProps<typeof Flex>;

export const Card = (props: BoxProps) => {
  const { theme }: any = useThemeUI();
  return (
    <Flex
      backgroundColor="primary.white_to_dark"
      p={3}
      sx={{
        boxShadow: theme?.shadow.normal,
        // boxShadow: "0px 12px 26px -10px rgb(34 37 59 / 26%)",
        borderRadius: "10px",
      }}
      {...props}
    />
  );
};
