import { useState, useEffect } from "react";

export function useFirebaseOnSnapshot<T extends any>(
  getDataOnSnapshot,
  trigger = [],
  establishment = "",
  collection = null
) {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let didCancel = true;
    const unsubscribe = getDataOnSnapshot((d: T) => {
      if (didCancel && d) {
        setData(d);
        setLoading(false);
      }
    }, establishment, collection);

    return () => {
      if (unsubscribe) unsubscribe();
      didCancel = false;
    };
  }, trigger);

  return { data, loading, setData };
}
