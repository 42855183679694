import { useState } from "react";
import { forgotPassword } from "src/data/forgotPassword";
import { Box, Flex } from "reflexbox";
import { Button, TitleGreyBold, Text, Card } from "src/common-ui";
import { TextField, FormFinal } from "src/common-ui/Form";
import { ContainerForm } from "./ContainerForm";
import { useNotify } from "src/layout/Notification";
import { Link } from "react-router-dom";

interface FormValues {
  email?: string;
}

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const handleForgotPassword = async ({ email }) => {
    setLoading(true);
    const res = await forgotPassword({ email });
    setLoading(false);
    if (res?.code === "auth/user-not-found") return notify({ label: "Email non valide", type: "error" });
    if (res?.code === "auth/invalid-email") return notify({ label: "Email non valide", type: "error" });
    notify({ label: "Email envoyé", type: "success" });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = "ra.validation.required";
    }
    return errors;
  };

  return (
    <ContainerForm loading={loading}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" mb={3} mt={2}>
        <Box p={2}>
          <img src="/img/kards/kards1.svg" alt="logo" width="250px" />
        </Box>
        <TitleGreyBold>Mot de passe oublié ?</TitleGreyBold>
        <Box pb={2} />
      </Flex>

      <Flex flexDirection="column" alignItems="center" justifyContent="center" width="100%" sx={{ zIndex: "1" }}>
        <Card p="20px" width="100%" maxWidth="700px">
          <FormFinal validate={validate} onSubmit={handleForgotPassword}>
            <Flex flexDirection="column" alignItems="center" justifyContent="center" sx={{ zIndex: "1" }}>
              <Flex flexDirection="column" width="100%">
                <Text>
                  Saisissez l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser
                  votre mot de passe.
                </Text>
                <Box p={1} />

                <TextField
                  name="email"
                  data-cy="input-email"
                  label={"Email"}
                  type="email"
                  disabled={loading}
                  width="100%"
                />
                <Box p={1} />
                <Flex pb={2} alignItems="center" justifyContent="center" width="100%">
                  <Button data-cy="btn-signUp" type="submit" disabled={loading}>
                    Valider
                  </Button>
                  <Box p={1} />
                  <Button data-cy="btn-back" bg="grey.main">
                    <Link to={`/login`}>Retour</Link>
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </FormFinal>
        </Card>
      </Flex>
    </ContainerForm>
  );
}
