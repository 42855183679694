export const colors = {
  green_one: "#b9f3b3",
  success: "#10dc60",
  green_two: "#2e8e55",
  green_three: "#0c6602",

  blue_one: "#90d6f7",
  secondary: "#0cd1e8",
  blue_two: "#2293a0",
  primary: "#3880ff",
  blue_three: "#303778",

  purple_one: "#b49ff8",
  purple_two: "#c97dc9",
  purple_three: "#7c2f7c",
  tertiary: "#7044ff",

  yellow_one: "#ffeb9d",
  warning: "#ffce00",
  yellow_two: "#f3c034",
  yellow_three: "#aa7d03",

  red_one: "#e29191",
  red_two: "#df5330",
  danger: "#f04141",

  pink_one: "#fdcfdf",
  pink_two: "#fbbedf",
  pink_three: "#fca3cc",

  orange_one: "#e77224",
  orange_two: "#fa976a",

  dark: "#222428",
  medium: "#989aa2",
  light: "#f4f5f8",
  white: "#ffffff",
};

export const colorsExa = [
  "#b9f3b3",
  "#10dc60",
  "#2e8e55",
  "#0c6602",

  "#90d6f7",
  "#0cd1e8",
  "#2293a0",
  "#3880ff",
  "#303778",

  "#b49ff8",
  "#c97dc9",
  "#7c2f7c",
  "#7044ff",

  "#ffeb9d",
  "#ffce00",
  "#f3c034",
  "#aa7d03",

  "#e29191",
  "#df5330",
  "#f04141",

  "#fdcfdf",
  "#fbbedf",
  "#fca3cc",

  "#e77224",
  "#fa976a",

  "#000000",
  "#222428",
  "#989aa2",
  "#f4f5f8",
  "#ffffff",
];
