import React from "react";
import styled from "styled-components";
import useSWR from "swr";
import { fetcher } from "../commons/fetcher";

const LocationSvg = () => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 12">
      <path
        fillRule="nonzero"
        fill="white"
        d="M9 5c0-2.265-1.757-4-4-4-2.243 0-4 1.735-4 4 0 1.16 1.32 3.094 4 5.636C7.68 8.094 9 6.16 9 5zm-4 7C1.667 8.967 0 6.634 0 5c0-2.851 2.239-5 5-5s5 2.149 5 5c0 1.634-1.667 3.967-5 7zm0-6a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
      ></path>
    </svg>
  </>
);

export function Weather({ title, location, hostApi }) {
  const { data } = useSWR(`${hostApi}/weather/${location}`, fetcher);

  return (
    <CustomWrapper className="notranslate" icon={data?.weather?.[0]?.icon}>
      {data && (
        <Container>
          <ContainerDataFlex>
            <ContainerFlex style={{ alignItems: "flex-start" }}>
              <ContainerDataFlex>
                <LocationSvg />
                <Location>{title || location}</Location>
              </ContainerDataFlex>
              <Temperature>{Math.round(data?.main?.temp)} C °</Temperature>
            </ContainerFlex>
            <ContainerFlex>
              <CustomImg
                width="40px"
                src={`https://openweathermap.org/img/wn/${data?.weather?.[0]?.icon}@2x.png`}
                alt="img météo"
              />
              <MeteoIndication>{data?.weather?.[0]?.description}</MeteoIndication>
            </ContainerFlex>
          </ContainerDataFlex>
        </Container>
      )}
    </CustomWrapper>
  );
}

const CustomImg = styled.img`
  margin-left: auto;
  margin-right: auto;
`;

const Location = styled.div`
  margin-left: 6px;
  font-size: 13.125px;
  color: white;
`;

const MeteoIndication = styled.div`
  font-size: 11.6667px;
  margin-top: 1px;
  color: white;
`;

const Temperature = styled.div`
  margin-top: 0px;
  font-size: 25px;
  font-weight: bold;
  margin-top: 4px;
  line-height: 1.208333;
`;

const ContainerDataFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  color: white;
`;

const CustomWrapper = styled.div`
  padding: 10.2083px 17.5px;
  background: ${(props) => `url(/assets/weather/${props.icon}.jpg)`} rgb(77, 52, 243);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center !important;
  border-radius: 10px;
`;
