import React from "react";
import styled from "styled-components";
import useSWR from "swr";
import { fetcher } from "../commons/fetcher";
import { Box, Flex } from "reflexbox";

export function GoogleReview({ place_id, hostApi }) {
  const { data } = useSWR(`${hostApi}/google-review/${place_id}`, fetcher);
  const newArray = Array.from({ length: Math.round(data?.rating) }, (_, i) => i + 1);
  const totalReview = data?.user_ratings_total;
  const array = Array.from({ length: 5 }, (_, i) => i + 1);

  return (
    <Box m="auto" p={2} bg="#ffffff" sx={{ borderRadius: "10px" }} className="notranslate" width="90%">
      <Flex>
        <Flex alignItems="center" justifyContent="center">
          <GoogleLogo />
        </Flex>
        <Flex justifyContent="center" alignItems="center" ml={2}>
          notes
        </Flex>
      </Flex>
      <Flex justifyContent="flex-start" alignItems="center">
        {data?.rating && (
          <>
            <Flex flex=".2" height="30px" justifyContent="center" alignItems="center">
              <RatingReview>{data?.rating}</RatingReview>
            </Flex>
            <Flex flex=".8" height="30px" alignItems="center">
              <Box sx={{ position: "absolute" }}>
                {array.map((i) => (
                  <svg key={i} style={{ width: "22px", height: "22px" }} viewBox="0 0 14 14">
                    <path
                      fill="rgba(17, 17, 17, 0.2)"
                      d="M6.826 10.743l-3.28 1.724a.5.5 0 0 1-.725-.528l.627-3.65a.5.5 0 0 0-.144-.443L.65 5.26a.5.5 0 0 1 .277-.853l3.666-.533a.5.5 0 0 0 .377-.273L6.61.279a.5.5 0 0 1 .896 0L9.147 3.6a.5.5 0 0 0 .376.273l3.666.533a.5.5 0 0 1 .277.853l-2.653 2.586a.5.5 0 0 0-.144.442l.627 3.651a.5.5 0 0 1-.726.528l-3.279-1.724a.5.5 0 0 0-.465 0z"
                    ></path>
                  </svg>
                ))}
              </Box>
              <Box sx={{ position: "absolute" }}>
                {newArray.map((i) => (
                  <svg key={i} style={{ width: "22px", height: "22px" }} viewBox="0 0 14 14">
                    <path
                      fill="rgb(255, 180, 0)"
                      d="M6.826 10.743l-3.28 1.724a.5.5 0 0 1-.725-.528l.627-3.65a.5.5 0 0 0-.144-.443L.65 5.26a.5.5 0 0 1 .277-.853l3.666-.533a.5.5 0 0 0 .377-.273L6.61.279a.5.5 0 0 1 .896 0L9.147 3.6a.5.5 0 0 0 .376.273l3.666.533a.5.5 0 0 1 .277.853l-2.653 2.586a.5.5 0 0 0-.144.442l.627 3.651a.5.5 0 0 1-.726.528l-3.279-1.724a.5.5 0 0 0-.465 0z"
                    ></path>
                  </svg>
                ))}
              </Box>
            </Flex>
          </>
        )}
      </Flex>
      {totalReview && <ReviewText>{totalReview} commentaires</ReviewText>}

      <Flex justifyContent="center" alignItems="center">
        <LinkWrite
          href={`https://search.google.com/local/writereview?placeid=${place_id}`}
          rel="noreferrer,noopener,nofollow"
          target="_blank"
        >
          Laisser un avis
        </LinkWrite>
      </Flex>
    </Box>
  );
}

export function GoogleReviewStore({ place_id, hostApi }) {
  const { data } = useSWR(`${hostApi}/google-review/${place_id}`, fetcher);
  const newArray = Array.from({ length: Math.round(data?.rating) }, (_, i) => i + 1);
  const totalReview = data?.user_ratings_total;
  const array = Array.from({ length: 5 }, (_, i) => i + 1);

  return (
    <Box className="notranslate">
      <Flex>
        {data?.rating && (
          <>
            <Flex height="30px" pr="4px">
              <RatingReview>{data?.rating}</RatingReview>
            </Flex>
            <Flex height="30px" alignItems="center">
              <Box sx={{ position: "absolute" }}>
                {array.map((i) => (
                  <svg key={i} style={{ width: "18px", height: "18px" }} viewBox="0 0 14 14">
                    <path
                      fill="rgba(17, 17, 17, 0.2)"
                      d="M6.826 10.743l-3.28 1.724a.5.5 0 0 1-.725-.528l.627-3.65a.5.5 0 0 0-.144-.443L.65 5.26a.5.5 0 0 1 .277-.853l3.666-.533a.5.5 0 0 0 .377-.273L6.61.279a.5.5 0 0 1 .896 0L9.147 3.6a.5.5 0 0 0 .376.273l3.666.533a.5.5 0 0 1 .277.853l-2.653 2.586a.5.5 0 0 0-.144.442l.627 3.651a.5.5 0 0 1-.726.528l-3.279-1.724a.5.5 0 0 0-.465 0z"
                    ></path>
                  </svg>
                ))}
              </Box>
              <Box sx={{ position: "absolute" }}>
                {newArray.map((i) => (
                  <svg key={i} style={{ width: "18px", height: "18px" }} viewBox="0 0 14 14">
                    <path
                      fill="rgb(255, 180, 0)"
                      d="M6.826 10.743l-3.28 1.724a.5.5 0 0 1-.725-.528l.627-3.65a.5.5 0 0 0-.144-.443L.65 5.26a.5.5 0 0 1 .277-.853l3.666-.533a.5.5 0 0 0 .377-.273L6.61.279a.5.5 0 0 1 .896 0L9.147 3.6a.5.5 0 0 0 .376.273l3.666.533a.5.5 0 0 1 .277.853l-2.653 2.586a.5.5 0 0 0-.144.442l.627 3.651a.5.5 0 0 1-.726.528l-3.279-1.724a.5.5 0 0 0-.465 0z"
                    ></path>
                  </svg>
                ))}
              </Box>
              <Box sx={{ position: "absolute" }} pl="100px">
                {totalReview && <ReviewText>({totalReview})</ReviewText>}
              </Box>
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  );
}

const LinkWrite = styled.a`
  margin-top: 10px;
  padding: 9px 20px;
  width: 150px;
  border-radius: 16px;
  background-color: rgb(28, 145, 255) !important;
  font-size: 11px;
  line-height: 14px;
  color: rgb(255, 255, 255) !important;
  display: inline-flex;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  transition: all 0.1s ease 0s;
  -webkit-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  text-decoration: none !important;
`;

const RatingReview = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 1.18;
  color: rgb(17, 17, 17);
`;

const ReviewText = styled.div`
  flex: 0.6;
  overflow: hidden;
  min-width: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.17;
  color: rgba(17, 17, 17, 0.5);
  display: flex;
`;

const GoogleLogo = () => (
  <svg width="85px" height="36px" viewBox="0 0 85 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title></title>
    <desc>Created with Sketch.</desc>
    <g id="logos/google__9PyzeUIx" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="google_2015__9PyzeUIx">
        <rect id="Rectangle__9PyzeUIx" x="0" y="0" width="85" height="36"></rect>
        <path
          d="M20.82051,13.8285403 L10.9585121,13.8285403 L10.9585121,16.7549491 L17.9526292,16.7549491 C17.6071093,20.8585601 14.1930357,22.6085804 10.9703375,22.6085804 C6.8472338,22.6085804 3.24942855,19.3645384 3.24942855,14.8175829 C3.24942855,10.3881993 6.67907946,6.97693356 10.9794447,6.97693356 C14.2972206,6.97693356 16.2522225,9.09196401 16.2522225,9.09196401 L18.3012295,6.97010295 C18.3012295,6.97010295 15.6712777,4.0423 10.8747175,4.0423 C4.76744864,4.0423 0.0422999999,9.19733415 0.0422999999,14.7654363 C0.0422999999,20.2215356 4.48712612,25.5420753 11.0306691,25.5420753 C16.7860807,25.5420753 20.9990888,21.5993904 20.9990888,15.7694496 C20.9990888,14.5394417 20.82051,13.8285403 20.82051,13.8285403 Z"
          id="path2998__9PyzeUIx"
          fill="#4285F4"
          fillRule="nonzero"
        ></path>
        <path
          d="M28.899228,11.7094728 C24.8526602,11.7094728 21.9526644,14.8730534 21.9526644,18.5627572 C21.9526644,22.3070153 24.7654943,25.5294394 28.9467825,25.5294394 C32.7319827,25.5294394 35.8329889,22.6363394 35.8329889,18.6432338 C35.8329889,14.0665692 32.2257972,11.7094728 28.899228,11.7094728 L28.899228,11.7094728 Z M28.9394662,14.4237174 C30.9294664,14.4237174 32.8151293,16.0325192 32.8151293,18.6249432 C32.8151293,21.1623705 30.9373934,22.8170247 28.930321,22.8170247 C26.7249386,22.8170247 24.9851565,21.0507416 24.9851565,18.6048245 C24.9851565,16.2113628 26.7033664,14.4237174 28.9394662,14.4237174 L28.9394662,14.4237174 Z"
          id="path3000__9PyzeUIx"
          fill="#EA4335"
          fillRule="nonzero"
        ></path>
        <path
          d="M44.0076424,11.7094728 C39.9610739,11.7094728 37.0610789,14.8730534 37.0610789,18.5627572 C37.0610789,22.3070153 39.8739088,25.5294394 44.0551961,25.5294394 C47.8403964,25.5294394 50.9414026,22.6363394 50.9414026,18.6432338 C50.9414026,14.0665692 47.3342109,11.7094728 44.0076424,11.7094728 Z M44.0478799,14.4237174 C46.03788,14.4237174 47.9235429,16.0325192 47.9235429,18.6249432 C47.9235429,21.1623705 46.0458079,22.8170247 44.0387355,22.8170247 C41.8333522,22.8170247 40.0935701,21.0507416 40.0935701,18.6048245 C40.0935701,16.2113628 41.8117801,14.4237174 44.0478799,14.4237174 L44.0478799,14.4237174 Z"
          id="path3005__9PyzeUIx"
          fill="#FBBC05"
          fillRule="nonzero"
        ></path>
        <path
          d="M58.8254221,11.716789 C55.1111158,11.716789 52.1916154,14.9699517 52.1916154,18.6212851 C52.1916154,22.7804935 55.5763625,25.5385847 58.7614036,25.5385847 C60.7308069,25.5385847 61.7780052,24.7568358 62.5511037,23.8595572 L62.5511037,25.2221671 C62.5511037,27.6062053 61.1035554,29.0338142 58.9186986,29.0338142 C56.8080082,29.0338142 55.7493001,27.4643717 55.3814018,26.5738019 L52.7256851,27.6840085 C53.667833,29.6760466 55.5643306,31.753546 58.9406464,31.753546 C62.6335642,31.753546 65.4482484,29.4274402 65.4482484,24.5490929 L65.4482484,12.1319728 L62.5511037,12.1319728 L62.5511037,13.3025365 C61.6609019,12.3426691 60.442749,11.716789 58.8254221,11.716789 Z M59.0942826,14.4255465 C60.9153348,14.4255465 62.7852164,15.9804564 62.7852164,18.6359176 C62.7852164,21.334963 60.9193203,22.822511 59.0540451,22.822511 C57.0738721,22.822511 55.2314229,21.2146516 55.2314229,18.6615234 C55.2314229,16.0085943 57.1449784,14.4255465 59.0942826,14.4255465 L59.0942826,14.4255465 Z"
          id="path3007__9PyzeUIx"
          fill="#4285F4"
          fillRule="nonzero"
        ></path>
        <path
          d="M78.3299385,11.7003275 C74.8262836,11.7003275 71.8845225,14.4878831 71.8845225,18.6011664 C71.8845225,22.9535598 75.1635695,25.5349266 78.6664756,25.5349266 C81.5900976,25.5349266 83.3844636,23.9354976 84.4552796,22.5024353 L82.0665902,20.9130298 C81.4467429,21.8749842 80.4104978,22.8151956 78.6811072,22.8151956 C76.7385042,22.8151956 75.8453177,21.7514775 75.2919567,20.7209835 L84.5576964,16.8764135 L84.0766693,15.7497463 C83.1811952,13.5433004 81.0930015,11.7003275 78.3299385,11.7003275 L78.3299385,11.7003275 Z M78.4506466,14.3578732 C79.7131527,14.3578732 80.621744,15.0291065 81.0076029,15.833881 L74.820074,18.4200945 C74.5533141,16.4178248 76.450195,14.3578732 78.4506466,14.3578732 Z"
          id="path3011__9PyzeUIx"
          fill="#EA4335"
          fillRule="nonzero"
        ></path>
        <polygon
          id="path3015__9PyzeUIx"
          fill="#34A853"
          fillRule="nonzero"
          points="67.4674711 25.1244795 70.5109366 25.1244795 70.5109366 4.7566717 67.4674711 4.7566717"
        ></polygon>
      </g>
    </g>
  </svg>
);
