import { Flex } from "reflexbox";
import { Text } from "src/common-ui";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

type CheckBoxProps = {
  checked: boolean;
  label?: string;
  onChange: () => void;
};

export function CheckBox({ checked, label = "", onChange }: CheckBoxProps) {
  return (
    <Flex alignItems="center" onClick={onChange}>
      <Flex color="primary.blue_to_gold" fontSize={3}>
        {checked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
      </Flex>
      {label && (
        <Text fontSize={"14px"} ml={1}>
          {label}
        </Text>
      )}
    </Flex>
  );
}
