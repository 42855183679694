import { useColorMode } from "theme-ui";

export function AppLoader() {
  const [colorMode] = useColorMode();
  return (
    <div
      id="app-loader"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        background: "white",
        height: "100vh",
        width: "100%",
      }}
    >
      <img
        alt="Kards"
        width="350px"
        height="auto"
        src={colorMode === "dark" ? "/img/kards/kards1.svg" : "/img/kards/kards1.svg"}
      />
      <div className="bar-loader">
        <div className="bar" style={{ backgroundColor: "#44C1F0" }}></div>
        <div className="bar" style={{ backgroundColor: "#21018a" }}></div>
        <div className="bar" style={{ backgroundColor: "#44C1F0" }}></div>
        <div className="bar" style={{ backgroundColor: "#21018a" }}></div>
        <div className="bar" style={{ backgroundColor: "#44C1F0" }}></div>
      </div>
    </div>
  );
}
