import { Flex, Box } from "reflexbox";
import React from "react";
import { SurveyComponentRoot, ThemeCard } from "@kards/types";
import { Text } from "../commons/Text";
import { noop } from "lodash";

export type SurveyProps = {
  theme: ThemeCard;
  survey: SurveyComponentRoot;
  onClick?: (id: string) => void;
  percentages?: { [x: string]: number }[];
  isUserVoted?: any;
};

export function Survey({ theme, survey, onClick = noop, percentages, isUserVoted }: SurveyProps) {
  if (isUserVoted) {
    if (survey.hideResults) {
      return (
        <Flex flexDirection="column" width="100%">
          <Text color={theme.color.title} textAlign="center" fontWeight="bold" fontSize="1.2em" width="100%" mb={2}>
            {survey.question}
          </Text>
          <Text color={theme.color.title} textAlign="center" fontWeight="bold" fontSize="1.2em" width="100%" mb={2}>
            Déja Répondu
          </Text>
        </Flex>
      );
    }

    return (
      <Flex flexDirection="column" width="100%">
        <Text color={theme.color.title} textAlign="center" fontWeight="bold" fontSize="1.2em" width="100%" mb={2}>
          {survey.question}
        </Text>
        <Flex justifyContent="center" flexDirection="column">
          {survey?.options?.map((option) => (
            <Button
              key={option?.id}
              textAlign="center"
              width="100%"
              my={"5px"}
              style={{
                background: option?.background,
              }}
              color={theme.color.title}
              fontWeight="bold"
              fontSize="1em"
              p={"15px"}
              display="flex"
              justifyContent="space-between"
            >
              <>
                <Text color={option.color}>{option.content}</Text>
                <Text color={option.color}>
                  {percentages?.find((percentage) => Object?.keys(percentage)[0] === option?.id)?.[option?.id] || 0} %
                </Text>
              </>
            </Button>
          ))}
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" width="100%">
      <Text color={theme.color.title} textAlign="center" fontWeight="bold" fontSize="1.2em" width="100%" mb={2}>
        {survey.question}
      </Text>
      <Flex justifyContent="center" flexDirection="column">
        {survey?.options?.map((option) => (
          <Button
            onClick={() => onClick(option?.id)}
            textAlign="center"
            width="100%"
            my={"5px"}
            style={{
              background: option?.background,
            }}
            color={theme.color.title}
            fontWeight="bold"
            fontSize="1em"
            p={"15px"}
          >
            <Text color={option.color}>{option.content}</Text>
          </Button>
        ))}
      </Flex>
    </Flex>
  );
}

function Button(props: React.ComponentProps<typeof Box>) {
  return <Box as="button" color="#FFF" px={3} py={1} sx={{ borderRadius: "15px" }} {...props} />;
}
