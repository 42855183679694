import { useEffect } from "react";
import { Flex, Box } from "reflexbox";
import { Card, TitleGreyBold, Text } from "src/common-ui";
import { ContainerForm } from "./ContainerForm";

import { useColorMode } from "theme-ui";

export function ErrorUnAuthorized() {
  const [, setColorMode] = useColorMode();
  useEffect(() => {
    setColorMode("");
  }, []);

  return (
    <ContainerForm loading={false}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" mb={3} mt={2}>
        <Box p={2}>
          <img src="./img/kards/kards1.svg" alt="logo" width="200px" />
        </Box>
        <TitleGreyBold>Oups</TitleGreyBold>
        <Box pb={2} />
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={["90%", "50%"]}
        sx={{ zIndex: "1" }}
      >
        <Card p="20px" width="100%" maxWidth="700px" flexDirection="column" alignItems="center" justifyContent="center">
          <Text fontSize={55}>404</Text>
        </Card>
      </Flex>
    </ContainerForm>
  );
}
