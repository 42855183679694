import { Box, Flex } from "reflexbox";
import { useField } from "react-final-form";
import { Label } from "./Label";
import { Input, Textarea } from "./Input";
import { ErrorField } from "./ErrorField";

export type TextFieldProps = {
  name?: string;
  label?: string | React.ReactNode;
  required?: boolean;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  width?: string;
  inputType?: "text" | "textarea";
} & React.ComponentProps<typeof Input>;

export function TextField({
  name,
  label,
  required,
  endAdornment,
  startAdornment,
  width,
  color = "label",
  validate = () => undefined,
  parse,
  format,
  showError = true,
  inputType = "text",
  ...props
}: TextFieldProps) {
  const { input, meta } = useField(name, { validate, parse, format });
  return (
    <Box mb={1} sx={{ position: "relative" }} width={width}>
      {label && <Label color={color}>{`${label}${required ? "*" : ""}`}</Label>}
      <label style={{ display: "flex", alignItems: "center" }}>
        {startAdornment && (
          <Flex sx={{ position: "absolute", left: "15px" }} alignItems="center" fontWeight="500">
            {startAdornment}
          </Flex>
        )}
        {inputType === "text" && <Input {...input} startAdornment={startAdornment} type="text" {...props} />}
        {inputType === "textarea" && <Textarea {...input} startAdornment={startAdornment} type="text" {...props} />}
        {endAdornment && (
          <Box sx={{ position: "absolute", right: "15px" }} fontWeight="500">
            {endAdornment}
          </Box>
        )}
        {props?.disabled && (
          <Box sx={{ position: "absolute", right: "15px" }} fontWeight="500">
            🔒
          </Box>
        )}
      </label>
      {showError && <ErrorField meta={meta} />}
    </Box>
  );
}

export function TextInput({ label, required, endAdornment, width, ...props }: TextFieldProps) {
  return (
    <Box mb={1} sx={{ position: "relative" }} width={width}>
      <label>
        <Label {...props}>{`${label}${required ? "*" : ""}`}</Label>
        <Input type="text" {...props} ref={props?.inputRef} />
        {endAdornment && (
          <Box sx={{ position: "absolute", top: "34px", right: "15px" }} fontWeight="500">
            {endAdornment}
          </Box>
        )}
      </label>
    </Box>
  );
}
