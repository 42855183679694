import React, { useState } from "react";
import { Box, Flex } from "reflexbox";
import { ThemeCard, Video } from "@kards/types";
import { VideoPlayer } from "./VideoPlayer";
import { PlayIcon } from "../PlayIcon";

type VideoComponentProps = {
  theme?: ThemeCard;
  value: Video;
  style?: any;
};

export function VideoComponent({ value, ...props }: VideoComponentProps) {
  const [isGif, setIsGif] = useState(true);

  if (isGif)
    return (
      <Box sx={{ position: "relative", width: "100%", height: "100%", minHeight: "200px" }}>
        <img
          loading="lazy"
          decoding="async"
          height="100%"
          src={`${value.gifURL}`}
          onClick={(e) => {
            e?.stopPropagation();
            e?.preventDefault();
            setIsGif(false);
          }}
          {...props}
        />

        <Flex
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          onClick={(e) => {
            e?.stopPropagation();
            e?.preventDefault();
            setIsGif(false);
          }}
          fontSize="2.5em"
          backgroundColor="#0e0c0c5c"
          sx={{ position: "absolute", top: 0, borderRadius: props?.style?.borderRadius }}
        >
          <PlayIcon />
        </Flex>
      </Box>
    );

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <VideoPlayer onEnd={() => setIsGif(true)} src={value.videoURL} width="100%" controls autoPlay />
    </Box>
  );
}
