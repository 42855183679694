import React from "react";
import { SubTitle, Title } from "../commons/Text";
import { Box } from "reflexbox";
import { MediaComponent } from "../commons/Media/Media";
import { Flex } from "reflexbox";
import { ThemeCard, Media as MediaType } from "@kards/types";

type MediaProps = {
  media: MediaType;
  hostImage: string;
  width?: string;
  theme: ThemeCard;
  dev?: boolean;
  radius?: number;
};

export function Media({ media, theme, dev = false, radius, hostImage }: MediaProps) {
  return (
    <Box color={theme?.color?.background}>
      {(media?.title || media?.description) && (
        <Box display="flex" flexDirection="column" paddingLeft={2} paddingTop={2} paddingBottom={1}>
          <Box>
            <Title theme={theme} title={media?.title} />
          </Box>
          <Box>
            <SubTitle theme={theme} title={media?.description} />
          </Box>
        </Box>
      )}
      <Flex sx={{ position: "relative", overflow: "hidden" }}>
        <MediaComponent dev={dev} style={{ width: "100%" }} hostImage={hostImage} radius={radius} value={media} />
      </Flex>
    </Box>
  );
}
