import { Flex, Box } from "reflexbox";
import React, { useState } from "react";
import { ComponentQuestion, ThemeCard } from "@kards/types";
import { Text } from "../commons/Text";
import { noop } from "lodash";

export type QuestionProps = {
  theme: ThemeCard;
  question: ComponentQuestion;
  onSubmitQuestion?: (text: string) => void;
  isUserVoted?: boolean;
  result?: string;
};

export function Question({ result, theme, question, onSubmitQuestion = noop, isUserVoted }: QuestionProps) {
  const [text, setText] = useState("");

  return (
    <Flex flexDirection="column" width="100%">
      <Text color={theme.color.title} textAlign="center" fontWeight="bold" fontSize="1.2em" width="100%">
        {question?.value?.question}
      </Text>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitQuestion(text);
        }}
        style={{ width: "100%" }}
      >
        <Flex
          color={theme.color.title}
          sx={{ borderRadius: "5px", zIndex: 999999, boxShadow: "0px 0px 20px 0px rgba(10, 10, 10, 0.15)" }}
          margin="5%"
          alignItems="center"
          flexDirection="column"
          padding="10px"
          overflowY="auto"
          textAlign="center"
        >
          <Input
            theme={theme}
            // value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Ecrivez quelque chose..."
          />
        </Flex>
        <Flex alignItems="center" flexDirection="column">
          <Button
            type="submit"
            theme={theme}
            disabled={isUserVoted || !text}
            text
            backgroundColor={!isUserVoted && text ? theme.color.title : "grey"}
          >
            Envoyer
          </Button>
          {isUserVoted && (
            <Box color={theme.color.title} textAlign="center" fontSize="0.8em" p="5px" fontWeight="inherit">
              Merci de votre réponse
              <Box my="5px">"{result}"</Box>
            </Box>
          )}
        </Flex>
      </form>
    </Flex>
  );
}

function Input({ theme, ...props }) {
  return (
    <Flex
      as="input"
      my={2}
      color={theme.color.title}
      placeholder="Ecrivez quelque chose..."
      sx={{
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        fontSize: "16px",
        width: "100%",
        "::placeholder": {
          fontStyle: "italic",
          color: theme.color.title,
        },
      }}
      {...props}
    />
  );
}

function Button({ theme, ...props }) {
  return (
    <Box
      as="button"
      color={theme.color.background}
      sx={{
        padding: "10px",
        borderRadius: "25px",
        boxShadow: "0px 0px 20px 0px rgba(10, 10, 10, 0.15)",
      }}
      {...props}
    />
  );
}
