import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/fr";
import { Post } from "./type";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.locale("fr");

type ItemCarouselProps = {
  post: Post;
};

export function CarouselItem({ post }: ItemCarouselProps) {
  return (
    <a
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      target="_blank"
      rel="noreferrer"
      href={post.permalink}
    >
      <Card>
        <Header>
          <HeaderContent>
            <HeaderProfilContent>
              <HeaderProfilTitle>{post.userName}</HeaderProfilTitle>
              <HeaderProfilTime>{post?.timestamp && dayjs(post?.timestamp).fromNow()}</HeaderProfilTime>
            </HeaderProfilContent>
          </HeaderContent>
        </Header>

        <Media post={post} />

        <MetaContent></MetaContent>
        <div>
          <CommentContent>{post?.caption}</CommentContent>
        </div>
      </Card>
    </a>
  );
}
export function CarouselItemGallery({ post }: ItemCarouselProps) {
  return (
    <a
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      target="_blank"
      rel="noreferrer"
      href={post.permalink}
    >
      <Media post={post} />
    </a>
  );
}

function Media({ post }: ItemCarouselProps) {
  if (post.media_type === "VIDEO")
    return <video src={post?.media_url} width="100%" height="100%" autoPlay loop muted />;

  return (
    <ImageContent>
      <ImageWrapper>
        <Image loading="lazy" decoding="async" src={post?.media_url} />
      </ImageWrapper>
    </ImageContent>
  );
}

const Card = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  width: 190px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px;
`;

const ImageContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
`;

const ImageWrapper = styled.div`
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  margin: -1px;
`;

const Image = styled.img`
  height: 100% !important;
  max-width: inherit !important;
  position: absolute;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
`;

const MetaContent = styled.div`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: row;
  padding: 0px 12px;
  line-height: 1;
  position: relative;
`;

const CommentContent = styled.div`
  font-size: 14px;
  width: 90%;
  line-height: 18px;
  max-height: 40px;
  margin: 5px 12px 12px 12px;
  background-color: ${(props) => props.backgroundColor};
  position: relative;
  display: inline-block;
  overflow: hidden;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const HeaderProfilContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const HeaderProfilTitle = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
`;

const HeaderProfilTime = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  opacity: 0.6;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Header = styled.div`
  display: flex;
  background-color: ${(props) => props.backgroundColor};
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  box-sizing: border-box;
  height: 60px;
`;
