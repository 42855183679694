import { Box } from "reflexbox";
import * as d3 from "d3";
import React, { useState } from "react";
import { createPortal } from "react-dom";

const colors = {
  bar: "#E6C630",
  terrace: "#2ED5C3",
  restaurant: "#F0942E",
  toilet: "#EB5F5A",
  lounge: "#FF7F6A",
  pool: "#00B3E1",

  spa: "#E6C630",
  corridor: "#2ED5C3",
  room: "#F0942E",
  hall: "#EB5F5A",
  elevator: "#FF7F6A",
  library: "#00B3E1",

  outside: "#E6C630",
  sport: "#2ED5C3",
};

const useD3 = (renderChartFn, dependencies) => {
  const ref = React.useRef();

  React.useEffect(() => {
    renderChartFn(d3.select(ref.current));
  }, dependencies);
  return ref;
};

function D3Map({
  minHeight,
  editable,
  currentPlan,
  spaces,
  currentSpaceId,
  activeSpace,
  onClickOnSpace,
  establishmentId,
}) {
  const ref = useD3(
    (svg) => {
      const imgHeight = 1025,
        imgWidth = 1538;

      // for the background
      svg
        .append("rect")
        .style("fill", "rgb(255,255,255)")
        .attr("width", "100%")
        .attr("height", "100%");

      const g = svg.append("g").classed("main", true);
      g.append("image")
        .attr("xlink:href", function() {
          return currentPlan;
        })
        .attr("width", imgWidth + "px")
        .attr("height", imgHeight + "px")
        .attr("style", "filter: opacity(0.5);");

      function handleZoom(e) {
        g.attr("transform", e.transform);
      }

      const zoom = d3
        .zoom()
        .scaleExtent([0.3, 20])
        .on("zoom", handleZoom);
      const transform = d3.zoomIdentity.translate(-150, 10).scale(0.5);
      g.attr("transform", transform);
      svg
        .call(zoom) // Adds zoom functionality
        .call(zoom.transform, transform); // Calls/inits handleZoom
    },
    [currentPlan],
  );

  React.useEffect(() => {
    const data = d3.group([...spaces, ...(activeSpace ? [activeSpace] : [])]);
    console.log("data", data);
    const rects = d3
      .select("g.main")
      .selectAll("g.space")
      .data(data, (d) => d?.id);
    rects.exit().remove();

    const newRects = rects
      .enter()
      .append("g")
      .attr("class", "space")
      .attr("transform", function(d) {
        return "translate(" + d.x + "," + d.y + ")";
      })
      .classed("pathPlanClicked", (d) => d?.id === currentSpaceId || d?.id === activeSpace?.id)
      .on("click", function(_d, e) {
        e?.id !== activeSpace?.id ? onClickOnSpace(e) : window.location.replace(`/${establishmentId}/${e.id}`);
      });
    newRects
      .append("path")
      .attr("id", (d) => d?.id)
      .attr("fill", (d) => (d?.color ? d?.color : colors[d?.type]))
      .attr("style", (d) => `fill:${d?.color ? d?.color : colors[d?.type]}`)
      .attr("width", "100px")
      .attr(
        "d",
        "M79.792 41.829c0-16.453-13.338-29.792-29.792-29.792S20.208 25.375 20.208 41.829c0 13.064 8.412 24.156 20.112 28.175l6.104 15.523c1.277 3.248 5.874 3.248 7.152 0l6.104-15.523c11.7-4.019 20.112-15.111 20.112-28.175z",
      );

    newRects
      .append("text")
      .attr("x", 45)
      .attr("y", 50)
      .attr("width", 35 + "px")
      .attr("height", 35 + "px")
      .attr("font-weight", "bold")
      .attr("fill", "white")
      .attr("font-size", "20px")
      .text((d) => (d?.id === currentSpaceId || d?.id === activeSpace?.id ? "" : d.name[0].toUpperCase()));

    newRects
      .append("text")
      .attr("style", (d) => (d?.id !== currentSpaceId ? "display: none" : ""))
      .attr("font-weight", "bold")
      .attr("fill", "white")
      .attr("font-size", "20px")
      .attr("x", (d) => (d?.id === currentSpaceId ? "-2%" : 0))
      .attr("y", (d) => (d?.id === currentSpaceId ? "-10%" : 0))
      .text(() => "Vous êtes ici");

    newRects
      .append("text")
      .attr("style", (d) => (d?.id !== currentSpaceId && d?.id !== activeSpace?.id ? "display: none" : ""))
      .attr("font-weight", "bold")
      .attr("fill", "white")
      .attr("font-size", "20px")
      .attr("x", (d) => (d?.id ? "2%" : 0))
      .attr("y", (d) => (d?.id ? "-2%" : 0))
      .on("click", function(_d, e) {
        editable && window.location.replace(`/${e.id}`);
      })
      .text((d) => d.name);
  }, [spaces, currentSpaceId, currentPlan, activeSpace, minHeight]);

  return (
    <Box>
      <svg
        ref={ref}
        style={{
          minHeight,
          width: "100%",
          height: "100%",
        }}
      />
    </Box>
  );
}

export function Plan({ editable = true, spaceId, component, establishmentId }) {
  const [activeSpace, setActiveSpace] = useState<any>();
  const [fullscreen, setFullScreen] = useState<any>();

  if (!component?.value?.spaces) return null;

  if (fullscreen)
    return createPortal(
      <Box
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        minWidth="100vw"
        sx={{ position: "fixed", zIndex: 10000, left: 0, top: 0 }}
      >
        <Box sx={{ position: "absolute", right: "20px", top: "20px" }} onClick={() => setFullScreen(!fullscreen)}>
          <img src="/img/full-screen.svg" height="20px" />
        </Box>
        <D3Map
          minHeight="100vh"
          establishmentId={establishmentId}
          editable={editable}
          currentPlan={component?.value?.plan}
          spaces={component?.value?.spaces}
          onClickOnSpace={setActiveSpace}
          currentSpaceId={spaceId}
          activeSpace={activeSpace}
        />
      </Box>,
      document.getElementsByTagName("main")?.[0],
    );
  return (
    <Box width="100%" height="100%" minHeight={"300px"} sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", right: "20px", top: "20px" }} onClick={() => setFullScreen(!fullscreen)}>
        <img src="/img/full-screen.svg" height="20px" />
      </Box>
      <D3Map
        minHeight="300px"
        establishmentId={establishmentId}
        editable={editable}
        currentPlan={component?.value?.plan}
        spaces={component?.value?.spaces}
        onClickOnSpace={setActiveSpace}
        currentSpaceId={spaceId}
        activeSpace={activeSpace}
      />
    </Box>
  );
}
