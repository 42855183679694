import { UIEvent, PhotoEditorSDKUI, ImageFormat, ExportFormat, Operation, Tool } from "photoeditorsdk";
import { useEffect } from "react";
import { MyProvider } from "src/common-ui/Library";
import { useEstablishment } from "src/helpers/useEstablishment";
import { Image } from "@kards/types";
import { useCreateImagefromImagephotoEditorSDK } from "./api";
import fr from "./fr";

export function PhotoEditorSDK({ image, onClose, onUpdateEnd }: { image: Image; onClose; onUpdateEnd: any }) {
  const updateImage = useCreateImagefromImagephotoEditorSDK();
  const establishmentId = useEstablishment();

  useEffect(() => {
    async function initEditor() {
      await PhotoEditorSDKUI.init({
        container: ".editor",
        license: JSON.stringify({
          api_token: "FvXgG5IHMy9wxmxINHz74g",
          app_identifiers: ["localhost", "admin.kards.fr", "a-kards.web.app"],
          available_actions: [],
          domains: ["https://api.photoeditorsdk.com"],
          enterprise_license: false,
          expires_at: null,
          features: [
            "camera",
            "library",
            "export",
            "customassets",
            "whitelabel",
            "brush",
            "filter",
            "focus",
            "overlay",
            "text",
            "textdesign",
            "transform",
          ],
          issued_at: 1641804327,
          minimum_sdk_version: "1.0",
          owner: "BVS Tech",
          platform: "HTML5",
          products: ["pesdk"],
          version: "2.4",
          signature:
            "gWP39B9nkT/5dstnA20zh51m/ILWtwveZeC6exW9OU7j9Oni9ziuMliM7Raavv2TJuUkxgRNmdKQ5LByr6uop6cUkKMokS7e9G9iGtuwWjMJUdImteIawGAjdxz/kVihM/4r0b9TqUa3CyL1ROFvkmUB6ins4DbzaUmBF/7tGw8u/8xXVqDzMj/VTdRhTNkb8GJzVmffJGkOU77jgY9sd4auYbm8bHHNiOKmzbDkbtNCAve71KpriyDuWVLrpjBYKU+JYyG168qDrf/4LdaBO6RQuXUecZ7eXnQnpDeME96U2+hegvsWPFrR6adN6XYxQQvztmNXm3w3B8UgpAxMuYrjKr5ALb3wPqkX/GR5i152D+/C0AswpAey/8TGo6zJ8UwUBf/huqJ1uhDjhszvmOPiPbz80sL/A3G5KxuZ/HuDarSlaOogrn5ZrCEmx9R39nPjRc+iWFpZavjzX4OtHwqJjRvqjga/RsO2gjfjq/7EpP5A/JVy0mbW+rVqGDmj6nLpycgCS9YsNZEn9x9yYVDsT19g3IbvkSJKGESylGh87SQE1ZN1WpXyCqPXgin29W2uKhIZQvqod2UEVTCJ2xoeyaUlDeqxhHQU8sI9UnV4LIrFOEI1L+pm9aTUu/21LJLigq0WE13eT2H6SB4DQnfd/YG0PemlQZ5tIBtO/ZE=",
        }),
        library: {
          provider: MyProvider,
        },
        language: "fr",
        custom: {
          languages: {
            fr,
          },
          themes: {
            light: {
              button: {
                containedPrimaryBackground: "#4949ec",
              },
              typography: {
                fontFamily: "Poppins",
                provider: "google",
                // Most users load font while initializing their app,
                // In that case, you can choose to skip loading it again
                skipLoading: false,
              },
            },
          },
        },
        tools: [
          "transform",
          "filter",
          "adjustment",
          "focus",
          "frame",
          "overlay",
          "text",
          "textdesign",
          "sticker",
          "brush",
        ] as Tool[],
        layout: "advanced",
        theme: "light",
        order: "default",
        enableZoom: true,
        displayResizeWarning: false,
        displayCloseWarning: true,
        displayToolControlBarTitle: true,
        image: image.original, // Image url or Image path relative to assets folder
        assetBaseUrl: "/assets",
        text: {
          fonts: [
            { identifier: "imgly_font_abril_fatface_regular" },
            { identifier: "imgly_font_aleo_bold" },
            { identifier: "imgly_font_amaticsc" },
            { identifier: "imgly_font_amberlight" },
            { identifier: "imgly_font_archivo_black" },
            { identifier: "imgly_font_bungee_inline" },
            { identifier: "imgly_font_campton_bold" },
          ],
        },
        textdesign: {
          items: [
            { identifier: "imgly_text_design_blocks" },
            { identifier: "imgly_text_design_rotated" },
            { identifier: "imgly_text_design_blocks_light" },
            { identifier: "imgly_text_design_equal_width" },
            { identifier: "imgly_text_design_masked" },
            { identifier: "imgly_text_design_celebrate" },
            { identifier: "imgly_text_design_sunshine" },
            { identifier: "imgly_text_design_masked_badge" },
            { identifier: "imgly_text_design_blocks_condensed" },
            { identifier: "imgly_text_design_celebrate_simple" },
            { identifier: "imgly_text_design_equal_width_fat" },
            { identifier: "imgly_text_design_watercolor" },
            { identifier: "imgly_text_design_particles" },
            { identifier: "imgly_text_design_masked_speech_bubble" },
            { identifier: "imgly_text_design_masked_speech_bubble_comic" },
            { identifier: "imgly_text_design_multiline" },
          ],
        },
        export: {
          image: {
            enableDownload: false,
            format: ImageFormat.JPEG,
            exportType: ExportFormat.DATA_URL,
          },
        },
      }).then((editor) => {
        console.log("PhotoEditorSDK for Web is ready!", image);

        if (image.photoEditorSDKOperations) {
          editor.serialize({ image: true }).then((state) => {
            editor
              .deserialize({ ...state, operations: image.photoEditorSDKOperations })
              .then(() => {
                console.log("Restored state!");
              })
              .catch((err) => {
                console.log("An error has occured ", err);
              });
          });
        }
        editor.on(UIEvent.EXPORT, async (imageEdit) => {
          // todo: handle exported image here
          editor
            .serialize({ image: true })
            .then((state) => {
              console.log(state);
              const ops = state.operations?.map((op) =>
                op.type === "transform"
                  ? ({
                      type: "transform",
                      options: {
                        end: {
                          x: op.options.end.x,
                          y: op.options.end.y,
                        },
                        meta: op.options.meta,
                        rotation: op.options.rotation,
                        start: {
                          x: op.options.start.x,
                          y: op.options.start.x,
                        },
                      },
                    } as Operation)
                  : op,
              );
              updateImage(image.id, { ...image, photoEditorSDKOperations: ops }, imageEdit).then(() => {
                // save image state into image
                onUpdateEnd();
                onClose();
              });
            })
            .catch((err) => {
              console.log("An error has occured ", err);
            });
        });
        editor.on(UIEvent.CLOSE, async () => {
          // todo: handle exported image here
          onClose();
        });
      });
    }
    initEditor();
  }, []);

  return (
    <div
      id={establishmentId}
      className="editor"
      style={{ width: "100%", height: "100%", position: "relative", borderRadius: "25px" }}
    />
  );
}
