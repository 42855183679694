import React from "react";
import { ThemeCard } from "@kards/types";
import { Box } from "reflexbox";

export const Text = (props: React.ComponentProps<typeof Box>) => (
  <Box as="p" color="black" sx={{ fontSize: "min(6vh, 1.1em)", wordBreak: "break-word" }} {...props} />
);

export const formatDescription = (v) => {
  if (typeof v === "string" && v.length > 120) v = `${v.replace(/^(.{120}[^\s]*).*/, "$1")} ...`;
  return v;
};

type TitleProps = {
  title?: string;
  theme?: ThemeCard;
} & React.ComponentProps<typeof Box>;

export const Title = ({ title, theme, children, ...props }: TitleProps) => (
  <Box
    as="h2"
    sx={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
    fontWeight="bold"
    fontSize="min(6vw, 1.1em)"
    fontFamily="inherit"
    color={theme?.color?.title}
    {...props}
  >
    {title || children}
  </Box>
);

export const SubTitle = ({ title, theme }: TitleProps) => (
  <Box
    as="p"
    sx={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
    fontWeight="400"
    fontSize="min(4.5vw, 1em)"
    fontFamily="inherit"
    p="2px 0px"
    color={theme?.color?.subtitle}
  >
    {title}
  </Box>
);

export const TitleProduct = ({ title, theme }: TitleProps) => (
  <Box
    as="h1"
    fontWeight="bold"
    fontSize="min(6vw, 1em)"
    fontFamily="inherit"
    p="2px 0px"
    color={theme?.color?.productTitle}
  >
    {title}
  </Box>
);

export const SubTitleProduct = ({ title, theme }: TitleProps) => (
  <Box
    as="p"
    // sx={{
    //   whiteSpace: "nowrap",
    //   overflow: "hidden",
    //   textOverflow: "ellipsis",
    // }}
    word-break="break-word"
    fontWeight="400"
    fontSize="min(4.5vw, 1em)"
    fontFamily="inherit"
    color={theme?.color?.productDescription}
  >
    {formatDescription(title)}
  </Box>
);
