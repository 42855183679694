import { useState } from "react";
import { OnBoardingLayout, OnBoardingLayoutProps } from "./OnBoardingLayout";
import { CheckoutForm } from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Flex } from "reflexbox";

const stripePromise = loadStripe(window.kardsConfiguration.stripePk);

export function Payment(props: Omit<OnBoardingLayoutProps, "children" | "title">) {
  const [isValide, setIsValide] = useState(false);

  return (
    <OnBoardingLayout disabledNextBtn={!isValide} {...props} title="Renseignez vos coordonnées bancaires">
      <Flex width="100%" alignItems="center" justifyContent="center">
        <Elements stripe={stripePromise}>
          <CheckoutForm setIsValide={setIsValide} stripeProduct={props?.stripeProduct} />
        </Elements>
      </Flex>
    </OnBoardingLayout>
  );
}
