import React from "react";
import { noop } from "lodash";
import { Flex, Box } from "reflexbox";

const Container = props => (
  <Flex
    justifyContent="center"
    alignItems="center"
    height="5em"
    width="100%"
    {...props}
  />
);

const SocialImg = props => <Box as="img" height="2em" width="2em" {...props} />;

export const Social = ({ instagram, facebook, twitter, onClick = noop }) => {
  return (
    <Container>
      {facebook && (
        <a
          style={{ margin: "1em 2em" }}
          onClick={() => onClick("facebook")}
          key={facebook}
          href={facebook}
          target="blank"
        >
          <SocialImg src="/img/icon/facebook.svg" />
        </a>
      )}
      {twitter && (
        <a
          style={{ margin: "1em 2em" }}
          onClick={() => onClick("twitter")}
          key={twitter}
          href={twitter}
          target="blank"
        >
          <SocialImg src="/img/icon/twitter.svg" />
        </a>
      )}
      {instagram && (
        <a
          style={{ margin: "1em 2em" }}
          onClick={() => onClick("instagram")}
          key={instagram}
          href={instagram}
          target="blank"
        >
          <SocialImg src="/img/icon/instagram.svg" />
        </a>
      )}
    </Container>
  );
};
