export function convertFileToBase64(file) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();

    xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.responseType = "blob";
    xhr.open("GET", file);
    xhr.send();
  });
}
