import { useEffect, useState } from "react";
import { Flex, Box } from "reflexbox";
import { FormFinal, TextField } from "src/common-ui/Form";
import { Button, Card, TitleGreyBold, Text } from "src/common-ui";
import { Link } from "react-router-dom";
import { signIn } from "src/data/signIn";
import { ContainerForm } from "./ContainerForm";
import { useNotify } from "src/layout/Notification";
import { useColorMode } from "theme-ui";

type FormValues = {
  email?: string;
  password?: string;
};

export function Login() {
  const [, setColorMode] = useColorMode();
  useEffect(() => {
    setColorMode("");
  }, []);

  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const submit = async (auth: FormValues) => {
    try {
      setLoading(true);
      await signIn({ email: auth?.email, password: auth?.password });
    } catch (err) {
      if (err?.code === "auth/wrong-password") {
        return notify({
          show: true,
          label: "Email ou mot de passe non valide",
          type: "error",
        });
      }
      if (err?.code === "auth/user-not-found") {
        return notify({
          show: true,
          label: "Email ou mot de passe non valide",
          type: "error",
        });
      }
      if (err?.code === "auth/too-many-requests") {
        return notify({
          show: true,
          label: "Trop de tentative de connexion, veuillez attendre 10 minutes",
          type: "error",
        });
      }

      return notify({
        show: true,
        label: `Un problème est survenue, contactez le support ${err?.code}`,
        type: "error",
      });
      // all other error must be "catch" in app with good redirect according to the situation
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContainerForm loading={loading}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" mb={3} mt={2}>
        <Box p={2}>
          <img src="/img/kards/kards1.svg" alt="logo" width="400px" />
        </Box>
        <TitleGreyBold>Connectez-vous à votre compte</TitleGreyBold>
        <Box pb={2} />
      </Flex>

      <Flex flexDirection="column" alignItems="center" justifyContent="center" width="100%" sx={{ zIndex: "1" }}>
        <Card p="20px" width="100%" maxWidth="700px">
          <FormFinal initialValues={{}} onSubmit={submit}>
            <TextField name="email" label="Email" data-cy="usermail" disabled={loading} type="email" width="100%" />
            <Box p={1} />
            <TextField
              name="password"
              label="Mot de passe"
              data-cy="password"
              type="password"
              disabled={loading}
              width="100%"
            />
            <Box p={1} />

            <Flex width="100%" mb={1} justifyContent="space-between" alignItems="center">
              <Link to={`/create-account`}>
                <Text sx={{ cursor: "pointer", textDecoration: "underline" }} fontSize="0.8em">
                  Créer un compte
                </Text>
              </Link>
              <Link to={`/forgot-password`}>
                <Text sx={{ cursor: "pointer", textDecoration: "underline" }} fontSize="0.8em">
                  mot de passe oublié ?
                </Text>
              </Link>
            </Flex>

            <Flex pb={2} flexDirection="column" alignItems="center" justifyContent="center" width="100%">
              <Button type="submit" data-cy="submit_login" disabled={loading}>
                Se connecter
              </Button>
            </Flex>
          </FormFinal>
        </Card>
      </Flex>
    </ContainerForm>
  );
}
