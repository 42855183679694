import { Box } from "reflexbox";

export function ErrorField({ meta, ...props }) {
  if ((meta.error || meta.submitError) && meta.touched)
    return (
      <Box
        as="p"
        fontSize="0.8em"
        my={"2px"}
        fontStyle="italic"
        color="red.main"
        sx={{ position: "absolute" }}
        {...props}
      >
        {meta.error}
      </Box>
    );

  return null;
}
