import React from "react";
import { ProductLinked } from "@kards/types";
import { Box } from "reflexbox";
import { Price } from "./Price";
import { Label } from "./Label";
import { SubTitleProduct, TitleProduct } from "./Text";
import { SmartMedia } from "./Media/SmartMedia";
import { MediaComponent } from "./Media/Media";
import { SimpleProduct } from "../SimpleList/SimpleProduct";

export function CardProduct({
  product,
  children,
  isShadow = null,
  theme = null,
  hostImage,
  bounced,
}: {
  product: ProductLinked;
  onEditProduct?: any;
  margin?: any;
  isShadow?: any;
  theme?: any;
  children?: any;
  hostImage: string;
  bounced?: boolean;
}) {
  if (!product) return null;
  const { reference, description, labels, price, picture } = product;

  if (!picture)
    return (
      <Box>
        <SimpleProduct product={product} theme={theme} bounced={bounced} children={children} />
      </Box>
    );

  return (
    <Box
      sx={{
        position: "relative",
        boxShadow: `${isShadow ? "0px 0px 20px 0px rgba(100,100,100,.15)" : "none"}`,
        borderRadius: "10px",
      }}
      className={bounced ? "littleBounced" : ""}
    >
      <Box style={{ position: "relative" }}>
        <MediaComponent
          hostImage={hostImage}
          value={picture}
          style={{
            width: "100%",
            height: "auto",
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          }}
        />
        {Boolean(price) && <Price price={price} theme={theme} />}
      </Box>
      <Box flexDirection="column" display="flex" marginTop={2} paddingLeft={1}>
        <Box display="flex" flexDirection="column">
          <Box width="95%">
            <TitleProduct theme={theme} title={reference} />
          </Box>
          {description && (
            <Box width="95%">
              <SubTitleProduct theme={theme} title={description} />
            </Box>
          )}
          <Label labels={labels} />
        </Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
}

export function CardProductSmall({
  product,
  children,
  margin = null,
  isShadow = null,
  theme = null,
  hostImage,
  bounced,
}: {
  product: ProductLinked;
  onEditProduct?: any;
  margin?: any;
  isShadow?: any;
  theme?: any;
  children?: any;
  hostImage: string;
  bounced?: boolean;
}) {
  if (!product) return null;
  const { reference, description, labels, price, picture } = product;

  return (
    <Box
      sx={{
        position: "relative",
        boxShadow: `${isShadow ? "0px 0px 20px 0px rgba(100,100,100,.15)" : "none"}`,
        borderRadius: "10px",
      }}
      margin={margin ? margin : 2}
      pb={"12px"}
      className={bounced ? "littleBounced" : ""}
    >
      <Box style={{ position: "relative" }}>
        <SmartMedia
          hostImage={hostImage}
          image={picture}
          alt={reference}
          style={{ width: "100%", height: "auto" }}
          size="big"
        />
        <Price price={price} theme={theme} />
      </Box>
      <Box flexDirection="column" display="flex" marginTop={2} paddingLeft={1}>
        <Box display="flex" flexDirection="column">
          <Box width="95%">
            <TitleProduct theme={theme} title={reference} />
          </Box>
          {description && (
            <Box width="95%">
              <SubTitleProduct theme={theme} title={description} />
            </Box>
          )}
          <Label labels={labels} />
        </Box>
        {children}
      </Box>
    </Box>
  );
}
