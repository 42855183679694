import React from "react";
import { GoogleMap, Polyline, PolylineProps } from "@react-google-maps/api";
import { Flex } from "reflexbox";
import { Title } from "../commons";
import { ThemeCard, ValueGpx } from "@kards/types";
import { GoCloudDownload } from "react-icons/go";

const defaultOptions = {
  strokeColor: "#f04141",
  strokeOpacity: 1,
  strokeWeight: 4,
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};

export type GPxMapProps = {
  theme?: ThemeCard;
  value: ValueGpx;
  options?: PolylineProps["options"];
  onZoomChanged?: (zoom: number) => void;
};

export function GpxMap({ theme, value, options = {}, onZoomChanged = () => {} }: GPxMapProps) {
  const { title, positions, zoom = 10, color, downloadURL } = value;
  const center = positions?.[0];

  function handleZoomChanged() {
    onZoomChanged(this.getZoom()); //this refers to Google Map instance
  }

  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" alignItems="center">
        <Title theme={theme} title={title} fontSize="min(5.5vw,1.4em)" width="100%" />
        {Boolean(downloadURL) && (
          <a href={downloadURL} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <GoCloudDownload />
          </a>
        )}
      </Flex>

      <GoogleMap
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: true,
        }}
        mapContainerStyle={{ width: "100%", height: "100%", minHeight: "300px" }}
        center={center}
        zoom={zoom}
        onZoomChanged={handleZoomChanged}
      >
        <Polyline
          path={positions}
          options={{ ...defaultOptions, ...options, strokeColor: color || defaultOptions.strokeColor }}
        />
      </GoogleMap>
    </Flex>
  );
}
