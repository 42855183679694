import React, { useState } from "react";
import { ThemeCard } from "@kards/types";
import { Title } from "../commons/Text";
import { Flex, Box } from "reflexbox";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Collapse } from "react-collapse";

export type SimpleListProps = {
  title?: string;
  theme?: ThemeCard;
  children: React.ReactNode;
  defaultOpen?: boolean;
};

export function SimpleList({ title, theme, children, defaultOpen }: SimpleListProps) {
  const [open, setOpen] = useState(Boolean(defaultOpen));
  return (
    <Box>
      <Flex alignItems="center">
        {title && <Title theme={theme} title={title} fontSize="min(5.5vw,1.4em)" />}
        {title && (
          <Flex ml={1} alignItems="center" justifyContent="center">
            <MdKeyboardArrowDown
              fontSize="min(7.5vw,2em)"
              color={theme?.color?.title}
              style={{ transform: `rotate(${open ? "0" : "180"}deg)`, transition: "0.3s" }}
              onClick={() => setOpen((o) => !o)}
            />
          </Flex>
        )}
      </Flex>
      <Collapse isOpened={!title ? true : open}>
        <Box>{children}</Box>
      </Collapse>
    </Box>
  );
}
