import React from "react";
import { ProductLinked, ThemeCard } from "@kards/types";
import { numberToEuro } from "../commons/Price";
import { SmartImage } from "../commons/Media/SmartImage";
import { Label } from "../commons/Label";
import { Box, Flex } from "reflexbox";
import { Title, TitleProduct } from "../commons";

export type SimpleProductProps = {
  product: ProductLinked;
  theme?: ThemeCard;
  children?: React.ReactNode;
  setEditProduct?: any;
  height?: string;
  hostImage: string;
  bounced?: boolean;
};

export type SimpleListProps = {
  title: string;
  theme?: ThemeCard;
  children: React.ReactNode;
};

export function SimpleListGallery({ title, theme, children }: SimpleListProps) {
  return (
    <Box>
      <Box>
        <Title theme={theme} title={title} sx={{}} style={{ fontSize: "min(5.5vw,1.4em)" }} />
      </Box>
      <Flex justifyContent="space-around" alignItems="flex-start" flexWrap="wrap">
        {children}
      </Flex>
    </Box>
  );
}

export function GalleryProduct({ product, theme, children, hostImage, bounced }: SimpleProductProps) {
  const { reference, price, picture, labels } = product;
  return (
    <Box
      display="flex"
      flexDirection="column"
      color={theme?.color?.background}
      marginTop=".6em"
      className={bounced ? "littleBounced" : ""}
    >
      <Box paddingY="5px" display="flex" flexDirection="column">
        <SmartImage
          size="medium"
          hostImage={hostImage}
          image={picture}
          alt={reference}
          style={{ width: "100%", height: "auto" }}
        />
      </Box>
      <Box display="flex" paddingY="5px" flexDirection="column" justifyContent="center" alignItems="flex-start">
        <Box width="100%">
          <TitleProduct theme={theme} title={reference} />
        </Box>
        <Box width="100%" display="flex" alignItems="center">
          <Label labels={labels} />
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box flex="0.2">
            <Box
              as="p"
              fontWeight="400"
              fontSize="min(4.5vw, 1em)"
              fontFamily="inherit"
              p="2px 0px"
              color={theme?.color?.productPrice}
            >
              {numberToEuro(price)}
            </Box>
          </Box>
        </Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
}
