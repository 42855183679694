import { firebase } from "src/firebase";

export function toArray<T>(querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) {
  const result = [];
  querySnapshot.forEach((d) => {
    result.push({ ...d.data(), id: d.id });
  });

  return result as T[];
}
