import { Route, Switch } from "react-router-dom";
// import { CompletePassword } from "src/auth/CompletePassword";
import { ChoiceAccountType } from "src/auth/CreateAccount";
import { ForgotPassword } from "src/auth/ForgotPassword";
import { Login } from "src/auth/Login";
import { OnboardingWelcome } from "src/onboarding/OnboardingWelcome";
import { OnBoarding } from "src/onboarding";

export function LoginRoutes() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/create-account" component={ChoiceAccountType} />
      <Route path="/forgot-password" component={ForgotPassword} />
      {/* <Route path="/complete-password" component={CompletePassword} /> */}
      <Route exact path={"/establishments/:establishmentId/welcome"} component={OnboardingWelcome} />
      <Route path="/onboarding/:establishmentId" exact component={OnBoarding} />
    </Switch>
  );
}
