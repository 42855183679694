import React from "react";
import { Event } from "@kards/types";
import { Box } from "reflexbox";
import { uniq } from "lodash";

type FilterLocationsProps = {
  eventsFiltered: Event[];
  locationSelected: string;
  onSelected: (l: string) => void;
  backgroundLocation?: string;
};

export function FilterLocations({
  backgroundLocation,
  eventsFiltered,
  locationSelected,
  onSelected,
}: FilterLocationsProps) {
  console.log(uniq(eventsFiltered?.flatMap((c) => c.locations)));
  return (
    <Box width="100%" backgroundColor={"transparent"} alignItems="center" style={{ overflowX: "auto", zIndex: 1 }}>
      <Box
        alignItems="center"
        marginLeft={1}
        minWidth="auto"
        display="flex"
        width="100%"
        height="100%"
        overflowX="scroll"
      >
        <Box p="2px" />
        {uniq(eventsFiltered?.flatMap((c) => c.locations))
          ?.filter(Boolean)
          ?.map((p) => (
            <Box
              as="button"
              key={p}
              display="flex"
              padding="5px"
              margin="5px"
              minWidth="auto"
              justifyContent="center"
              alignItems="center"
              className="normalBounced"
              style={{
                whiteSpace: "nowrap",
                cursor: "pointer",
                fontSize: "0.8em",
                borderRadius: "25px",
                border: `1px solid  ${
                  locationSelected === p ? (backgroundLocation ? backgroundLocation : "#1e2023") : "#1e2023"
                }`,
                backgroundColor:
                  locationSelected === p ? (backgroundLocation ? backgroundLocation : "#1e2023") : "#fff",
                color: locationSelected === p ? "#fff" : "#1e2023",
              }}
              onClick={() => onSelected(p === locationSelected ? null : p)}
            >
              {p}
            </Box>
          ))}
        <Box p="2px" />
      </Box>
    </Box>
  );
}
