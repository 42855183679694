import { ProductLinked } from "@kards/types";
import React from "react";
import { Box } from "reflexbox";

export function Label({ labels }: { labels: ProductLinked["labels"] }) {
  return (
    <Box display="flex" flexWrap="wrap">
      {labels &&
        labels?.map((l) => (
          <img
            key={l}
            style={{ height: "24px", filter: "inherit", margin: "5px" }}
            width="20px"
            alt={l}
            src={`/img/productlabels/${l}.svg`}
          />
        ))}
    </Box>
  );
}

export function StoreLabel({ labels }: { labels: ProductLinked["labels"] }) {
  return (
    <Box display="flex" flexWrap="wrap">
      {labels &&
        labels?.map((l) => (
          <img
            key={l}
            style={{ height: "24px", filter: "inherit", margin: "5px" }}
            width="20px"
            alt={l}
            src={`/img/storelabels/${l}.png`}
          />
        ))}
    </Box>
  );
}
