import React from "react";
import styled from "styled-components";
import { Title } from "../commons";
import { ProductLinked, ThemeCard } from "@kards/types";
import useSWR from "swr";
import { fetcher } from "../commons/fetcher";
import { Flex, Box } from "reflexbox";

// const weathersCode = {
//   goodWeather: ["Clear"],
//   badWeather: ["Clouds", "Tornado", "Squall", "Snow", "Rain", "Drizzle", "Thunderstorm"],
// };

export type SuggestionsOnWeatherProps = {
  location: string;
  setEditProduct?: any;
  goodWeather: ProductLinked[];
  badWeather: ProductLinked[];
  height?: string;
  goodWeatherTitle?: string;
  badWeatherTitle?: string;
  width?: string;
  hostApi: string;
  children?: (d: {
    product: ProductLinked;
    products: ProductLinked[];
    weather: string;
    index: number;
    theme: ThemeCard;
  }) => React.ReactNode;
  theme?: ThemeCard;
};

export function SuggestionsOnWeather({
  location,
  hostApi,
  goodWeather,
  badWeather,
  theme,
  children,
}: SuggestionsOnWeatherProps) {
  const { data } = useSWR(`${hostApi}/weather/${location}`, fetcher);
  const isGoodWeather = data?.main?.temp_max > 18;

  const weather = isGoodWeather ? "good" : "bad";
  const productDependWeather = isGoodWeather ? goodWeather : badWeather;
  const titleDependWeather = isGoodWeather ? "Rafraîchissez-vous" : "Réchauffez vous";

  return (
    <Box>
      <Box>
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Title style={{ padding: "2px" }} theme={theme} title={titleDependWeather} />
          <Flex justifyContent="center" alignItems="center">
            {data?.weather?.[0]?.icon ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                <img src={`/assets/weather/${data?.weather?.[0]?.icon}.svg`} width="30px" alt="weather" />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                <img src={`/assets/weather/01d.svg`} width="40px" alt="weather" />
              </div>
            )}
            {data?.main && <Title theme={theme} title={`${Math.round(data?.main?.temp)} C °`} />}
          </Flex>
        </Flex>
      </Box>
      <Horizontal>
        {productDependWeather?.map((product, index) => (
          <Box key={product?.id} minWidth="350px" p="5px">
            {children({
              product,
              products: productDependWeather,
              weather,
              index,
              theme,
            })}
          </Box>
        ))}
      </Horizontal>
    </Box>
  );
}

const Horizontal = styled.div<any>`
  background-color: ${(props) => props.backgroundColor};
  box-shadow: ${(props) => props.shadow};
  padding-left: 6px;
  flex-wrap: nowrap;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  ::-webkit-scrollbar {
    display: none;
  }
`;
