import React from "react";
import { numberToEuro } from "../commons/Price";
import { formatDescription, SubTitleProduct, TitleProduct } from "../commons/Text";
import { Box } from "reflexbox";
import { Label } from "../commons/Label";
import { ProductLinked, ThemeCard } from "@kards/types";

export type SimpleProductProps = {
  product: ProductLinked;
  theme?: ThemeCard;
  children?: React.ReactNode;
  onEditProduct?: any;
  bounced?: boolean;
};

export function SimpleProduct({ product, theme, children, bounced }: SimpleProductProps) {
  const { reference, price, description, labels } = product;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      color={theme?.color?.background}
      marginTop=".6em"
      className={bounced ? "littleBounced" : ""}
    >
      <Box
        display="flex"
        paddingY="5px"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        flex="1"
      >
        <Box width="100%">
          <TitleProduct theme={theme} title={reference} />
        </Box>
        <Box width="100%">
          <SubTitleProduct theme={theme} title={formatDescription(description)} />
        </Box>
        <Box width="100%" display="flex" alignItems="center">
          {Boolean(price) && (
            <Box flex="0.2">
              <Box
                as="p"
                fontWeight="400"
                fontSize="min(4.5vw, 1em)"
                fontFamily="inherit"
                p="2px 0px"
                color={theme?.color?.productPrice}
              >
                {numberToEuro(price)}
              </Box>
            </Box>
          )}
          {children}
        </Box>
        <Label labels={labels} />
      </Box>
    </Box>
  );
}
