import React from "react";
import { Offer, SettingField, ThemeCard } from "@kards/types";
import { formatDescription, SubTitle, SubTitleProduct, Title, TitleProduct } from "../commons/Text";
import { Box } from "reflexbox";
import { numberToEuro } from "../commons/Price";

export type OfferWithoutImageProps = {
  title: string;
  offer: Offer;
  theme?: ThemeCard;
  children: (settingField: SettingField, index: number) => React.ReactNode;
};

export function OfferWithoutImage({ title, offer, theme, children }: OfferWithoutImageProps) {
  return (
    <Box margin={2}>
      <Box display="flex" alignItems="center">
        <Title theme={theme} title={title} sx={{}} style={{ fontSize: "min(5.5vw,1.4em)" }} />

        <Box marginLeft="5px" color={theme?.color?.title}>
          -
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          {Boolean(offer?.price) && (
            <Box
              as="p"
              fontWeight="400"
              fontSize="min(4.5vw, 1em)"
              fontFamily="inherit"
              p="2px 0px"
              marginLeft="5px"
              color={theme?.color?.productPrice}
            >
              {numberToEuro(offer?.price)}
            </Box>
          )}
        </Box>
      </Box>
      {offer?.description && (
        <SubTitle theme={theme} title={offer?.description} sx={{}} style={{ fontSize: "min(5.5vw,1.4em)" }} />
      )}

      {offer?.settingFields?.map((settingField, i) => children(settingField, i))}
    </Box>
  );
}

export type SimpleProductProps = {
  settingField: SettingField;
  theme?: ThemeCard;
  children?: React.ReactNode;
  onEditProduct?: any;
  bounced?: boolean;
};

export function OfferWithoutImageProduct({ settingField, theme, bounced }: SimpleProductProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      color={theme?.color?.background}
      className={bounced ? "littleBounced" : ""}
    >
      <Box display="flex" paddingY="5px" flexDirection="column" justifyContent="center" alignItems="flex-start">
        {settingField?.label && (
          <Box width="100%">
            <TitleProduct theme={theme} title={settingField?.label} />
          </Box>
        )}
        {settingField?.productsLinked?.map((p) => (
          <>
            <Box width="100%">
              <TitleProduct fontSize="min(4.5vw, 1em)" theme={theme} title={formatDescription(p?.reference)} />
              <SubTitleProduct fontSize="min(4.5vw, 0.9em)" theme={theme} title={formatDescription(p?.description)} />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center"></Box>
          </>
        ))}
      </Box>
    </Box>
  );
}
