export default {
  common: {
    error: "Erreur",
    warning: "Warning",
    color: {
      colorPicker: {
        hex: "Hex",
        r: "R",
        g: "G",
        b: "B",
        sliderHue: "Couleur",
        sliderOpacity: "Opacité de couleur",
      },
    },
  },
  mainCanvasActions: {
    buttonExport: "Valider",
    buttonUndo: "Annuler action",
    buttonRedo: "Refaire action",
    buttonClose: "Fermer",
  },
  infoModals: {
    exporting: {
      heading: "Enregistrement...",
      body: "Patientez quelques secondes...",
    },
    saving: {
      heading: "Sauvegarde...",
      body: "Patientez quelques secondes...",
    },
    loading: {
      heading: "Chargement...",
      body: "Patientez quelques secondes...",
    },
    resizing: {
      heading: "Recadrage...",
      body: "Patientez quelques secondes...",
    },
    loadingFonts: {
      heading: "Chargement de police...",
      body: "Patientez quelques secondes...",
    },
    stickerLoading: {
      heading: "Chargement de Sticker...",
      body: "Patientez quelques secondes...",
    },
  },
  errorModals: {
    imageLoading: {
      headingDelimiter: ":",
      body:
        "Échec du chargement de l'image. Cela peut avoir plusieurs raisons, par example le fichier est corrompu ou le type de fichier n'est pas pris en charge",
      buttonYes: "Recharger",
    },
    rendering: {
      headingDelimiter: ":",
      body: "Une erreur s'est produite lors du rendu de l'image",
      buttonYes: "Recharger",
    },
    fontLoading: {
      headingDelimiter: ":",
      heading: "Echec du chargement de la police",
      body: "Les polices suivantes n'ont pas pu être chargées : ${error}",
      buttonNo: "Fermer",
    },
    webcamUnavailable: {
      headingDelimiter: ":",
      body: "Impossible d'afficher l'image de la webcam (Erreur : ${error})",
      buttonYes: "Fermer",
    },
    stickerLoading: {
      headingDelimiter: ":",
      body: "Impossible de charger le sticker",
      buttonNo: "Fermer",
    },
    unexpectedError: {
      headingDelimiter: ":",
      body: "Une erreur inattendue s'est produite ${error}",
      buttonYes: "Recharger",
    },
  },
  warningModals: {
    imageResized: {
      headingDelimiter: ":",
      heading: "Image redimensionnée",
      body:
        "Votre image dépasse la taille maximale de ${megapixels} mégapixels et a été redimensionnée à ${width}x${height} pixels",
      buttonYes: "Continuer",
    },
    unsupportedSerializationVersion: {
      headingDelimiter: ":",
      body:
        "Vos données ont été restaurées à partir d'un ancien format de données et peuvent avoir un aspect différent",
      buttonYes: "Appliquer les changements",
      buttonNo: "Annuler",
    },
    discardChanges: {
      headingDelimiter: ":",
      body: "Vous avez des changements non enregistrés. Voulez-vous vraiment annuler les modifications ?",
      buttonYes: "Annuler les modifications",
      buttonNo: "Conserver les changements",
    },
    unsavedChanges: {
      headingDelimiter: ":",
      body: "Vous avez des changements non enregistrés. Êtes-vous sûr de vouloir quitter?",
      buttonYes: "Quitter sans sauvegarder",
      buttonNo: "Annuler",
    },
    unsupportedWebGLRenderer: {
      body:
        "Un problème a été détecté dans le navigateur qui pourrait entraîner de longs temps de chargement. Veuillez essayer un autre navigateur.",
      buttonYes: "Continuer",
    },
  },
  library: {
    title: "Bibliothèque",
    controls: {
      buttonUpload: "Télécharger une image",
      buttonWebcamOpen: "Ouvrir la Webcam",
      buttonWebcamClose: "Fermer la Webcam",
      placeholderSearch: "Rechercher",
      noResults: "Pas de résultat",
    },
  },
  filter: {
    title: "Filtres",
    controls: {
      buttonReset: "Retirer le filtre",
      sliderIntensity: "Intensité du filtre",
    },
    categories: {
      imgly_filter_category_duotone: "DuoTone",
      imgly_filter_category_bw: "B & W",
      imgly_filter_category_vintage: "Vintage",
      imgly_filter_category_smooth: "Smooth",
      imgly_filter_category_cold: "Cold",
      imgly_filter_category_warm: "Warm",
      imgly_filter_category_legacy: "Legacy",
    },
    items: {
      imgly_lut_celsius: "Inferno",
      imgly_lut_chest: "Chestnut",
      imgly_lut_fixie: "Fixie",
      imgly_lut_fridge: "Fridge",
      imgly_lut_front: "Sunny 70s",
      imgly_lut_k2: "Flat Black",
      imgly_lut_mellow: "Mellow",
      imgly_lut_sin: "Hard Stuff",
      imgly_lut_texas: "Oldtimer",
      imgly_lut_ad1920: "1920 A.D.",
      imgly_lut_ancient: "Ancient",
      imgly_lut_bleached: "Kalmen",
      imgly_lut_bleachedblue: "Joran",
      imgly_lut_blues: "Polaroid",
      imgly_lut_blueshadows: "Zephyr",
      imgly_lut_breeze: "Levante",
      imgly_lut_bw: "Greyed",
      imgly_lut_classic: "Classic",
      imgly_lut_colorful: "Colorful",
      imgly_lut_cool: "Snappy",
      imgly_lut_cottoncandy: "Candy",
      imgly_lut_creamy: "Creamy",
      imgly_lut_eighties: "Low Fire",
      imgly_lut_elder: "Colla",
      imgly_lut_evening: "Sunrise",
      imgly_lut_fall: "Moss",
      imgly_lut_food: "Food",
      imgly_lut_glam: "Glam",
      imgly_lut_gobblin: "Gobblin",
      imgly_lut_highcarb: "High Carb",
      imgly_lut_highcontrast: "Hicon",
      imgly_lut_k1: "K1",
      imgly_lut_k6: "K6",
      imgly_lut_kdynamic: "Pebble",
      imgly_lut_keen: "Keen",
      imgly_lut_lenin: "Lemon",
      imgly_lut_litho: "Litho",
      imgly_lut_lomo: "Lomo",
      imgly_lut_lomo100: "Lomo 100",
      imgly_lut_lucid: "Lucid",
      imgly_lut_neat: "Neat",
      imgly_lut_nogreen: "Pumpkin",
      imgly_lut_orchid: "Solanus",
      imgly_lut_pale: "Pale",
      imgly_lut_pitched: "Pitched",
      imgly_lut_plate: "Weathered",
      imgly_lut_pola669: "Green Gap",
      imgly_lut_polasx: "Pola SX",
      imgly_lut_pro400: "Pro 400",
      imgly_lut_quozi: "Quozi",
      imgly_lut_sepiahigh: "Sepia",
      imgly_lut_settled: "Settled",
      imgly_lut_seventies: "Seventies",
      imgly_lut_soft: "Soft",
      imgly_lut_steel: "Steel",
      imgly_lut_summer: "Summer",
      imgly_lut_sunset: "Golden",
      imgly_lut_tender: "Tender",
      imgly_lut_twilight: "Twilight",
      imgly_lut_winter: "Softy",
      imgly_lut_x400: "Dusty",
      imgly_duotone_desert: "Desert",
      imgly_duotone_peach: "Peach",
      imgly_duotone_clash: "Clash",
      imgly_duotone_plum: "Plum",
      imgly_duotone_breezy: "Breezy",
      imgly_duotone_deepblue: "Deep Blue",
      imgly_duotone_frog: "Frog",
      imgly_duotone_sunset: "Sunset",
    },
  },
  adjustment: {
    title: "Adjustement",
    controls: {
      buttonReset: "Réinitialiser le réglage",
    },
    categories: {
      basics: "Basic",
      refinements: "Refinements",
    },
    items: {
      brightness: "Brightness",
      saturation: "Saturation",
      contrast: "Contrast",
      gamma: "Gamma",
      sharpness: "Sharpness",
      clarity: "Clarity",
      exposure: "Exposure",
      shadows: "Shadows",
      highlights: "Highlights",
      whites: "Whites",
      blacks: "Blacks",
      temperature: "Temperature",
    },
  },
  focus: {
    title: "Focus",
    controls: {
      buttonReset: "Retirer le focus",
      sliderIntensity: "Intensité du focus",
    },
    items: {
      radial: "Radial",
      mirrored: "Mirrored",
      linear: "Linear",
      gaussian: "Gaussian",
    },
    history: {
      focusPosition: "Position du focus",
      focusSize: "Taille du focus",
    },
  },
  overlay: {
    title: "Overlays",
    controls: {
      buttonReset: "Retirer l'overlay",
      sliderOpacity: "Opacité de l'Overlay",
      carouselBlendMode: "Mode",
      blendModeNormal: "Normal",
      blendModeOverlay: "Overlay",
      blendModeHardLight: "Hard Light",
      blendModeSoftLight: "Soft Light",
      blendModeMultiply: "Multiply",
      blendModeDarken: "Darken",
      blendModeLighten: "Lighten",
      blendModeScreen: "Screen",
      blendModeColorBurn: "Couleur Burn",
      tabOpacity: "Opacity",
      tabBlendMode: "Blend Mode",
    },
    items: {
      imgly_overlay_bokeh: "Bokeh",
      imgly_overlay_chop: "Chop",
      imgly_overlay_clouds: "Clouds",
      imgly_overlay_golden: "Golden",
      imgly_overlay_grain: "Grain",
      imgly_overlay_hearts: "Hearts",
      imgly_overlay_lightleak1: "Light Leak 1",
      imgly_overlay_lightleak2: "Light Leak 2",
      imgly_overlay_metal: "Metal",
      imgly_overlay_mosaic: "Mosaic",
      imgly_overlay_painting: "Painting",
      imgly_overlay_paper: "Paper",
      imgly_overlay_rain: "Rain",
      imgly_overlay_vintage: "Vintage",
      imgly_overlay_wall1: "Wall",
      imgly_overlay_wall2: "Wall 2",
      imgly_overlay_wood: "Wood",
    },
  },
  sticker: {
    title: "Stickers",
    controls: {
      buttonUpload: "Charger un sticker",
      sliderOpacity: "Opacité du sticker",
      selectColor: "Coleur du sticker",
      tabColor: "Couleur",
      tabOpacity: "Opacité",
    },
    categories: {
      imgly_sticker_emoticons: "Emoticons",
      imgly_sticker_shapes: "Shapes",
      imgly_sticker_custom: "Custom",
    },
    items: {
      imgly_sticker_emoticons_alien: "Alien",
      imgly_sticker_emoticons_angel: "Angel",
      imgly_sticker_emoticons_angry: "Angry",
      imgly_sticker_emoticons_anxious: "Anxious",
      imgly_sticker_emoticons_asleep: "Asleep",
      imgly_sticker_emoticons_attention: "Attention",
      imgly_sticker_emoticons_baby_chicken: "Baby Chicken",
      imgly_sticker_emoticons_batman: "Batman",
      imgly_sticker_emoticons_beer: "Beer",
      imgly_sticker_emoticons_black: "Black",
      imgly_sticker_emoticons_blue: "Blue",
      imgly_sticker_emoticons_blush: "Blush",
      imgly_sticker_emoticons_boxer: "Boxer",
      imgly_sticker_emoticons_business: "Business",
      imgly_sticker_emoticons_chicken: "Chicken",
      imgly_sticker_emoticons_cool: "Cool",
      imgly_sticker_emoticons_cry: "Cry",
      imgly_sticker_emoticons_deceased: "Deceased",
      imgly_sticker_emoticons_devil: "Devil",
      imgly_sticker_emoticons_duckface: "Duckface",
      imgly_sticker_emoticons_furious: "Furious",
      imgly_sticker_emoticons_grin: "Grin",
      imgly_sticker_emoticons_guitar: "Guitar",
      imgly_sticker_emoticons_harry_potter: "Harry Potter",
      imgly_sticker_emoticons_hippie: "Hippie",
      imgly_sticker_emoticons_hitman: "Hitman",
      imgly_sticker_emoticons_humourous: "Humourous",
      imgly_sticker_emoticons_idea: "Idea",
      imgly_sticker_emoticons_impatient: "Impatient",
      imgly_sticker_emoticons_kiss: "Kiss",
      imgly_sticker_emoticons_kisses: "Kisses",
      imgly_sticker_emoticons_laugh: "Laugh",
      imgly_sticker_emoticons_loud_cry: "Loud Cry",
      imgly_sticker_emoticons_loving: "Loving",
      imgly_sticker_emoticons_masked: "Masked",
      imgly_sticker_emoticons_music: "Music",
      imgly_sticker_emoticons_nerd: "Nerd",
      imgly_sticker_emoticons_ninja: "Ninja",
      imgly_sticker_emoticons_not_speaking_to_you: "Not speaking to you",
      imgly_sticker_emoticons_pig: "Pig",
      imgly_sticker_emoticons_pumpkin: "Pumpkin",
      imgly_sticker_emoticons_question: "Question",
      imgly_sticker_emoticons_rabbit: "Rabbit",
      imgly_sticker_emoticons_sad: "Sad",
      imgly_sticker_emoticons_sick: "Sick",
      imgly_sticker_emoticons_skateboard: "Skateboard",
      imgly_sticker_emoticons_skull: "Skull",
      imgly_sticker_emoticons_sleepy: "Sleepy",
      imgly_sticker_emoticons_smile: "Smile",
      imgly_sticker_emoticons_smoking: "Smoking",
      imgly_sticker_emoticons_sobbing: "Sobbing",
      imgly_sticker_emoticons_star: "Star",
      imgly_sticker_emoticons_steaming_furious: "Steaming Furious",
      imgly_sticker_emoticons_sunbathing: "Sunbathing",
      imgly_sticker_emoticons_tired: "Tired",
      imgly_sticker_emoticons_tongue_out_wink: "Tongue out wink",
      imgly_sticker_emoticons_wave: "Wave",
      imgly_sticker_emoticons_wide_grin: "Wide Grin",
      imgly_sticker_emoticons_wink: "Wink",
      imgly_sticker_emoticons_wrestler: "Wrestler",
      imgly_sticker_shapes_arrow_02: "Arrow 1",
      imgly_sticker_shapes_arrow_03: "Arrow 2",
      imgly_sticker_shapes_badge_01: "Badge 1",
      imgly_sticker_shapes_badge_11: "Badge 5",
      imgly_sticker_shapes_badge_12: "Badge 6",
      imgly_sticker_shapes_badge_13: "Badge 7",
      imgly_sticker_shapes_badge_15: "Badge 8",
      imgly_sticker_shapes_badge_18: "Badge 9",
      imgly_sticker_shapes_badge_19: "Badge 10",
      imgly_sticker_shapes_badge_20: "Badge 11",
      imgly_sticker_shapes_badge_28: "Badge 12",
      imgly_sticker_shapes_badge_32: "Badge 13",
      imgly_sticker_shapes_badge_35: "Badge 14",
      imgly_sticker_shapes_badge_36: "Badge 15",
      imgly_sticker_shapes_badge_04: "Badge 2",
      imgly_sticker_shapes_badge_06: "Badge 3",
      imgly_sticker_shapes_badge_08: "Badge 4",
      imgly_sticker_shapes_spray_01: "Spray 1",
      imgly_sticker_shapes_spray_03: "Spray 2",
      imgly_sticker_shapes_spray_04: "Spray 3",
    },
    canvasActions: {
      buttonDelete: "Supprimer",
      buttonBringToFront: "Mettre au premier plan",
      buttonDuplicate: "Dupliquer",
      buttonFlipHorizontal: "Tourner",
      buttonFlipVertical: "Tourner",
    },
    history: {
      add: "Sticker",
      resize: "Redimension de sticker",
      position: "Position du sticker",
      color: "Couleur du sticker",
      delete: "Supprimer le Sticker delete",
      order: "Ordre du sticker",
      opacity: "opacité du sticker",
      flip: "Rotation du sticker",
    },
  },
  text: {
    title: "Text",
    controls: {
      buttonNew: "Nouveau texte",
      dropdownFontFamily: "Police",
      textFontSize: "Taille de police",
      selectAlignment: "Alignement",
      selectFontColor: "Couleur de police",
      selectBackgroundColor: "Couleur du fond",
      sliderLineSpacing: "Espacement des lignes",
      tabColor: "Couleur",
      tabBgColor: "Couleur de fond",
      tabAlignment: "Alignement",
      tabLineHeight: "Hauteur de ligne",
      tabFontSize: "Taille de police",
    },
    canvasControls: {
      placeholderText: "Ecrire quelque chose",
      buttonSave: "Valider",
      buttonClose: "Annuler",
      inputText: "Texte",
    },
    canvasActions: {
      buttonEdit: "Editer",
      buttonDelete: "Supprimer",
      buttonBringToFront: "Mettre au premier plan",
      buttonDuplicate: "Dupliquer",
    },
    history: {
      add: "Texte",
      edit: " Edition du texte",
      resize: " Taille du texte",
      position: " Position du texte",
      alignment: " Alignement du texte",
      textColor: " Couleur du texte",
      backgroundColor: " Couleur de fond du texte",
      fontFamily: "Police",
      fontStyle: "Style du texte",
      lineSpacing: "Espacement des lignes",
      width: "Largeur du texte",
      delete: "Suppression du texte",
      order: "Ordre du texte",
    },
  },
  textdesign: {
    title: "Texte designé",
    controls: {
      buttonNew: "Nouveau texte",
      buttonShuffle: "Design aléatoire",
      selectColor: "Couleur du texte",
      tabColor: "Couleur",
      tabShuffle: "Aléatoire",
    },
    canvasControls: {
      placeholderText: "Écrivez quelque chose avec style",
      buttonSave: "Valider",
      buttonClose: "Fermer",
      inputText: "Texte",
    },
    canvasActions: {
      buttonEdit: "Editer",
      buttonInvert: "Inverser",
      buttonDelete: "Supprimer",
      buttonBringToFront: "Mettre au premier plan",
      buttonDuplicate: "Dupliquer",
    },
    history: {
      add: "Texte designé",
      edit: "Edition du texte designé",
      resize: "Taille du texte designé",
      position: "Position du texte designé",
      color: "Couleur du texte designé",
      shuffle: "Texte designé aléatoire",
      invert: "Texte designé inversé",
      padding: "Marge du texte designé",
      order: "Ordre du texte designé",
      delete: "Suppression du texte designé",
    },
  },
  frame: {
    title: "Cadre",
    controls: {
      buttonReset: "Retirer le cadre",
      sliderOpacity: "Opacité du cadre",
      sliderSize: "Taille du cadre",
      selectColor: "Couleur du cadre",
      tabColor: "Couleur",
      tabOpacity: "Opacité",
      tabSize: "Taille",
    },
    items: {
      imgly_frame_dia: "Dia",
      imgly_frame_art_decor: "Art Decor",
      imgly_frame_black_passepartout: "Black",
      imgly_frame_lowpoly_shadow: "Low Poly",
      imgly_frame_wood_passepartout: "Wood",
    },
  },
  brush: {
    title: "Dessin",
    controls: {
      buttonReset: "Retirer le dessin",
      sliderSize: "Taille du dessin",
      sliderHardness: "Dureté du pinceau",
      selectColor: "Couleur",
      tabSize: "Taille",
      tabHardness: "Dureté",
      tabColor: "Couleur",
    },
    history: {
      brushStroke: "Dureté",
    },
  },
  transform: {
    title: "Transformation",
    controls: {
      buttonReset: "Réinitialiser",
      checkboxKeepResolution: "Concerver la résolution",
      inputCropSize: "Recadrer",
      inputHeight: "H",
      inputWidth: "L",
      tabFlipAndRotate: "Pivoter & Retourner",
      tabResolution: "Resolution",
      tabCropSize: "Recadrer",
    },
    categories: {
      imgly_transforms_common: "Common",
      imgly_transforms_facebook: "Facebook",
      imgly_transforms_twitter: "Twitter",
      imgly_transforms_instagram: "Instagram",
    },
    items: {
      imgly_transform_common_custom: "Custom",
      imgly_transform_common_square: "Square",
      imgly_transform_common_4: "4:3",
      imgly_transform_common_16: "16:9",
      imgly_transform_common_3: "3:4",
      imgly_transform_common_9: "9:16",
      imgly_transform_facebook_profile: "Profile",
      imgly_transform_facebook_title: "Title",
      imgly_transform_facebook_post: "Post",
      imgly_transform_instagram_story: "Story",
      imgly_transform_instagram_landscape: "Landscape",
      imgly_transform_instagram_portrait: "Portrait",
      imgly_transform_instagram_square: "Square",
      imgly_transform_twitter_profile: "Profile",
      imgly_transform_twitter_title: "Title",
      imgly_transform_twitter_post: "Post",
    },
    transformActions: {
      buttonFlipHorizontal: "Rotation horizontale",
      buttonFlipVertical: "Rotation herticale",
      buttonRotateClockwise: "Rotation dans le sens des aiguilles d'une montre",
      buttonRotateAntiClockwise: "Rotation dans le sens inverse des aiguilles d'une montre",
    },
  },
  custom: {
    title: "Personnalisé",
  },
};
