import React from "react";
import { Box } from "reflexbox";
import { Carousel } from "./Carousel";
import { Card, ValueFeedInstagram } from "@kards/types";

type FeedInstagramProps = {
  tag?: string;
  name?: string;
  theme: Card["theme"];
  hostApi: string;
  establishment?: string;
  disposition: ValueFeedInstagram["disposition"];
};

export function FeedInstagram({ establishment, theme, hostApi, disposition }: FeedInstagramProps) {
  return (
    <Box width="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
      <Carousel disposition={disposition} establishment={establishment} hostApi={hostApi} theme={theme}></Carousel>
    </Box>
  );
}
