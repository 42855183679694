import { Flex, Box } from "reflexbox";
import React from "react";
import { Media as MediaType, ThemeCard, ValueSurveyMedia } from "@kards/types";
import { Text } from "../commons/Text";
import { SmartImage } from "../commons/Media/SmartImage";
import { PlayIcon } from "../commons/PlayIcon";

export type SurveyMediaProps = {
  theme: ThemeCard;
  value: ValueSurveyMedia;
  onClick?: (id) => void;
  percentages?: any[];
  isUserVoted?: any;
  hostImage: string;
};

const noop = () => {};

export function SurveyMedia({ theme, value, onClick = noop, percentages, isUserVoted, hostImage }: SurveyMediaProps) {
  if (isUserVoted) {
    if (value.hideResults) {
      <Flex flexDirection="column" width="100%">
        <Text color={theme.color.title} textAlign="center" fontWeight="bold" fontSize="1.2em" width="100%" mb={2}>
          {value?.title}
        </Text>
        <Text color={theme.color.title} textAlign="center" fontWeight="bold" fontSize="1.2em" width="100%" mb={2}>
          Déja Répondu
        </Text>
      </Flex>;
    }

    return (
      <Flex flexDirection="column" width="100%">
        <Text color={theme.color.title} textAlign="center" fontWeight="bold" fontSize="1.2em" width="100%" mb={2}>
          {value?.title}
        </Text>
        <Flex flexDirection="column" flexWrap="wrap" width="100%" height="100%">
          {value?.medias?.map((media) => (
            <Flex justifyContent="space-between" backgroundColor="transparent" height="100%">
              <Box flex="0.3" width="45%">
                <Media media={media} hostImage={hostImage} />
              </Box>
              <Flex flex="0.4" alignItems="center" justifyContent="center">
                <Text textAlign="center" width="100%" color={theme.color.title} fontWeight="bold" p={"5px"}>
                  {media?.title}
                </Text>
              </Flex>
              <Flex flex="0.3" alignItems="center" justifyContent="center">
                <Text color={theme.color.title}>
                  {percentages?.find((percentage) => Object?.keys(percentage)[0] === media?.id)?.[media?.id] || 0} %
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    );
  }
  return (
    <Flex flexDirection="column" width="100%">
      <Text color={theme.color.title} textAlign="center" fontWeight="bold" fontSize="1.2em" width="100%" mb={2}>
        {value?.title}
      </Text>
      <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap" width="100%" height="100%">
        {value?.medias?.map((media) => (
          <Box backgroundColor="transparent" width="45%" height="100%">
            <Button onClick={() => onClick(media?.id)}>
              <Media media={media} hostImage={hostImage} />
              <Text textAlign="center" width="100%" color={theme.color.title} fontWeight="bold" p={"5px"}>
                {media?.title}
              </Text>
            </Button>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
}

function Button(props: React.ComponentProps<typeof Box>) {
  return <Box color="#FFF" {...props} />;
}

type MediaProps = {
  media: MediaType;
  hostImage: string;
};

function Media({ media, hostImage, ...props }: MediaProps) {
  if (media?.type === "video" || media?.type === "audio") return <VideoMedia media={media} />;

  return (
    <SmartImage
      size="medium"
      radius={0}
      hostImage={hostImage}
      image={media}
      alt={media?.name}
      style={{ width: "100%" }}
      {...props}
    />
  );
}

function VideoMedia({ media, ...props }) {
  return (
    <Box sx={{ position: "relative" }} width="100%">
      <img src={`${media.gifURL}`} width="100%" {...props} />
      <Flex
        justifyContent="center"
        alignItems="center"
        width={"100%"}
        height={"100%"}
        color="white"
        fontSize="2.5em"
        sx={{ position: "absolute", top: 0 }}
      >
        <PlayIcon />
      </Flex>
    </Box>
  );
}
