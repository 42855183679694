import { Flex, Box } from "reflexbox";
import { TitleGreyBold, Text, Button, Card } from "src/common-ui";
import { ContainerForm } from "./ContainerForm";
import { auth } from "src/firebase";

export function ErrorSubscription() {
  return (
    <ContainerForm loading={false}>
      <Box width={["90%", "50%"]} sx={{ zIndex: "1" }}>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" mb={3} mt={2}>
          <Box p={2}>
            <img src="./img/kards/kards1.svg" alt="logo" width="300px" />
          </Box>
          <TitleGreyBold>Problème avec votre compte à été détecté</TitleGreyBold>
          <Box pb={1} />
        </Flex>

        <Flex flexDirection="column" alignItems="center" justifyContent="center" width="100%" sx={{ zIndex: "1" }}>
          <Card p="20px" width="100%" maxWidth="700px" flexDirection="column">
            <Flex px="20px" textAlign="center" justifyContent="center" alignItems="center" width="100%">
              <Text maxWidth="800px">Il semblerait que votre compte ait été désactivé.</Text>
            </Flex>
            <Box p={2} />
            <Box px="20px" textAlign="center" justifyContent="center" alignItems="center" width="100%">
              <Text textAlign="left">
                La désactivation d'un compte arrive soit à la demande du client par exemple pour une suspension
                d'abonnement, soit à la suite d'un problème détecté avec le règlement de votre abonnement.
              </Text>
              <Box p={1} />
              <Text textAlign="left">
                Si vous souhaitez réactiver votre compte merci de nous contacter par mail à{" "}
                <strong>support@kards.fr</strong>.
              </Text>
              <Box p={1} />
              <Text textAlign="left">
                Merci de préciser vos coordonnées téléphoniques et votre nom afin que votre demande soit rapidement
                prise en charge.
              </Text>
            </Box>
          </Card>
        </Flex>
        <Box p={2} />
        <Box px="20px" width="100%" textAlign="center">
          <Text>Des questions ? Contactez nous sur support@kards.fr.</Text>
        </Box>
        <Flex p="10px" width="100%" alignItems="center" justifyContent="center">
          <Button onClick={() => auth.signOut()}>Se déconnecter</Button>
        </Flex>
        <Box p="40px" />
      </Box>
    </ContainerForm>
  );
}
