import { useState } from "react";
import { Image } from "src/common-ui";
import { Media as MediaType } from "@kards/types";
import { Box, Flex } from "reflexbox";
import { ThemeCard, Audio } from "@kards/types";

export function Media({ media, height }: { media: MediaType; height?: string }) {
  switch (media?.type) {
    case "video":
      return <ImageGif image={media} size="medium" />;
    case "audio":
      return <AudioIcon audio={media} />;
    case "image":
    default:
      return <Image height={height} image={media} size="medium" />;
  }
}
const sizeRange = {
  small: 60,
  medium: 295,
  big: 395,
};

export function ImageGif({ image, style = {}, size, ...props }) {
  const crop = `${image?.crop?.width || 0},${image?.crop?.height || 0},x${image?.crop?.x || 0},y${image?.crop?.y || 0}`;
  const src = `${image?.gifURL}?crop=${crop}&width=${sizeRange[size]}`;

  const [loading, setLoading] = useState(true);
  const showLoader = !image?.id || loading;

  return (
    <>
      {image?.id && (
        <img
          width="100%"
          height="auto"
          {...props}
          style={{ ...style, display: !showLoader ? "inline" : "none" }}
          src={src}
          alt={image?.name}
          onLoad={() => setLoading(false)}
        />
      )}
      <img
        width="100%"
        height="auto"
        {...props}
        style={{ ...style, display: showLoader ? "inline" : "none" }}
        src={"/img/placeholderImage.png"}
        alt="placeholderImage"
      />
    </>
  );
}

type AudioProps = {
  theme?: ThemeCard;
  audio: Audio;
  style?: any;
};

function AudioIcon({ audio, ...props }: AudioProps) {
  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
      <Flex
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
        fontSize="2.5em"
        backgroundColor="#0e0c0c5c"
        sx={{
          position: "absolute",
          top: 0,
          borderRadius: props?.style?.borderRadius,
        }}
      >
        <Flex flexDirection="column" alignItems="center">
          <MusicIcon />
          <Box as="p" fontSize="0.3em" mt={1}>
            {audio.name}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

function MusicIcon() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M12 3v9.28a4.39 4.39 0 00-1.5-.28C8.01 12 6 14.01 6 16.5S8.01 21 10.5 21c2.31 0 4.2-1.75 4.45-4H15V6h4V3h-7z"></path>
    </svg>
  );
}
