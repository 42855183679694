import { useEffect, useState } from "react";
import { Box, Flex } from "reflexbox";
import { Separator, Text } from "src/common-ui";
import { TextInput } from "src/common-ui/Form";
import { useField } from "react-final-form";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import { useThemeUI } from "theme-ui";
import { required } from "src/helpers/validator";

type LocationSearchFieldProps = {
  name?: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
};

export function LocationSearchField({
  name = "adress",
  label = "Adresse de votre établissement",
  placeholder = "Entrez l'adresse de votre établissement",
  isRequired = true,
}: LocationSearchFieldProps) {
  const { input, meta } = useField(name, { validate: isRequired ? required : null });
  const [location, setLocation] = useState({ adress: input?.value?.adress });
  const { theme }: any = useThemeUI();

  useEffect(() => {
    if (input?.value) {
      setLocation({ adress: input?.value?.adress });
    }
  }, [input?.value]);

  const handleSelect = async (value) => {
    try {
      const adress = await geocodeByAddress(value);
      input.onChange({
        adress: adress?.[0]?.formatted_address,
        place_id: adress?.[0]?.place_id,
        lat: adress?.[0]?.geometry?.location?.lat(),
        lng: adress?.[0]?.geometry?.location?.lng(),
      });
      setLocation({ adress: adress?.[0]?.formatted_address });
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = (adress) => {
    setLocation({ adress });
  };

  return (
    <>
      <PlacesAutocomplete value={location?.adress} onChange={handleChange} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <Flex my={"5px"} flexDirection="column" style={{ position: "relative" }}>
            <Box>
              <TextInput
                width={"100%"}
                validate={isRequired ? required : null}
                label={label}
                {...getInputProps({
                  placeholder: placeholder,
                })}
              />
            </Box>
            {!!suggestions?.length && (
              <Box style={{ zIndex: 9999, position: "absolute", top: "65px" }} width="100%">
                {suggestions.map((suggestion) => {
                  const style = {
                    background: "white",
                    padding: "5px",
                    color: theme.colors.secondary.main,
                    "&:hover": {
                      color: "white",
                      background: theme.colors.primary.light,
                    },
                  };

                  return (
                    <>
                      <Box key={suggestion.description} {...getSuggestionItemProps(suggestion, { style })}>
                        <Text color="secondary.dark">{suggestion.description}</Text>
                      </Box>
                      <Separator />
                    </>
                  );
                })}
              </Box>
            )}
          </Flex>
        )}
      </PlacesAutocomplete>
      {meta.submitFailed && (
        <Box fontSize="0.8em" fontStyle="italic" color="red.main" mt="4px">
          {meta.error}
        </Box>
      )}
    </>
  );
}
