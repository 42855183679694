import React from "react";
import { CardProduct } from "../commons";
import { ProductLinked, ThemeCard } from "@kards/types";

type ProductProps = {
  product: ProductLinked;
  hostImage: string;
  theme?: ThemeCard;
  width?: string;
  children?: React.ReactNode;
  onEditProduct?: any;
  bounced?: boolean;
};

export const Product = ({
  product,
  children,
  onEditProduct = null,
  theme = null,
  hostImage,
  bounced,
}: ProductProps) => (
  <CardProduct hostImage={hostImage} product={product} onEditProduct={onEditProduct} theme={theme} bounced={bounced}>
    {children}
  </CardProduct>
);
