import { ComponentDeezer, Card } from "@kards/types";
import React from "react";
import { Box, Flex } from "reflexbox";
import useSWR from "swr";
import { fetcher } from "../commons/fetcher";

type CurrentTrack = {
  album: { id: 15680870; title: string; link: string; cover_medium: string };
  artist: {
    id: number;
    name: string;
    link: string;
    tracklist: string;
    type: "artist";
  };
  duration: number;
  explicit_content_cover: number;
  explicit_content_lyrics: number;
  explicit_lyrics: boolean;
  id: number;
  link: string;
  md5_image: string;
  preview: string;
  rank: number;
  readable: boolean;
  timestamp: number;
  title: string;
  title_short: string;
  type: string;
};

export function Deezer({
  component,
  hostApi,
  establishment,
  theme,
}: {
  component: ComponentDeezer;
  theme: Card["theme"];
  hostApi: string;
  establishment: string;
}) {
  const playlist = component?.value?.playlist;
  const { data: currentTrack } = useSWR<CurrentTrack>(`${hostApi}/deezer/${establishment}/currentTrack`, fetcher);

  return (
    <Flex width="100%" backgroundColor={theme?.color?.background} flexDirection="column">
      {currentTrack ? (
        <Flex width="100%" justifyContent="space-around" alignItems="center" px={2}>
          <Flex
            justifyContent="center"
            alignItems="center"
            width="40%"
            margin={2}
            sx={{ overflow: "hidden", animation: "pulse 2s infinite" }}
          >
            <img width="100%" src={currentTrack?.album?.cover_medium} />
          </Flex>
          <Flex width="50%" justifyContent="center" alignItems="flex-start" flexDirection="column">
            <Box as="h2" fontSize="0.8em" color={theme.color.title}>
              {currentTrack?.title}
            </Box>

            <Box as="h2" fontSize="0.7em" color={theme.color.subtitle}>
              {currentTrack?.artist?.name}
            </Box>
          </Flex>
        </Flex>
      ) : (
        <Flex justifyContent="center" alignItems="center" height="10vh" p={2}>
          <p style={{ color: theme.color.subtitle }}>Pas de music actuellement</p>
        </Flex>
      )}

      <Flex width="100%" justifyContent="space-around" alignItems="center" px={2}>
        <Box
          width="60px"
          height="60px"
          sx={{
            boxShadow: "0px 0px 9px -4px #000000",
            borderRadius: "5px",
            overflow: "hidden",
          }}
          backgroundColor="#000"
        >
          <img width="100%" height="100%" src={playlist?.picture_medium} />
        </Box>
        <Flex justifyContent="center" alignItems="flex-start" flexDirection="column" width="50%">
          <Box as="h2" fontSize="0.8em" color={theme.color.title}>
            {playlist?.title} .{" "}
            <span style={{ color: theme.color.subtitle, fontSize: ".7em" }}>{playlist?.nb_tracks} titres</span>
          </Box>
          <Box as="h2" fontSize="0.7em" color={theme.color.subtitle}>
            {playlist?.creator?.name}
          </Box>
        </Flex>
        {/* <Flex>S'abbonner</Flex> */}
      </Flex>
    </Flex>
  );
}
