import "react-virtualized/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Suspense, lazy, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as ThemeUiProvider } from "theme-ui";
import { ErrorBoundary } from "src/common-ui/ErrorBoundary";
import { NotificationProvider } from "src/layout/Notification";
import { useGetEstablishmentAuth } from "src/app/useGetEstablishmentAuth";
import { themeUi } from "src/app/Theme";
import { LoginRoutes } from "src/app/LoginRoutes";
import { AppProvider, useRole } from "./app/AppContext";
import { AppLoader } from "./app/AppLoader";
import useSWR, { SWRConfig } from "swr";
import { db } from "./firebase";
import { useIsMobile } from "src/helpers/Breakpoints";

import "./App.css";
import { ErrorRoute } from "./app/ErrorRoute";

const DesktopApp = lazy(() => import("src/app/DesktopApp"));
const MobileApp = lazy(() => import("src/app/MobileApp"));

export default function App() {
  const [menuWrapped, setMenuWrapped] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const { establishment, token, loading, groupe } = useGetEstablishmentAuth();

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        focusThrottleInterval: 1000 * 10,
      }}
    >
      <ThemeUiProvider theme={themeUi}>
        <ThemeProvider theme={themeUi}>
          <ErrorBoundary>
            <NotificationProvider>
              <AppProvider
                value={{
                  establishment,
                  token,
                  groupe,
                  menuWrapped,
                  setMenuWrapped,
                }}
              >
                <Router>
                  {/* REDIRECT ---------------------------------------------------------------------- */}
                  {console.log("establishment", establishment)}
                  {console.log("error", loading)}
                  <RedirectWithCondition
                    redirectCondition={
                      !loading && establishment && establishment?.onBoarding && !establishment?.isSubscritptionActive
                    }
                    redirectPath={`/errorSubscription`}
                  />
                  <RedirectWithCondition
                    redirectCondition={
                      !loading &&
                      establishment &&
                      establishment?.onBoarding &&
                      establishment?.isSubscritptionActive &&
                      !establishment?.isPaymentMethodValid
                    }
                    redirectPath={`/errorPaymentMethod`}
                  />
                  <RedirectWithCondition
                    redirectCondition={
                      !loading &&
                      !establishment &&
                      !window.location.href?.includes("login") &&
                      !window.location.href?.includes("create-account")
                    }
                    redirectPath={`/login`}
                  />

                  <RedirectWithCondition
                    redirectCondition={!loading && token && establishment?.onBoarding === true && !establishment?.type}
                    redirectPath={`/establishments/${establishment?.id}/welcome`}
                  />
                  <RedirectWithCondition
                    redirectCondition={!loading && establishment && !isMobile && !establishment?.onBoarding}
                    redirectPath={`/onboarding/${establishment?.id}`}
                  />
                  <RedirectWithCondition
                    redirectCondition={
                      !loading &&
                      establishment &&
                      isMobile &&
                      establishment?.onBoarding &&
                      establishment?.isSubscritptionActive &&
                      establishment?.isPaymentMethodValid
                    }
                    redirectPath={`/establishments/${establishment?.id}/m`}
                  />

                  <RedirectWithCondition
                    redirectCondition={
                      !loading &&
                      establishment &&
                      !isMobile &&
                      establishment?.onBoarding &&
                      establishment?.isSubscritptionActive &&
                      establishment?.isPaymentMethodValid
                    }
                    withRoute
                    routePath="/"
                    redirectPath={`/establishments/${establishment?.id}/dashboard`}
                  />
                  <RedirectWithCondition
                    redirectCondition={
                      !loading &&
                      establishment &&
                      !isMobile &&
                      establishment?.onBoarding &&
                      establishment?.isSubscritptionActive &&
                      establishment?.isPaymentMethodValid
                    }
                    withRoute
                    routePath="/establishments"
                    redirectPath={`/establishments/${establishment?.id}/dashboard`}
                  />
                  <RedirectWithCondition
                    redirectCondition={
                      !loading &&
                      establishment &&
                      !isMobile &&
                      establishment?.onBoarding &&
                      establishment?.isSubscritptionActive &&
                      establishment?.isPaymentMethodValid
                    }
                    withRoute
                    routePath="/establishments/:establishmentId"
                    redirectPath={`/establishments/${establishment?.id}/dashboard`}
                  />

                  {/* ROUTES ---------------------------------------------------------------------- */}
                  <ErrorRoute />
                  <LoginRoutes />
                  <Route path="/establishments/:establishmentId">
                    {!loading && establishment && establishment?.onBoarding && <Secure token={token} />}
                  </Route>
                  {!loading && (
                    <Suspense fallback={<AppLoader />}>{isMobile ? <MobileApp /> : <DesktopApp />}</Suspense>
                  )}
                </Router>
              </AppProvider>
            </NotificationProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </ThemeUiProvider>
    </SWRConfig>
  );
}

type RedirectWithConditionType = {
  withRoute?: boolean;
  routePath?: string;
  redirectCondition: boolean;
  redirectPath: string;
  exact?: boolean;
};

const RedirectWithCondition = ({
  withRoute,
  routePath,
  redirectCondition,
  redirectPath,
  exact,
}: RedirectWithConditionType) => {
  if (withRoute) {
    if (redirectCondition) {
      console.log(redirectPath, " redirectCondition ", redirectCondition);
      return (
        <Route exact={exact} path={routePath}>
          <Redirect to={redirectPath} />
        </Route>
      );
    }
  }

  if (redirectCondition) {
    console.log(redirectPath, " redirectCondition ", redirectCondition);
    return <Redirect to={redirectPath} />;
  }
  return null;
};

function Secure({ token }) {
  const { establishmentId } = useParams<{ establishmentId: string }>();
  const role = useRole();

  if (role === "super-admin") return null;
  if (role === "groupe") return <HaveToRedirect token={token} type="groupes" />;
  if (role === "agency") return <HaveToRedirect token={token} type="agencies" />;
  if (establishmentId === token?.claims?.establishment) return null;
  return <Redirect to="/" />;
}

function HaveToRedirect({ token, type }) {
  const { establishmentId } = useParams<{ establishmentId: string }>();
  const { data } = useSWR<{ establishmentIds: string[] }>(`/${type}/${token?.claims?.establishment}`, () =>
    db
      .collection(type)
      .doc(token?.claims?.establishment)
      .get()
      .then((doc) => ({ ...doc?.data(), id: doc.id } as any)),
  );

  if (!data) return null;
  if (!data?.establishmentIds) return null;
  if ([...data?.establishmentIds, token?.claims?.establishment].includes(establishmentId)) return null;
  return <Redirect to="/" />;
}
