import { useState } from "react";
import { Box, Flex } from "reflexbox";
import { Button, Card, ModalFullScreen } from "src/common-ui";
import { FormFinal, Checkbox, TextField, LocationSearchField } from "src/common-ui/Form";
import { signUp } from "src/data/signUp";
import { ContainerForm } from "./ContainerForm";
import { useNotify } from "src/layout/Notification";
import { db } from "src/firebase";
import { AuthRouteProps } from "./types";
// import { useColorMode } from "theme-ui";
import { TiGroup, TiUser } from "react-icons/ti";
import { themeUi } from "src/app/Theme";
import { PhoneNumberField } from "../common-ui/Form/PhoneNumberField";

import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";

type FormValues = {
  establishmentName: string;
  firstName: string;
  lastName: string;
  phone: string;
  adress: string;
  email: string;
  password: string;
  passwordConfirm: string;
  cgps: boolean;
};

export function ChoiceAccountType() {
  const [typeAccount, setTypeAccount] = useState("normal");
  // const [, setColorMode] = useColorMode();
  const [loading, setLoading] = useState(false);

  return (
    <ContainerForm loading={loading}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" mb={3} mt={2} flex=".2">
        <Box p={2}>
          <img src="/img/kards/kards1.svg" alt="logo" width="250px" />
        </Box>

        <Flex>
          <Button
            onClick={() => {
              setTypeAccount("normal");
              // setColorMode("");
            }}
            backgroundColor={typeAccount === "normal" ? themeUi?.colors.primary.main : themeUi?.colors.grey.main}
            mx={2}
          >
            <>
              <TiUser />
              <Box mx={1} />
              Compte simple
            </>
          </Button>
          <Button
            onClick={() => {
              setTypeAccount("group");
              // setColorMode("groupe");
            }}
            backgroundColor={typeAccount === "group" ? themeUi?.colors.primary.main : themeUi?.colors.grey.main}
          >
            <>
              <TiGroup />
              <Box mx={1} />
              Compte Groupe
            </>
          </Button>
        </Flex>
      </Flex>

      {typeAccount === "normal" ? (
        <CreateAccount loading={loading} onLoading={setLoading} />
      ) : (
        <CreateAccountGroup loading={loading} onLoading={setLoading} />
      )}
    </ContainerForm>
  );
}

export function CreateAccount({ onLoading }: AuthRouteProps) {
  const [openModalCgu, setOpenModalCgu] = useState(false);
  const notify = useNotify();

  const submit = async (values: FormValues) => {
    try {
      onLoading(true);
      const res = await signUp(values);
      onLoading(false);
      switch (res?.code) {
        case "auth/invalid-email":
          return notify({ label: "Email non valide", type: "error" });
        case "auth/user-not-found":
          return notify({ label: "Email non valide", type: "error" });
        case "auth/email-already-exists":
          return notify({ label: "Email déjà utilisé", type: "error" });
        case "error":
          return notify({ label: "Une erreur est survenue", type: "error" });
      }
    } catch (e) {
      return notify({ label: "Une erreur est survenue", type: "error" });
    }
  };

  const validate = async (values: FormValues) => {
    const errors: any = {};
    if (!values.establishmentName) {
      errors.establishmentName = "Ce champs est requis";
    }

    if (values.establishmentName) {
      const allEstablishementsId = await db
        .collection("establishments")
        .doc(values?.establishmentName)
        .get();
      if (allEstablishementsId?.exists) {
        errors.establishmentName = "Ce nom d'etablissemnt est déjà pris";
      }
    }
    if (!values.firstName) {
      errors.firstName = "Ce champs est requis";
    }
    if (!values.lastName) {
      errors.lastName = "Ce champs est requis";
    }
    if (!values.phone) {
      errors.phone = "Ce champs est requis";
    }
    if (values.phone && values?.phone?.length < 11) {
      errors.phone = "Numéro de téléphone invalide";
    }
    if (!values.email) {
      errors.email = "Ce champs est requis";
    }
    if (!values.adress) {
      errors.adress = "Ce champs est requis";
    }
    if (!values.password) {
      errors.password = "Ce champs est requis";
    }
    if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = "Les mot de passe doivent être identique";
    }
    if ((values.password || "").length <= 6) {
      errors.password = "Mot de passe trop court";
    }
    if (!values.cgps) {
      errors.cgps = "Veuillez accepter les CGPS";
    }

    return errors;
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      sx={{ zIndex: "1" }}
      flex=".8"
    >
      <Card style={{ padding: "20px", width: "100%", maxWidth: "700px", height: "90%", overflow: "scroll" }}>
        <FormFinal width="100%" initialValues={{} as FormValues} onSubmit={submit} validate={validate}>
          <TextField
            style={{ paddingLeft: "80px" }}
            startAdornment={
              <span
                style={{
                  marginLeft: "-15px",
                  fontSize: "0.8em",
                  padding: "9px",
                  background: "#f5f5f5",
                  borderRadius: "4px 0px 0px 4px",
                  border: "1px solid #cacaca",
                  color: "#cacaca",
                }}
              >
                kards.fr/
              </span>
            }
            autoFocus
            name="establishmentName"
            label={"Nom de votre établissement"}
            type="text"
            width="100%"
          />
          <Box my={1} width="100%">
            <Box p={1} />
            <LocationSearchField />
          </Box>
          <Box display="flex" my={1}>
            <TextField name="firstName" label="Prénom" width="100%" />
            <Box p={1} />
            <TextField name="lastName" label="Nom" width="100%" />
          </Box>
          <Box display="flex" my={1}>
            <PhoneNumberField />
          </Box>
          <Box p={1} />
          <TextField name="email" type="email" label="Email" width="100%" />
          <Box display="flex" mt={1}>
            <TextField name="password" label="Mot de passe" type="password" autocomplete="new-password" width="100%" />
            <Box p={1} />
            <TextField
              name="passwordConfirm"
              label="Confimer votre mot de passe"
              type="password"
              autocomplete="new-password"
              width="100%"
            />
          </Box>
          <Flex alignItems="center" justifyContent="center">
            <Box p={3}>
              <Checkbox name="cgps" />
            </Box>
            <Box display="block" width="100%" justifyContent="center" alignItems="center" px={2} fontSize=".7em">
              J'ai pris connaissance des Conditions Générales de Préstations de Services .<br />
              <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => setOpenModalCgu(true)}>
                Conditions générales de prestations de services
              </span>
            </Box>
          </Flex>
          <Box p={3} width="100%" display="flex" justifyContent="center" alignItems="center">
            <Button type="submit">Continuer</Button>
            <Box p={1} />
            <Button data-cy="btn-back" bg="grey.main">
              <Link to={`/login`}>Retour</Link>
            </Button>
          </Box>
          <ModalFullScreen open={openModalCgu} onClose={() => setOpenModalCgu(false)}>
            <iframe src="/img/documents/cgps.pdf" style={{ width: "90%", height: "100%" }} />
          </ModalFullScreen>
        </FormFinal>
      </Card>
    </Flex>
  );
}

export function CreateAccountGroup({ onLoading }: AuthRouteProps) {
  const [openModalCgu, setOpenModalCgu] = useState(false);
  const notify = useNotify();

  const submit = async (values: FormValues) => {
    try {
      onLoading(true);
      const res = await signUp({ ...values, isGroup: true });
      onLoading(false);
      switch (res?.code) {
        case "auth/invalid-email":
          return notify({ label: "Email non valide", type: "error" });
        case "auth/user-not-found":
          return notify({ label: "Email non valide", type: "error" });
        case "auth/email-already-exists":
          return notify({ label: "Email déjà utilisé", type: "error" });
        case "error":
          return notify({ label: "Une erreur est survenue", type: "error" });
      }
    } catch (e) {
      return notify({ label: "Une erreur est survenue", type: "error" });
    }
  };

  const validate = async (values: FormValues) => {
    const errors: any = {};
    if (!values.establishmentName) {
      errors.establishmentName = "Ce champs est requis";
    }

    if (values.establishmentName) {
      const allEstablishementsId = await db
        .collection("establishments")
        .doc(values?.establishmentName)
        .get();
      if (allEstablishementsId?.exists) {
        errors.establishmentName = "Ce nom d'etablissemnt est déjà pris";
      }
    }
    if (!values.firstName) {
      errors.firstName = "Ce champs est requis";
    }
    if (!values.lastName) {
      errors.lastName = "Ce champs est requis";
    }
    if (!values.phone) {
      errors.phone = "Ce champs est requis";
    }
    if (values.phone && values?.phone?.length < 11) {
      errors.phone = "Numéro de téléphone invalide";
    }
    if (!values.email) {
      errors.email = "Ce champs est requis";
    }
    if (!values.adress) {
      errors.adress = "Ce champs est requis";
    }
    if (!values.password) {
      errors.password = "Ce champs est requis";
    }
    if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = "Les mot de passe doivent être identique";
    }
    if ((values.password || "").length <= 6) {
      errors.password = "Mot de passe trop court";
    }
    if (!values.cgps) {
      errors.cgps = "Veuillez accepter les CGPS";
    }

    return errors;
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      sx={{ zIndex: "1" }}
      flex=".8"
    >
      <Card style={{ padding: "20px", width: "100%", maxWidth: "700px", height: "90%", overflow: "scroll" }}>
        <FormFinal initialValues={{} as FormValues} onSubmit={submit} validate={validate}>
          <TextField
            style={{ paddingLeft: "80px" }}
            startAdornment={
              <span
                style={{
                  marginLeft: "-15px",
                  fontSize: "0.8em",
                  padding: "9px",
                  background: "#f5f5f5",
                  borderRadius: "4px 0px 0px 4px",
                  border: "1px solid #cacaca",
                  color: "#cacaca",
                }}
              >
                kards.fr/
              </span>
            }
            autoFocus
            name="establishmentName"
            label={"Nom de votre groupe"}
            type="text"
            width="100%"
          />
          <Box my={1} width="100%">
            <Box p={1} />
            <LocationSearchField />
          </Box>
          <Box display="flex" my={1}>
            <TextField name="firstName" label="Prénom" width="100%" />
            <Box p={1} />
            <TextField name="lastName" label="Nom" width="100%" />
          </Box>
          <Box display="flex" my={1}>
            <PhoneNumberField />
          </Box>
          <Box p={1} />
          <TextField name="email" label="Email" width="100%" />
          <Box display="flex" mt={1}>
            <TextField name="password" label="Mot de passe" type="password" autocomplete="new-password" width="100%" />
            <Box p={1} />
            <TextField
              name="passwordConfirm"
              label="Confimer votre mot de passe"
              type="password"
              autocomplete="new-password"
              width="100%"
            />
          </Box>
          <Flex alignItems="center" justifyContent="center">
            <Box p={3}>
              <Checkbox name="cgps" />
            </Box>
            <Box display="block" width="100%" justifyContent="center" alignItems="center" px={2} fontSize=".7em">
              J'ai pris connaissance des Conditions Générales de Préstations de Services .<br />
              <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => setOpenModalCgu(true)}>
                Conditions générales de prestations de services
              </span>
            </Box>
          </Flex>
          <Box p={3} width="100%" display="flex" justifyContent="center" alignItems="center">
            <Button type="submit">Continuer</Button>
            <Box p={1} />
            <Button data-cy="btn-back" bg="grey.main">
              <Link to={`/login`}>Retour</Link>
            </Button>
          </Box>
          <ModalFullScreen open={openModalCgu} onClose={() => setOpenModalCgu(false)}>
            <iframe src="/img/documents/cgps.pdf" style={{ width: "90%", height: "100%" }} />
          </ModalFullScreen>
        </FormFinal>
      </Card>
    </Flex>
  );
}
