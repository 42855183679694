import { Flex, Box } from "reflexbox";
import React from "react";
import { ThemeCard, ValueSurveyProduct } from "@kards/types";
import { Text, Title } from "../commons/Text";

import { SmartImage } from "../commons/Media/SmartImage";

export type SurveyProductProps = {
  theme: ThemeCard;
  value: ValueSurveyProduct;
  onClick?: (id) => void;
  percentages?: any[];
  isUserVoted?: any;
  hostImage: string;
};

const noop = () => {};

export function SurveyProduct({
  theme,
  value,
  onClick = noop,
  percentages,
  isUserVoted,
  hostImage,
}: SurveyProductProps) {
  if (isUserVoted) {
    if (value.hideResults) {
      <Flex flexDirection="column" width="100%">
        <Text color={theme.color.title} textAlign="center" fontWeight="bold" fontSize="1.2em" width="100%" mb={2}>
          {value?.title}
        </Text>
        <Text color={theme.color.title} textAlign="center" fontWeight="bold" fontSize="1.2em" width="100%" mb={2}>
          Déja Répondu
        </Text>
      </Flex>;
    }

    return (
      <Flex flexDirection="column" width="100%">
        <Text color={theme.color.title} textAlign="center" fontWeight="bold" fontSize="1.2em" width="100%" mb={2}>
          {value?.title}
        </Text>
        <Flex flexDirection="column" flexWrap="wrap" width="100%" height="100%">
          {value?.products?.map((product) => (
            <Flex justifyContent="space-between" backgroundColor="transparent" height="100%">
              <Box flex="0.3" width="45%">
                {product?.picture && (
                  <SmartImage
                    size="medium"
                    style={{ width: "100%", maxHeight: "400px" }}
                    hostImage={hostImage}
                    image={product?.picture}
                    alt={product?.picture?.name}
                  />
                )}
              </Box>
              <Flex flexDirection="column" flex="0.4" alignItems="flex-start" justifyContent="center">
                <Title width="100%" color={theme.color.title} fontWeight="bold" p={"5px"} title={product?.reference} />
                <Text width="100%" color={theme.color.subtitle} fontWeight="bold" fontSize="0.9em" p={"5px"}>
                  {formatDescription(product?.description)}
                </Text>
              </Flex>
              <Flex flex="0.3" alignItems="center" justifyContent="center">
                <Text color={theme.color.title}>
                  {percentages?.find((percentage) => Object?.keys(percentage)[0] === product?.id)?.[product?.id] || 0} %
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" width="100%">
      <Text color={theme.color.title} textAlign="center" fontWeight="bold" fontSize="1.2em" width="100%" mb={2}>
        {value?.title}
      </Text>
      <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap" width="100%" height="100%">
        {value?.products?.map((product) => (
          <Button width="45%" onClick={() => onClick(product?.id)}>
            {product?.picture && (
              <SmartImage
                size="medium"
                style={{ width: "100%", maxHeight: "400px" }}
                hostImage={hostImage}
                image={product?.picture}
                alt={product?.picture?.name}
              />
            )}

            <Title
              textAlign="center"
              width="100%"
              color={theme.color.title}
              fontWeight="bold"
              p={"5px"}
              title={product?.reference}
            ></Title>
            <Text width="100%" color={theme.color.subtitle} fontSize="0.9em" p={"5px"}>
              {formatDescription(product?.description)}
            </Text>
          </Button>
        ))}
      </Flex>
    </Flex>
  );
}

function Button(props: React.ComponentProps<typeof Box>) {
  return <Box as="button" color="#FFF" px={1} py={1} sx={{ borderRadius: "15px" }} {...props} />;
}

export const formatDescription = (v) => {
  if (typeof v === "string" && v.length > 40) v = `${v.replace(/^(.{40}[^\s]*).*/, "$1")} ...`;
  return v;
};
