import { ProductLinked, ThemeCard } from "@kards/types";
import React from "react";
import { Box } from "reflexbox";
import Dinero from "dinero.js";

function toEuro(dinero: Dinero.Dinero) {
  return dinero.setLocale("de-DE").toFormat();
}

export function numberToEuro(amount: number) {
  if (!amount) return "";
  return toEuro(Dinero({ amount, currency: "EUR" }));
}

export function Price({ price, theme }: { price: ProductLinked["price"]; theme: ThemeCard }) {
  return (
    <Box
      as="p"
      sx={{
        position: "absolute",
        right: "0.6em",
        bottom: "-10px",
        borderRadius: "20px",
        boxShadow: "0px 0px 20px 0px rgb(100 100 100 / 15%)",
      }}
      fontFamily="inherit"
      fontSize="min(4.5vw, 1em)"
      paddingX="15px"
      paddingTop="1px"
      paddingBottom="3px"
      backgroundColor={theme?.color?.backgroundPrice}
      color={theme?.color?.productPrice}
    >
      {numberToEuro(price)}
    </Box>
  );
}
