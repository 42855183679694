import React from "react";
import { CardProduct, Title, SubTitle } from "../commons";
import { ProductLinked, ThemeCard } from "@kards/types";
import { Flex } from "reflexbox";
import Slider from "react-slick";

export type CarouselProps = {
  title?: string;
  description?: string;
  theme?: ThemeCard;
  children?: React.ReactNode;
};

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: "ease-out",
};

export const ProductsCarousel = ({ title, description, theme, children }: CarouselProps) => (
  <div>
    <Flex flexDirection="column" padding="5% 0 0 16px">
      <Title theme={theme} title={title} />
      <SubTitle theme={theme} title={description} />
    </Flex>
    <Slider style={{ width: "100%", overflow: "hidden" }} {...settings}>
      {children}
    </Slider>
  </div>
);

export type ItemCarouselProps = {
  product: ProductLinked;
  onEditProduct?: () => void;
  theme?: ThemeCard;
  width?: string & any;
  children?: React.ReactNode;
  hostImage: string;
  bounced?: boolean;
};

export function ItemCarousel({ product, theme, onEditProduct, children, hostImage, bounced }: ItemCarouselProps) {
  return (
    <div style={{ margin: "10px" }}>
      <CardProduct
        hostImage={hostImage}
        onEditProduct={onEditProduct}
        product={product}
        isShadow
        theme={theme}
        bounced={bounced}
      >
        {children}
      </CardProduct>
    </div>
  );
}
