import React from "react";
import { Box } from "reflexbox";
import { Price } from "../commons/Price";
import { Offer as OfferType, ThemeCard } from "@kards/types";
import { SubTitleProduct, TitleProduct } from "../commons";
import { MediaComponent } from "../commons/Media/Media";
// import { SimpleList } from "../SimpleList";
// import { SimpleProduct } from "../SimpleList/SimpleProduct";

export type OfferProps = {
  offer: OfferType;
  theme?: ThemeCard;
  width?: string;
  children?: React.ReactNode;
  onEditOffer?: any;
  hostImage: string;
};

export const Offer = ({ offer, theme, children, hostImage }: OfferProps) => {
  if (!offer) return null;

  // if (!offer?.picture) {
  //   return (
  //     <SimpleList title={null} theme={theme} products={[offer]}>
  //       {(product) => (
  //         <SimpleProduct key={product?.id} product={product} theme={theme}>
  //           {children}
  //         </SimpleProduct>
  //       )}
  //     </SimpleList>
  //   );
  // }

  const { reference, description, price, picture } = offer;

  return (
    <Box sx={{ position: "relative" }} margin={2} className="littleBounced">
      <Box style={{ position: "relative" }}>
        <MediaComponent
          hostImage={hostImage}
          value={picture}
          style={{
            width: "100%",
            height: "auto",
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          }}
        />
        {Boolean(price) && <Price price={price} theme={theme} />}
      </Box>
      <Box flexDirection="column" display="flex" marginTop={2} paddingLeft={1}>
        <Box display="flex" flexDirection="column">
          <Box>
            <TitleProduct theme={theme} title={reference} />
          </Box>
          <Box>
            <SubTitleProduct theme={theme} title={description} />
          </Box>
        </Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};
