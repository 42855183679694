import React from "react";
import { ProductLinked, ThemeCard } from "@kards/types";
import { numberToEuro } from "../commons/Price";
import { SmartImage } from "../commons/Media/SmartImage";
import { Label } from "../commons/Label";
import { Box } from "reflexbox";
import { SubTitleProduct, TitleProduct } from "../commons";

export type SimpleProductProps = {
  product: ProductLinked;
  theme?: ThemeCard;
  children?: React.ReactNode;
  setEditProduct?: any;
  height?: string;
  hostImage: string;
  bounced?: boolean;
};

const formatDescription = (v) => {
  if (typeof v === "string" && v.length > 120) v = `${v.replace(/^(.{120}[^\s]*).*/, "$1")} ...`;
  return v;
};

export function SimpleProductPicture({ product, theme, children, hostImage, bounced }: SimpleProductProps) {
  const { reference, price, description, picture, labels } = product;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      color={theme?.color?.background}
      marginTop=".6em"
      className={bounced ? "littleBounced" : ""}
    >
      <Box
        display="flex"
        paddingY="5px"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        width="65%"
      >
        <Box width="100%">
          <TitleProduct theme={theme} title={reference} />
        </Box>
        <Box width="100%">
          <SubTitleProduct theme={theme} title={formatDescription(description)} />
        </Box>
        <Box width="100%" display="flex" alignItems="center">
          <Label labels={labels} />
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          {Boolean(price) && (
            <Box flex="0.2">
              <Box
                as="p"
                fontWeight="400"
                fontSize="min(4.5vw, 1em)"
                fontFamily="inherit"
                p="2px 0px"
                color={theme?.color?.productPrice}
              >
                {numberToEuro(price)}
              </Box>
            </Box>
          )}
          {children}
        </Box>
      </Box>
      <Box
        paddingY="5px"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="flex-end"
        width="35%"
      >
        <SmartImage
          size="medium"
          hostImage={hostImage}
          image={picture}
          alt={reference}
          style={{ width: "100%", height: "auto" }}
        />
      </Box>
    </Box>
  );
}
