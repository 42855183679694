/* eslint  react/display-name:off */
import { forwardRef } from "react";
import { Flex } from "reflexbox";
import styled from "styled-components";

type NoStyledInputProps = { Icon?: React.ReactNode } & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const InputComponent = forwardRef((props: any, ref: any) => {
  return (
    <Flex
      ref={ref}
      as="input"
      color="dark.main"
      backgroundColor="input"
      fontFamily="Mulish, sans-serif"
      fontSize="1.1em"
      width="85%"
      mr={1}
      sx={{ borderRadius: "3px" }}
      {...props}
    />
  );
});

export const NoStyledInput = forwardRef(({ Icon, maxLength = 20, ...props }: NoStyledInputProps, ref: any) => {
  return (
    <Flex as="label" color="primary.dark_to_white">
      <InputComponent ref={ref} maxLength={maxLength} {...props} />
      {Icon}
    </Flex>
  );
});

export const Input = styled.input<{ startAdornment?: any }>`
  ${({ theme, startAdornment }) => `
    border: 1px solid #D9D9D9;
    padding: 8px 14px;
    padding-left: ${startAdornment ? "50px" : ""}
    outline: none;
    font-size: 1em;
    border-radius: 4px;
    background-color: white;
    color: ${theme?.colors ? theme?.colors?.secondary?.dark : "#777777"} !important;
    width: ${startAdornment ? "100%" : "100%"}
    box-sizing: border-box;
    ::placeholder {
      color: ${theme?.colors?.secondary?.inputPlaceholder};
    }
  `}
`;

export const Textarea = styled.textarea<{ startAdornment?: any }>`
  ${({ theme, startAdornment }) => `
    border: 1px solid #D9D9D9;
    padding: 8px 14px;
    padding-left: ${startAdornment ? "50px" : ""}
    outline: none;
    font-size: 1em;
    border-radius: 4px;
    background-color: white;
    color: ${theme?.colors ? theme?.colors?.secondary?.dark : "#777777"} !important;
    width: ${startAdornment ? "100%" : "100%"}
    box-sizing: border-box;
    ::placeholder {
      color: ${theme?.colors?.secondary?.inputPlaceholder};
    }
  `}
`;

export const InputWhite = styled.input`
  ${({ theme }) => `
    background-color: white !important;
    border: 1px solid rgb(208, 213, 232);
    padding: 10px 14px;
    outline: none;
    font-size: .9em;
    color: ${theme.colors.secondary.dark} !important
    width: 100%;
    box-sizing: border-box;
  `}
`;
