import React from "react";
import { noop } from "lodash";
import { ThemeCard, ValueFeedInstagram } from "@kards/types";
import useSWR from "swr";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/fr";
import { Flex, Box } from "reflexbox";
import { CarouselItem, CarouselItemGallery } from "./CarouselItem";
import { fetcher } from "../commons/fetcher";
import { Post } from "./type";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.locale("fr");

export const Carousel = ({ onScroll = noop, theme, hostApi, establishment, disposition }: CarouselProps) => {
  const { data } = useSWR<Post[]>(
    hostApi && establishment ? `${hostApi}/instagram/${establishment}/feed/1` : null,
    fetcher,
  );

  return (
    <Flex flexDirection="column" width="100%">
      <Flex justifyContent="center" flexDirection="column" alignItems="center">
        <Flex justifyContent="center">
          <a target="_blank" rel="noreferrer" href={`https://www.instagram.com/${data?.[0]?.userName}`}></a>
          <Flex
            color={theme?.color?.subtitle}
            padding="10px"
            fontSize="1em"
            fontWeight={400}
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
          >
            <Box mr="5px">Suivre le compte</Box>
            <div
              style={{
                justifyContent: "center",
                fontSize: "min(4vw, .9em)",
                padding: "5px",
                borderRadius: "3px",
                fontWeight: 600,
                background: theme?.color?.subtitle,
                color: theme?.color?.background,
              }}
            >
              {data?.[0]?.userName}
            </div>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: disposition === "gallery" ? "wrap" : "nowrap",
          overflowX: "auto",
          overflowY: "hidden",
          justifyContent: disposition === "gallery" ? "center" : "inherit",
        }}
        onScroll={onScroll}
      >
        {Array.isArray(data) &&
          data?.map((post) =>
            disposition === "gallery" ? (
              <div key={post?.id} style={{ width: "45%", margin: "5px" }}>
                <CarouselItemGallery post={post} />
              </div>
            ) : (
              <div key={post?.id} style={{ flex: "0 0 auto" }}>
                <CarouselItem post={post} />
              </div>
            ),
          )}
      </Flex>
    </Flex>
  );
};

type CarouselProps = {
  establishment?: string;
  hostApi?: string;
  tag?: string;
  name?: string;
  theme?: ThemeCard;
  onScroll?: (e: any) => void;
  disposition: ValueFeedInstagram["disposition"];
};
