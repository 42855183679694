import RSelect from "react-select";
import { useThemeUI } from "theme-ui";

export function Select({ ...props }) {
  const ctx = useThemeUI();
  const { theme }: any = ctx;

  return (
    <RSelect
      formatCreateLabel={(value) => {
        return `Créer ${value}`;
      }}
      styles={{
        control: () => ({
          border: "1px solid rgb(208, 213, 232)",
          display: "flex",
          backgroundColor: theme?.colors?.primary?.white_to_grey,
          fontSize: ".9em",
          color: theme?.colors?.primary?.dark_to_white,
          borderRadius: "4px",
          paddingLeft: "4px",
          minWidth: "110px",
        }),
        valueContainer: () => ({
          width: "90%",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }),
        singleValue: () => ({
          color: theme?.colors?.primary?.dark_to_white,
          fontWeight: 400,
        }),
        input: () => ({
          margin: 0,
          paddingBottom: 0,
          paddingTop: 0,
        }),
        menu: () => ({
          border: "1px solid rgb(208, 213, 232)",
          position: "absolute",
          zIndex: 1000,
          marginTop: "5px",
          width: "100%",
          borderRadius: "4px",
          overflow: "hidden",
        }),
        menuList: () => ({
          padding: 0,
          backgroundColor: theme?.colors?.primary?.white_to_grey,
          overflow: "scroll",
          maxHeight: "300px",
        }),
        placeholder: () => ({
          paddingLeft: "8px",
          color: theme?.colors?.white.main,
          fontWeight: 400,
        }),
        option: () => ({
          backgroundColor: theme?.colors?.primary?.white_to_grey,
          padding: "5px",
          margin: "0px 3px",
          color: theme?.colors?.primary.blue_to_white,
          "&:hover": {
            color: theme?.colors?.white.main,
            background: theme?.colors?.primary.blue_to_cyan,
          },
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          color: theme?.colors?.white.main,
        }),
        multiValueRemove: (styles) => ({
          ...styles,
          color: theme?.colors?.white.main,
          cursor: "pointer",
          "&:hover": {
            background: theme?.colors?.primary.blue_to_cyan,
            color: theme?.colors?.white.main,
          },
        }),
        multiValue: (styles) => ({
          ...styles,
          background: theme?.colors?.primary.blue_to_cyan,
          color: theme?.colors?.white.main,
          padding: "4px 14px",
          borderRadius: "24px",
          fontSize: "14px",
        }),
      }}
      {...props}
    />
  );
}
