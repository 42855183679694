import { useState } from "react";
import { Image } from "@kards/types";
import { PhotoEditorSDK } from "src/medias/PhotoEditor";
import { useModal } from "src/helpers/useModal";
import { Modal } from "./Modal";

const sizeRange = {
  small: 60,
  medium: 295,
  big: 395,
};

type PictureProps = {
  style?: React.CSSProperties;
  image: Image;
  size: keyof typeof sizeRange;
  width?: string;
  maxWidth?: string;
  height?: string;
  editable?: boolean;
};

export function Picture({ image, style = {}, size, editable, ...props }: PictureProps) {
  const { closeModal, open, openModal } = useModal();

  const crop = `${image?.crop?.width || 0},${image?.crop?.height || 0},x${image?.crop?.x || 0},y${image?.crop?.y || 0}`;
  const src = `${window.kardsConfiguration.hostApi}${image?.imageURL}?crop=${crop}&width=${sizeRange[size]}
  ${
    image?.photoEditorSDKOperations?.length
      ? `&${image?.photoEditorSDKOperations?.map((o) => `options[]=${JSON.stringify(o?.options)}&type[]=${o?.type}`)}`
      : ""
  }`;

  const [loading, setLoading] = useState(true);
  const showLoader = !image?.id || loading;
  return (
    <>
      {image?.id && (
        <>
          <img
            onClick={() => editable && openModal()}
            width="100%"
            height={props.height || "auto"}
            {...props}
            style={{ ...style, display: !showLoader ? "inline" : "none" }}
            src={src}
            alt={image?.name}
            onLoad={() => setLoading(false)}
          />
          {editable && (
            <Modal onClose={closeModal} backdrop open={open} px={5} py={3} width="90vw" height="90vh">
              <PhotoEditorSDK image={image} onClose={closeModal} onUpdateEnd={() => console.log("end")} />
            </Modal>
          )}
        </>
      )}
      <img
        width="100%"
        height="auto"
        {...props}
        style={{ ...style, display: showLoader ? "inline" : "none" }}
        src={"/img/placeholderImage.png"}
        alt="placeholderImage"
      />
    </>
  );
}
