import React from "react";
import { Title } from "../commons";
import { ThemeCard, ResultVDM } from "@kards/types";
import { Box, Flex } from "reflexbox";
import Slider from "react-slick";

export type VDMCarouselProps = {
  results?: ResultVDM[];
  title?: string;
  theme?: ThemeCard;
  onClickAdd?: () => void;
  children?: (result: ResultVDM, index: number) => React.ReactNode;
};

const settings = {
  dots: true,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export function VDMCarousel({ title, results = [], theme, children, onClickAdd }: VDMCarouselProps) {
  return (
    <div>
      <Flex alignItems="center" justifyContent="space-between">
        <Title theme={theme} title={title} />
        <button onClick={onClickAdd}>Ajouter</button>
      </Flex>
      <Box pl={1}>
        <Slider {...settings}>
          {results.map((item, index) => (
            <Box p="10px" key={item?.id}>
              {children(item, index)}
            </Box>
          ))}
        </Slider>
      </Box>
    </div>
  );
}

export type VDMItemProps = {
  result: ResultVDM;
  theme?: ThemeCard;
};

const options: Intl.DateTimeFormatOptions = {
  weekday: "long",
  day: "2-digit",
  month: "long",
};

export function VDMItem({ result, theme }: VDMItemProps) {
  return (
    <Flex flexDirection="column" flex={1}>
      <Flex color={theme?.color?.productTitle}>{result.comment}</Flex>
      <Flex fontStyle="italic" fontSize="12px" mt="4px" color={theme?.color?.productDescription}>
        {new Date(result.createdAt.seconds).toLocaleString("fr-FR", options)}
      </Flex>
      <Flex fontStyle="italic" fontSize="12px" mt="4px" color={theme?.color?.productDescription}>
        {result.createdBy}
      </Flex>
    </Flex>
  );
}
