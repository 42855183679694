import React from "react";
import { Box, Flex } from "reflexbox";

export const Separator = ({ color, widthSeparator, heightSeparator }) => {
  return (
    <Flex justifyContent="center" alignItems="center" width="100%">
      <Box
        bg={color}
        height={heightSeparator ? `${heightSeparator}px` : "6px"}
        width={widthSeparator ? `${widthSeparator}%` : "100%"}
      />
    </Flex>
  );
};
